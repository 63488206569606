import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import BeaverLogoPencilAnimation from './BeaverLogoPencilAnimation';
import BeaverLogoWavingAnimationSVG from './BeaverLogoWavingAnimationSVG';
import ProgressBar from '../component/ProgressBarContainer';
import Personal from '../component/signupInformation/Personal';
import Experience from '../component/signupInformation/Experience';
import Loan from '../component/signupInformation/Loan';
import Interests from '../component/signupInformation/Interests';
import Education from '../component/signupInformation/Education';
import Project from '../component/signupInformation/Project';
import LoanDonor from '../component/signupInformation/donor/LoanDonor';
import StepContent from '../component/signupInformation/StepContent';
import { toast } from 'react-toastify';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
    // min-width: 0;
    // max-height: 6%;
    // box-shadow: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    width: 100vw;
    margin-bottom: 20px;
    margin-right: 15px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  
  /* Hide scrollbar for WebKit browsers */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 540px) {
    width: 70%;
  }

  @media (max-width: 480px) {
    width: 60%;
  }

  @media (max-width: 480px) {
    width: 55%;
    margin-right: 15px;
  }
`;


const BeaverContainer = styled.div`
  display: flex;
  height: 100%;
  width: 10%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Button = styled(motion.button)`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #003366;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

function SignupInformation() {
  const [step, setStep] = useState(0);
  const [personalEntries, setPersonalEntries] = useState([{ name: '', phone: '', email: '', gender: '', date_of_birth: '' }]);
  const [experienceEntries, setExperienceEntries] = useState([{ jobTitle: '', company: '', location: '', startDate: '', endDate: '' }]);
  const [loanEntries, setLoanEntries] = useState({
    student_total_loan_amount: '',
    fund_request_reason: ''
  });  
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [educationEntries, setEducationEntries] = useState([]);
  const [projectEntries, setProjectEntries] = useState([{ name: '', description: '', whoBenefits: '', pictures: [] }]);
  const [showBeaver, setShowBeaver] = useState(false);
  const [isDonor, setIsDonor] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    const checkStep = async () => {
      const currentUser = auth.currentUser;
      if (currentUser === null) {
        navigate('/login');
      }
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          const completedStep = data.sign_up_step_completed || 0;
          setStep(completedStep);
          
          // Set isDonor status
          const donorStatus = data.isDonor || 0;
          setIsDonor(donorStatus === 1);
        }
      }
    };
    checkStep();
  }, [navigate]);

  useEffect(() => {
    if (isDonor) {
      setEducationEntries([{ university: [{ university: '' }], majors: [{ major: '' }], gpa: [2.1,4.0], startDate: '', endDate: '' }]);
    } else {
      setEducationEntries([{ university: '', major: '', gpa: '', startDate: '', endDate: '' }]);
    }
  }, [isDonor]);
  
  

  const updateStepInDB = async (step) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, { sign_up_step_completed: step }, { merge: true });
    }
  };

  const triggerCloudFunction = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("User not authenticated");
      }
  
      const response = await fetch('https://us-central1-studentevaluation-9d972.cloudfunctions.net/processStringWithOpenAI', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          projects: projectEntries,
          userId: currentUser.uid
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
    } catch (error) {
      console.error('Error calling Cloud Function:', error);
    }
  };
  

  const nextStep = async () => {
    if (isDonor) {
      if (step === 0 && !(await handleSubmitPersonal())) return;
      if (step === 1 && !(await handleSubmitEducation())) return;
      if (step === 2 && !(await handleSubmitInterests())) return;
      if (step === 3 && !(await handleSubmitLoanDonar())) return;
    } else {
      if (step === 0 && !(await handleSubmitPersonal())) return;
      if (step === 1 && !(await handleSubmitEducation())) return;
      if (step === 2 && !(await handleSubmitProject())) return;
      if (step === 3 && !(await handleSubmitLoan())) return;
      // if (step === 3 && !(await handleSubmitInterests())) return;
     
      // if (step === 5 && !(await handleSubmitProject())) return;
    }

    const nextStep = step + 1;
    setStep(nextStep);
    await updateStepInDB(nextStep);

    if ((!isDonor && nextStep === 4) || (isDonor && nextStep === 4)) {
      if (!isDonor) {
        triggerCloudFunction();
      }
      navigate('/home');
    }

    setShowBeaver(true);
    setTimeout(() => {
      setShowBeaver(false);
    }, 2000);
  };


  const prevStep = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const handleSubmitPersonal = async () => {
    const allFieldsFilled = personalEntries.every(entry =>
      Object.values(entry).every(value => value.trim() !== '')
    );

    if (!allFieldsFilled) {
      toast.error('Please fill out all fields.');
      return false;
    }

    const currentUserId = auth.currentUser?.uid;
    if (currentUserId) {
      await uploadPersonalData(currentUserId, personalEntries);
      return true;
    } else {
      console.error('No user logged in');
      return false;
    }
  };

  const handleSubmitExperience = async () => {
    const allFieldsFilled = experienceEntries.every(entry =>
      Object.values(entry).every(value => value.trim() !== '') &&
      validateDate(entry.startDate) &&
      validateDate(entry.endDate)
    );

    const datesValid = experienceEntries.every(entry =>
      isEndDateAfterStartDate(entry.startDate, entry.endDate)
    );

    if (!allFieldsFilled) {
      toast.error('Please fill out all fields and ensure dates are in the format DD/MM/YYYY.');
      return false;
    }

    if (!datesValid) {
      toast.error('End date must be after start date.');
      return false;
    }

    const currentUserId = auth.currentUser?.uid;
    if (currentUserId) {
      await uploadExperienceData(currentUserId, experienceEntries);
      return true;
    } else {
      console.error('No user logged in');
      return false;
    }
  };

  const handleSubmitLoanDonar = async () => {

    const currentUserId = auth.currentUser?.uid;
    if (currentUserId) {
      await uploadLoanData(currentUserId, loanEntries);
      return true;
    } else {
      console.error('No user logged in');
      return false;
    }
  };

  const handleSubmitLoan = async () => {
    // Ensure that the loanEntry properties are defined and valid
    const totalLoanAmountFilled = loanEntries.student_total_loan_amount?.trim() !== '';
    const fundRequestReasonFilled = loanEntries.fund_request_reason?.trim() !== '';
  
    // Check for word count in the fund request reason
    if (loanEntries.fund_request_reason) {
      const wordCount = loanEntries.fund_request_reason.trim().split(/\s+/).length;
      if (wordCount < 5) {
        toast.warn(
          'Your reason is quite short! A longer and more detailed explanation might increase your chances of receiving a scholarship.'
        );
        return false;
      }
    }
  
    // If any field is empty, show an error toast
    if (!totalLoanAmountFilled || !fundRequestReasonFilled) {
      toast.error('Please fill out all fields.');
      return false;
    }
  
    const currentUserId = auth.currentUser?.uid;
    if (currentUserId) {
      await uploadLoanData(currentUserId, loanEntries);
      return true;
    } else {
      console.error('No user logged in');
      return false;
    }
  };
  

  const handleSubmitInterests = async () => {
    // if (selectedInterests.length < 5) {
    //   toast.error('Please select at least 5 interests.');
    //   return false;
    // }

    if (selectedInterests.length === 0) {
      toast.error('You must select at least 1 interest'); 
      return false;
    }

    const currentUserId = auth.currentUser?.uid;
    if (currentUserId) {
      await uploadInterestsData(currentUserId, selectedInterests);
      return true;
    } else {
      console.error('No user logged in');
      return false;
    }
  };

  const handleSubmitEducation = async () => {
    console.log("Test 1", educationEntries);
    
    const allFieldsFilled = educationEntries.every(entry => {
      console.log("Test 2");
      
      if (isDonor) {
        console.log("Test 3");
  
        const universitiesFilled = entry.university.every((uni, index) => {
          const condition = uni.university.trim() !== '';
          console.log(`University ${index + 1} filled:`, condition);
          return condition;
        });
  
        const majorsFilled = entry.majors.every((major, index) => {
          const condition = major.major.trim() !== '';
          console.log(`Major ${index + 1} filled:`, condition);
          return condition;
        });
  
        const gpaValid = entry.gpa.length === 2 && entry.gpa.every((gpaValue, index) => {
          const condition = typeof gpaValue === 'number';
          console.log(`GPA ${index + 1} valid:`, condition);
          return condition;
        });
  
        return universitiesFilled && majorsFilled && gpaValid;
      } else {
        console.log("Non-donor branch");
        const allNonDonorFieldsFilled = Object.values(entry).every(value => typeof value === 'string' && value.trim() !== '');
        console.log("All non-donor fields filled:", allNonDonorFieldsFilled);
        return allNonDonorFieldsFilled && validateDate(entry.startDate) && validateDate(entry.endDate);
      }
    });
  
    const datesValid = educationEntries.every(entry =>
      isEndDateAfterStartDate(entry.startDate, entry.endDate)
    );
  
    if (!allFieldsFilled) {
      toast.error('Please fill out all fields and ensure dates are in the format DD/MM/YYYY.');
      return false;
    }
    console.log("Test 4");
  
    console.log("Test 5");
  
    const currentUserId = auth.currentUser?.uid;
    console.log("Test 6");
  
    if (currentUserId) {
      console.log("Test 7");
  
      await uploadEducationData(currentUserId, educationEntries);
      console.log("Test 8");
      return true;
    } else {
      console.error('No user logged in');
      return false;
    }
  };
  
  const handleSubmitProject = async () => {
    const allFieldsFilled = projectEntries.every(entry =>
      entry.name.trim() !== '' &&
      entry.description.trim() !== '' &&
      entry.whoBenefits.trim() !== '' &&
      entry.pictures.length > 0
    );

    if (!allFieldsFilled) {
      toast.error('Please fill out all fields and upload at least one picture for each project.');
      return false;
    }

    const currentUserId = auth.currentUser?.uid;
    if (currentUserId) {
      await uploadProjectData(currentUserId, projectEntries);  // Use updated projectEntries
      return true;
    } else {
      console.error('No user logged in');
      return false;
    }
  };


  const uploadPersonalData = async (userId, personalData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { personalData: personalData }, { merge: true });
      console.log('Personal data uploaded successfully');
    } catch (error) {
      console.error('Error uploading personal data:', error);
    }
  };

  const uploadExperienceData = async (userId, experienceData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { experienceData: experienceData }, { merge: true });
      console.log('Experience data uploaded successfully');
    } catch (error) {
      console.error('Error uploading experience data:', error);
    }
  };

  const uploadLoanData = async (userId, loanData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { loanData: loanData }, { merge: true });
      console.log('Loan data uploaded successfully');
    } catch (error) {
      console.error('Error uploading loan data:', error);
    }
  };

  const uploadInterestsData = async (userId, interests) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { interests: interests }, { merge: true });
      console.log('Interests uploaded successfully');
    } catch (error) {
      console.error('Error uploading interests:', error);
    }
  };

  const uploadEducationData = async (userId, educationData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { educationData: educationData }, { merge: true });
      console.log('Education data uploaded successfully');
    } catch (error) {
      console.error('Error uploading education data:', error);
    }
  };

  const uploadProjectData = async (userId, projectData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { projectData: projectData }, { merge: true });
      console.log('Project data uploaded successfully');
    } catch (error) {
      console.error('Error uploading project data:', error);
    }
  };

  const validateDate = (date) => {
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    return dateRegex.test(date);
  };

  const isEndDateAfterStartDate = (startDate, endDate) => {
    const [startDay, startMonth, startYear] = startDate.split('/').map(Number);
    const [endDay, endMonth, endYear] = endDate.split('/').map(Number);
    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);
    return end >= start;
  };

  const steps = [
    { tooltipText: "Personal Information" },
    { tooltipText: "Education" },
    { tooltipText: "Accomplishments" },
    { tooltipText: "Loans" },
    // { tooltipText: "Experience" },
    // { tooltipText: "Interests" },


  ];

  const stepsDonor = [
    { tooltipText: "Personal Information" },
    { tooltipText: "Universities" },
    { tooltipText: "Interestets" },
    { tooltipText: "Stipend Money" },
  ];

  return (
    <Wrapper>
      <Header>
        <BeaverContainer>
          <BeaverLogoPencilAnimation />
        </BeaverContainer>
        {isDonor ? (
        <ProgressBar step={step} steps={stepsDonor} />
      ) : (
        <ProgressBar step={step} steps={steps} />
      )}
       
      </Header>
      <ContentWrapper>
      <StepContent
          step={step}
          personalEntries={personalEntries}
          setPersonalEntries={setPersonalEntries}
          experienceEntries={experienceEntries}
          setExperienceEntries={setExperienceEntries}
          loanEntries={loanEntries}
          setLoanEntries={setLoanEntries}
          selectedInterests={selectedInterests}
          setSelectedInterests={setSelectedInterests}
          educationEntries={educationEntries}
          setEducationEntries={setEducationEntries}
          projectEntries={projectEntries}
          setProjectEntries={setProjectEntries}
          isDonor={isDonor}
        />
        <ButtonContainer>
          <Button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={prevStep}
          >
            Back
          </Button>
          <Button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={nextStep}
          >
            Next
          </Button>
        </ButtonContainer>
      </ContentWrapper>
    </Wrapper>
  );
}

export default SignupInformation;
