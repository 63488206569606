import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import DonorMedalBronze from '../SVG/donorMedals/DonorMedalBronze';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopUp = styled(motion.div)`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MedalImageWrapper = styled.div`
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
`;

const MotivationalText = styled.p`
  font-size: 1.1em;
  color: #333;
  margin: 0;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #003367;
  }
`;

const MedalPopUp = ({ onClose }) => {
  return (
    <Overlay
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PopUp
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
      >
        <MedalImageWrapper>
          <DonorMedalBronze />
        </MedalImageWrapper>
        <MotivationalText>
          Congratulations! You’ve earned the Bronze Donor status. Keep up the great work, and start grinding for the next level. Your contributions are making a difference!
        </MotivationalText>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </PopUp>
    </Overlay>
  );
};

export default MedalPopUp;
