import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaPlus, FaCommentDots, FaTrash } from 'react-icons/fa';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { setDoc, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'; // Ensure arrayRemove is imported
import { db, auth } from '../firebase';
import { toast } from 'react-toastify';

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-right: 40px;
  margin-bottom: 50px;
`;

const ProjectCard = styled(motion.div)`
  position: relative;
  width: 100%;
  margin: 1% 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const ProjectInfo = styled.div`
  padding: 10px;
`;

const ProjectTitle = styled.h3`
  margin: 0 0 10px 0;
  color: #003366;
`;

const ProjectText = styled.p`
  margin: 5px 0;
  font-size: 0.9em;
  color: #666;
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  color: #003366;
  margin-bottom: 0px;
`;

const Banner = styled(motion.div)`
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background-color: #003366;
  color: white;
  text-align: center;
  font-size: 1.2em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const BannerIcon = styled.div`
  margin-left: 5px;
  font-size: 1em;
  display: flex;
  align-items: center;
`;

const BannerImage = styled.img`
  height: 100%;
  max-height: 40px;
  margin-right: 10px;
`;

const ProjectContainerOverall = styled.div`
  margin-top: 10px;
`;

const ProjectContainerOverallNew = styled.div`
  margin-top: 10px;
  margin-right: -40px;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const ProjectImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
`;

const ImagePreviewWrapper = styled.div`
  position: relative;
  margin: 5px;
`;

const ImageDeleteButton = styled(FaTrash)`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 2px;

  &:hover {
    color: #ff0000;
  }
`;

const AddProjectContainer = styled.div`
  display: flex;
  width: 100%;
`;

const AddItemButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #577483;
  }

  &:focus {
    outline: none;
  }
`;

const AIAnnaChatContainer = styled(motion.div)`
  margin-top: 20px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  padding: 20px;
`;

const ChatHeader = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #003366;
  margin-bottom: 10px;
  text-align: center;
`;

const AddImageButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;

const AddImageIcon = styled(FaPlus)`
  margin-right: 5px;
`;


const ChatBox = styled.div`
  height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
`;

const ChatMessage = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: ${({ sender }) => (sender === 'user' ? 'flex-end' : 'flex-start')};
`;

const ProjectWrapper = styled.div`
  padding-left: 30px;
  padding-top: 10px;
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px; /* Adjust the height as needed */
  width: 100%;
  box-sizing: border-box;
  resize: vertical; /* Allows users to resize the textarea vertically */
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const ChatBubble = styled.p`
  margin: 0;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: ${({ sender }) => (sender === 'user' ? '#003366' : '#eee')};
  color: ${({ sender }) => (sender === 'user' ? 'white' : 'black')};
  text-align: left;
  max-width: 70%;
  word-wrap: break-word;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`;

const ChatInputContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const ChatButton = styled.button`
  padding: 10px 15px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #577483;
  }
`;

const Successes = ({ userData, setUserData }) => {
  // States
  const [projectData, setProjectData] = useState(userData.projectData || []);
  const [showAddProject, setShowAddProject] = useState(false);
  const [newProjectData, setNewProjectData] = useState({
    name: '',
    description: '',
    contribution: '',
    benefit: '',
    success: '',
    pictures: [],
  });
  const [editedProjectIndex, setEditedProjectIndex] = useState(null);
  const [editedProjectData, setEditedProjectData] = useState(null);

  const [showAIChat, setShowAIChat] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const currentUserUid = auth.currentUser ? auth.currentUser.uid : 'anonymous';

  // Update projectData when userData.projectData changes
  useEffect(() => {
    setProjectData(userData.projectData || []);
  }, [userData.projectData]);

  // Functions for adding new project
  const toggleAddProject = () => {
    setShowAddProject(!showAddProject);
    if (!showAddProject) {
      setNewProjectData({
        name: '',
        description: '',
        contribution: '',
        benefit: '',
        success: '',
        pictures: [],
      });
    }
  };

  const handleAddProject = async () => {
    if (newProjectData) {
      const updatedProjectData = [...projectData, newProjectData];
      setProjectData(updatedProjectData);
      // Update userData
      setUserData({ ...userData, projectData: updatedProjectData });

      // Update database
      try {
        const userRef = doc(db, 'users', currentUserUid);
        await updateDoc(userRef, {
          projectData: arrayUnion(newProjectData),
        });
        toast.success('Project added successfully!');
      } catch (error) {
        console.error('Error adding project:', error);
        toast.error('Error adding project');
      }

      // Reset newProjectData
      setNewProjectData({
        name: '',
        description: '',
        contribution: '',
        benefit: '',
        success: '',
        pictures: [],
      });
      setShowAddProject(false);
    }
  };

  // Functions for editing existing project
  const handleProjectClick = (project, index) => {
    if (editedProjectIndex === index) {
      // Close the current project if clicked again
      setEditedProjectIndex(null);
      setEditedProjectData(null);
    } else {
      setEditedProjectIndex(index);
      setEditedProjectData({ ...project });
    }
  };

  const handleUpdateProject = async () => {
    if (editedProjectData && editedProjectIndex !== null) {
      const updatedProjectData = [...projectData];
      const oldProject = updatedProjectData[editedProjectIndex];
      updatedProjectData[editedProjectIndex] = editedProjectData;
      setProjectData(updatedProjectData);
      // Update userData
      setUserData({ ...userData, projectData: updatedProjectData });

      // Update database
      try {
        const userRef = doc(db, 'users', currentUserUid);
        await updateDoc(userRef, {
          projectData: arrayRemove(oldProject),
        });
        await updateDoc(userRef, {
          projectData: arrayUnion(editedProjectData),
        });
        toast.success('Project updated successfully!');
      } catch (error) {
        console.error('Error updating project:', error);
        toast.error('Error updating project');
      }

      setEditedProjectIndex(null);
      setEditedProjectData(null);
    }
  };

  // Functions for adding/deleting images in newProjectData and editedProjectData
  const handleAddImageToNewProject = async (file) => {
    if (file) {
      const updatedProject = { ...newProjectData };
      if (!updatedProject.pictures) {
        updatedProject.pictures = [];
      }

      const storage = getStorage();
      const storageRef = ref(storage, `projects/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      updatedProject.pictures.push({ name: file.name, url: downloadURL });
      setNewProjectData(updatedProject);
    }
  };

  const handleDeleteImageFromNewProject = (imageIndex) => {
    const updatedPictures = [...newProjectData.pictures];
    updatedPictures.splice(imageIndex, 1);
    setNewProjectData({ ...newProjectData, pictures: updatedPictures });
  };

  const handleAddImageToEditedProject = async (file) => {
    if (file && editedProjectData) {
      const updatedProject = { ...editedProjectData };
      if (!updatedProject.pictures) {
        updatedProject.pictures = [];
      }

      const storage = getStorage();
      const storageRef = ref(storage, `projects/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      updatedProject.pictures.push({ name: file.name, url: downloadURL });
      setEditedProjectData(updatedProject);
    }
  };

  const handleDeleteImageFromEditedProject = (imageIndex) => {
    if (editedProjectData) {
      const updatedPictures = [...editedProjectData.pictures];
      updatedPictures.splice(imageIndex, 1);
      setEditedProjectData({ ...editedProjectData, pictures: updatedPictures });
    }
  };

  // AI Anna Chat functions
  const toggleAIChat = () => {
    setShowAIChat(!showAIChat);
    if (!showAIChat) {
      // Reset chat messages when reopening the chat
      setChatMessages([
        {
          sender: 'ai',
          text: "Hi, I am AI Anna. I want to help you polish your projects. Are you ready to get started?",
        },
      ]);
    }
  };

  const handleChatSend = async () => {
    const currentUser1 = auth.currentUser;
    if (!currentUser1) {
      throw new Error("User not authenticated");
    }

    if (chatInput.trim()) {
      setChatMessages([...chatMessages, { sender: 'user', text: chatInput }]);
      setChatInput('');
      console.log("current user uid", currentUserUid, "chatinput", chatInput, "userData.projectData", userData.projectData);

      try {
        const response = await fetch('https://us-central1-studentevaluation-9d972.cloudfunctions.net/chatWithAIAnna', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: currentUser1.uid,
            userInput: chatInput,
            projects: userData.projectData || [],
          }),
        });

        if (!response.ok) {
          console.log("Hello response", response); // For debugging purposes
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        const aiResponse = responseData.aiResponse;

        setChatMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'ai', text: aiResponse },
        ]);
      } catch (error) {
        console.error('Error chatting with AI Anna:', error);
        toast.error('Failed to get a response from AI Anna');
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleChatSend();
    }
  };

  return (
    <>
      <Title>Your Successes</Title>
      <SuccessWrapper>
        {/* Add Project Banner */}
        <Banner
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          whileTap={{ scale: 0.95 }}
          onClick={toggleAddProject}
        >
          <BannerImage
            src="https://firebasestorage.googleapis.com/v0/b/studentevaluation-9d972.appspot.com/o/AddSuccesses.png?alt=media&token=6bc4ec12-f090-42b6-bc20-b6655ff70159"
            alt="Add Successes"
          />
          Add Successes
          <BannerIcon>
            <FaPlus />
          </BannerIcon>
        </Banner>

        {/* Add Project Form */}
        {showAddProject && (
          <ProjectContainerOverallNew>
            <InputLabel>
              Name
              <Input
                type="text"
                value={newProjectData.name}
                onChange={(e) =>
                  setNewProjectData({ ...newProjectData, name: e.target.value })
                }
              />
            </InputLabel>
            <InputLabel>
              Description
              <TextArea
                value={newProjectData.description}
                onChange={(e) =>
                  setNewProjectData({ ...newProjectData, description: e.target.value })
                }
              />
            </InputLabel>
            <InputLabel>
              Who Benefits
              <TextArea
                value={newProjectData.whoBenefits}
                onChange={(e) =>
                  setNewProjectData({ ...newProjectData, whoBenefits: e.target.value })
                }
              />
            </InputLabel>
            {/* Additional fields like contribution, benefit, success */}
            <InputLabel>
              Add Image
              <AddImageButton
                onClick={() => document.getElementById('newProjectImageUpload').click()}
              >
                <AddImageIcon />
                Add Image
              </AddImageButton>
              <Input
                type="file"
                id="newProjectImageUpload"
                accept="image/*"
                onChange={(e) => handleAddImageToNewProject(e.target.files[0])}
                style={{ display: 'none' }}
              />
            </InputLabel>
            <ProjectImages>
              {newProjectData.pictures &&
                newProjectData.pictures.map((picture, index) => (
                  <ImagePreviewWrapper key={index}>
                    <ProjectImage
                      src={picture.url}
                      alt={`Preview ${index}`}
                      style={{ width: '100px', height: '100px' }}
                    />
                    <ImageDeleteButton
                      onClick={() => handleDeleteImageFromNewProject(index)}
                    />
                  </ImagePreviewWrapper>
                ))}
            </ProjectImages>
            <AddProjectContainer>
              <AddItemButton onClick={handleAddProject}>Add Project</AddItemButton>
            </AddProjectContainer>
          </ProjectContainerOverallNew>
        )}

        {/* AI Chat Banner */}
        <Banner
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          whileTap={{ scale: 0.95 }}
          onClick={toggleAIChat}
        >
          <BannerImage
            src="https://firebasestorage.googleapis.com/v0/b/studentevaluation-9d972.appspot.com/o/AIAnna.png?alt=media&token=0d49923a-d852-487f-af0f-add4304acc7a"
            alt="AI Anna"
          />
          Improve your projects with AI Anna
          <BannerIcon>
            <FaCommentDots />
          </BannerIcon>
        </Banner>

        {/* AI Anna Chat Component */}
        {showAIChat && (
          <AIAnnaChatContainer
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ChatHeader>Chat with AI Anna</ChatHeader>
            <ChatBox>
              {chatMessages.map((message, index) => (
                <ChatMessage key={index} sender={message.sender}>
                  <ChatBubble sender={message.sender}>{message.text}</ChatBubble>
                </ChatMessage>
              ))}
            </ChatBox>
            <ChatInputContainer>
              <ChatInput
                type="text"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                placeholder="Type a message..."
                onKeyPress={handleKeyPress}
              />
              <ChatButton onClick={handleChatSend}>Send</ChatButton>
            </ChatInputContainer>
          </AIAnnaChatContainer>
        )}

        {/* Display Projects */}
        {projectData && projectData.length > 0 ? (
          projectData.map((project, index) => (
            <ProjectWrapper key={index}>
              <ProjectCard
                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleProjectClick(project, index)}
              >
                <ProjectImage
                  src={project.pictures && project.pictures[0]?.url}
                  alt={project.name}
                />
                <ProjectInfo>
                  <ProjectTitle>{project.name || 'Unnamed Project'}</ProjectTitle>
                  <ProjectText>
                    {project.description || 'No success details available'}
                  </ProjectText>
                </ProjectInfo>
              </ProjectCard>
              {editedProjectIndex === index && (
                <ProjectContainerOverall>
                  <InputLabel>
                    Name
                    <Input
                      type="text"
                      value={editedProjectData.name}
                      onChange={(e) =>
                        setEditedProjectData({
                          ...editedProjectData,
                          name: e.target.value,
                        })
                      }
                    />
                  </InputLabel>
                  <InputLabel>
                    Description
                    <TextArea
                      value={editedProjectData.description}
                      onChange={(e) =>
                        setEditedProjectData({
                          ...editedProjectData,
                          description: e.target.value,
                        })
                      }
                    />
                  </InputLabel>
                  <InputLabel>
                    Who Beneftis
                    <TextArea
                      value={editedProjectData.whoBenefits}
                      onChange={(e) =>
                        setEditedProjectData({
                          ...editedProjectData,
                          whoBenefits: e.target.value,
                        })
                      }
                    />
                  </InputLabel>
                  {/* Additional fields */}
                  <InputLabel>
                    Add Image
                    <AddImageButton
                      onClick={() =>
                        document
                          .getElementById(`editedProjectImageUpload-${index}`)
                          .click()
                      }
                    >
                      <AddImageIcon />
                      Add Image
                    </AddImageButton>
                    <Input
                      type="file"
                      id={`editedProjectImageUpload-${index}`}
                      accept="image/*"
                      onChange={(e) => handleAddImageToEditedProject(e.target.files[0])}
                      style={{ display: 'none' }}
                    />
                  </InputLabel>
                  <ProjectImages>
                    {editedProjectData.pictures &&
                      editedProjectData.pictures.map((picture, picIndex) => (
                        <ImagePreviewWrapper key={picIndex}>
                          <ProjectImage
                            src={picture.url}
                            alt={`Preview ${picIndex}`}
                            style={{ width: '100px', height: '100px' }}
                          />
                          <ImageDeleteButton
                            onClick={() => handleDeleteImageFromEditedProject(picIndex)}
                          />
                        </ImagePreviewWrapper>
                      ))}
                  </ProjectImages>
                  <AddProjectContainer>
                    <AddItemButton onClick={handleUpdateProject}>
                      Update Project
                    </AddItemButton>
                  </AddProjectContainer>
                </ProjectContainerOverall>
              )}
            </ProjectWrapper>
          ))
        ) : (
          <div>
            <p>No projects available. Please add some projects.</p>
          </div>
        )}
      </SuccessWrapper>
    </>
  );
};

export default Successes;