import React from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

const Page = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const InputLabel = styled.label`
  margin-top: 5px;
  font-size: 0.9em;
  color: #333;
  display: flex;
`;

const Title = styled.h1`
  color: black;
`;

const Verticaldiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LargeInputField = styled.textarea`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 150px;
  resize: none;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const Loan = ({ loanEntries, setLoanEntries }) => { // Access props correctly
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoanEntries((prevLoanEntries) => ({
      ...prevLoanEntries,
      [name]: value,
    }));
  };

  return (
    <Page>
      <ToastContainer />
      <Title>Loan Preferences</Title>
      <Form>
        <Verticaldiv>
          <InputLabel>Student Loan in USD</InputLabel>
          <Input
            type="number"
            name="student_total_loan_amount"
            value={loanEntries.student_total_loan_amount}
            onChange={handleInputChange}
            placeholder="Enter Amount"
            required
          />
        </Verticaldiv>
        <Verticaldiv>
          <InputLabel>Why am I requesting the fund</InputLabel>
          <LargeInputField
            name="fund_request_reason"
            value={loanEntries.fund_request_reason}
            onChange={handleInputChange}
            placeholder="Enter your reason for requesting the fund"
            required
          />
        </Verticaldiv>
      </Form>
    </Page>
  );
};

export default Loan;
