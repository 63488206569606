import * as React from 'react';
import { PieChart, BarChart } from '@mui/x-charts';
import styled, { keyframes } from 'styled-components';
import FundingSuccessLogoSVG from '../component/SVG/FundingSuccessLogoSVG';
import { useNavigate } from 'react-router-dom';

// Mock data for the pie chart (money awarded to students in various categories)
const moneyAwardedData = {
  engineering: 500000,
  stem: 350000,
  liberalArts: 200000,
  communication: 150000,
  others: 100000,
};

// Mock data for the bar chart (money donated over the year 2024, monthly)
const donationsOver2024 = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  donations: [50000, 75000, 90000, 60000, 85000, 70000, 95000, 80000, 65000, 78000, 92000, 100000],
};

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
`;

const Title = styled.h4`
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 40px;
`;


const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Setting chart width to 50% of the screen */
  margin-bottom: 40px;
`;

const Subtitle = styled.h5`
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
`;

const Paper = styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLogo = styled(FundingSuccessLogoSVG)`
  height: 30px; 
  position: absolute;
  left: 20px;
  top: 20px;

  @media (max-width: 768px) {
    height: 25px; /* Smaller logo for mobile */
    left: 10px;   /* Adjust left position for mobile */
    top: 10px;    /* Adjust top position for mobile */
  }

  @media (max-width: 480px) {
    height: 20px; /* Even smaller logo for very small screens */
    left: 8px;
    top: 8px;
  }
`;


const Statistics = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <StyledLogo onClick={() => navigate('/')}/>
      {/* Main Title */}
      <Title>Funding Success Statistics</Title>

      {/* Pie Chart: Money Awarded to Students in Different Categories */}
      <ChartContainer>
       
        <Paper>
        <Subtitle>Money Awarded to Students by Category</Subtitle>
          <PieChart
              series={[
                {
                  data: [
                    { value: moneyAwardedData.engineering, label: 'Engineering' },
                    { value: moneyAwardedData.stem, label: 'STEM' },
                    { value: moneyAwardedData.liberalArts, label: 'Liberal Arts' },
                    { value: moneyAwardedData.communication, label: 'Communication' },
                    { value: moneyAwardedData.others, label: 'Others' },
                  ],
                  tooltip: (params) => `${params.label}: penis`, // Tooltip shows amount with dollar sign
                },
              ]}
            height={200}
           
          />
        </Paper>
      </ChartContainer>

      {/* Bar Chart: Money Donated Over the Year 2024 */}
      <ChartContainer>
       
        <Paper>
        <Subtitle>Donations Over 2024</Subtitle>
          <BarChart
            series={[
                {
                data: donationsOver2024.donations,
                color: '#003366', // Adding color to the bars
                },
            ]}
            xAxis={[
              {
                data: donationsOver2024.months,
                scaleType: 'band',
              },
            ]}
            height={400}
            margin={{ top: 20, bottom: 60, left: 70, right: 10 }}
            // add color the bar with hex code 003366 here
          />
        </Paper>
      </ChartContainer>
    </Container>
  );
};

export default Statistics;
