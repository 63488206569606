
// export default LandingPage;
import React from 'react';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
// import BeaverLogoSVG from './BeaverLogoSVG';
// import BeaverLogoDefaultAnimationSVG from './BeaverLogoDefaultAnimationSVG';
// import BeaverLogoWavingAnimationSVG from './BeaverLogoWavingAnimationSVG';
// import BeaverLogoBlinkAnimation from './BeaverLogoBlinkAnimation';
// import BeaverLogoWinkAnimation from './BeaverLogoWinkAnimation';
// import BeaverLogoPencilAnimation from './BeaverLogoPencilAnimation';
import FundingSuccessLogoSVG from '../component/SVG/FundingSuccessLogoSVG';
import BeaverSmileSVG from '../component/SVG/BeaverSmileSVG'


// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // This makes sure that our container takes full viewport height
  padding: 20px;
  background-color: white; // Adjust the color to match the background color of your brand
  color: white;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3em; // Regular font size for desktop
  margin-bottom: 20px;
  padding: 0 10px; // Default padding

  @media (max-width: 768px) { // This media query will apply styles for screens that are 768px wide or less
    font-size: 2em; // Smaller font size for smartphones
    padding: 0 10px; // Padding on the left and right
  }

  span {
    color: #add8e6; // Light blue color for specific parts of the title
  }
`;


const Subtitle = styled.p`
  font-size: 1.5em;
  margin-bottom: 50px;
  color: #577483;

  span {
    color: #03377e; // Light blue color for specific parts of the subtitle
  }
`;

// const BeaverContainer = styled.div`
//   display: flex;
//   height: 400px;
//   width: 1200px;
// `;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #03377e; // Adjust the button color to match your brand
  color: #ffffff;

  &:hover {
    background-color: #03377e;
  }
`;



const LandingPage = () => {
  const navigate = useNavigate();
  const start = () => {
 
    navigate("/login");
     
  };
  return (
    <Container>
      {/* <BeaverContainer>
       <BeaverLogoPencilAnimation/>
       <BeaverLogoWavingAnimationSVG/>
       <BeaverLogoBlinkAnimation/>
       <BeaverLogoWinkAnimation/>
      </BeaverContainer>  */}
      {/* <BeaverSmileSVG/> */}
      <FundingSuccessLogoSVG/>
      <Subtitle>
        <span>Donor</span>, support and invest in students who have consistently demonstrated exceptional talent and expertise in their respective disciplines.
      </Subtitle>
      <Subtitle>
        <span>Students</span>,  access funds available to pay your student loans by showing and demonstrating your success.
      </Subtitle>
      <Button onClick={() =>{start()}}>Start Now</Button>
    </Container>
  );
};

export default LandingPage;
