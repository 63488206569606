import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

const SectionContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SectionItem = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;


// const formatDateForInput = (dateStr) => {
//   const [dd, mm, yyyy] = dateStr.split('/');
//   return `${yyyy}-${mm}-${dd}`;
// };

const formatDateForDisplay = (dateStr) => {
  const [yyyy, mm, dd] = dateStr.split('-');
  return `${dd}/${mm}/${yyyy}`;
};

const LoansComponent = ({ loans, setLoans }) => {
  const [newLoan, setNewLoan] = useState(null);

  const handleChange = (field, value) => {
    const updatedLoans = [...loans];
    updatedLoans[field] = value;
    setLoans(updatedLoans);
  };

  const handleAddItem = (newItem) => {
    if (newItem) {
      setLoans((prev) => [...prev, newItem]);
      setNewLoan(null);
    }
  };



  return (
    <SectionContainer>
    
        <SectionItem>
         
          <InputLabel>
            Loan Amount in USD
            <Input type="text" value={loans.student_total_loan_amount} onChange={(e) => handleChange('student_total_loan_amount', e.target.value)} />
          </InputLabel>
          <InputLabel>
            Reason for the Loan
            <TextArea type="text" value={loans.fund_request_reason} onChange={(e) => handleChange( 'fund_request_reason', e.target.value)} />
          </InputLabel>
         
          
        </SectionItem>
     
    </SectionContainer>
  );
};

export default LoansComponent;
