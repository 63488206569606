import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Styled components for layout and design
const OutlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 3rem 0.875rem;

  @media (min-width: 1024px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

// New styled component for the heading
const Heading = styled.h2`
  color: #003366;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const StepCard = styled(motion.div)`
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 3rem;
  }
`;

const StepContent = styled.div`
  max-width: 26.25rem;
  text-align: center;

  h3 {
    color: #333;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  p {
    color: #666;
    margin-bottom: 1rem;
    font-size: 1.125rem;
  }

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const stepVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const Outline = () => {
  return (
    <OutlineContainer>
      {/* Adding the new heading */}
      <Heading>The Process</Heading>

      <StepCard
        initial="hidden"
        animate="visible"
        variants={stepVariants}
        whileHover={{ scale: 1.05 }}
      >
        <StepContent>
          <h3>1. Define and fund your award.</h3>
          <p>
            Once you donate, your scholarship will be published and will begin
            receiving applications.
          </p>
        </StepContent>
      </StepCard>

      <StepCard
        initial="hidden"
        animate="visible"
        variants={stepVariants}
        whileHover={{ scale: 1.05 }}
      >
        <StepContent>
          <h3>2. We’ll narrow down a list of finalists.</h3>
          <p>
            Based on the selection criteria we define together, we’ll review
            hundreds of applications and mark the best ones for you.
          </p>
        </StepContent>
      </StepCard>

      <StepCard
        initial="hidden"
        animate="visible"
        variants={stepVariants}
        whileHover={{ scale: 1.05 }}
      >
        <StepContent>
          <h3>3. Swipe to find your perfect match.</h3>
          <p>
            Use our intuitive swipe feature to browse through top candidates and
            find the student who best matches your vision.
          </p>
        </StepContent>
      </StepCard>

      <StepCard
        initial="hidden"
        animate="visible"
        variants={stepVariants}
        whileHover={{ scale: 1.05 }}
      >
        <StepContent>
          <h3>4. Work with our donor team to select winners and finalists.</h3>
          <p>
            100% of your donation goes directly to fund the education of
            deserving winners.
          </p>
        </StepContent>
      </StepCard>
    </OutlineContainer>
  );
};

export default Outline;
