import React, {  } from 'react';
import styled from 'styled-components';
import { FaTrash, FaPlusCircle } from 'react-icons/fa';

const SectionContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SectionItem = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const AddItemButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: #577483;
  }

  &:focus {
    outline: none;
  }
`;

const PlusIcon = styled(FaPlusCircle)`
  margin-right: 5px;
`;

const TrashWrapper = styled.div`
  width: 20px;
  padding: 10px;
  color: #003366;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

// const InputWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
// `;

// const MarginWrapper = styled.div`
//   width: 100%;
//   margin-bottom: 15px;
// `;

const LoanDonorComponent = ({ loanEntries, setLoanEntries }) => {
  const handleInputChange = (index, field, value) => {
    const updatedLoanEntries = [...loanEntries];
    updatedLoanEntries[index][field] = value;
    setLoanEntries(updatedLoanEntries);
  };

  const handleDeleteItem = (index) => {
    setLoanEntries((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddItem = () => {
    setLoanEntries((prev) => [
      ...prev,
      {
        total_donation_amount: '',
        number_of_people: '',
        time_span: '',
        most_important_aspect: '',
      },
    ]);
  };

  return (
    <SectionContainer>
      {loanEntries.map((entry, index) => (
        <SectionItem key={index}>
          <InputLabel>
            Total Amount to Donate in Dollar
            <Input
              type="number"
              value={entry.total_donation_amount}
              onChange={(e) => handleInputChange(index, 'total_donation_amount', e.target.value)}
              placeholder="Enter Amount"
              required
            />
          </InputLabel>

          <InputLabel>
            Number of People to Donate To
            <Input
              type="number"
              value={entry.number_of_people}
              onChange={(e) => handleInputChange(index, 'number_of_people', e.target.value)}
              placeholder="Enter Number"
              required
            />
          </InputLabel>

          <InputLabel>
            Time Span for Donation (in months)
            <Input
              type="number"
              value={entry.time_span}
              onChange={(e) => handleInputChange(index, 'time_span', e.target.value)}
              placeholder="Enter Time Span"
              required
            />
          </InputLabel>

          <InputLabel>
            Most Important Aspect of the Stipend
            <TextArea
              value={entry.most_important_aspect}
              onChange={(e) => handleInputChange(index, 'most_important_aspect', e.target.value)}
              placeholder="Enter Most Important Aspect"
              rows="4"
              required
            />
          </InputLabel>

          {loanEntries.length > 1 && (
            <TrashWrapper onClick={() => handleDeleteItem(index)}>
              <FaTrash />
            </TrashWrapper>
          )}
        </SectionItem>
      ))}
      <AddItemButton onClick={handleAddItem}>
        <PlusIcon />
        Add Donation Entry
      </AddItemButton>
    </SectionContainer>
  );
};

export default LoanDonorComponent;
