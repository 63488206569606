import React from 'react';
import styled from 'styled-components';
import { FaPlusCircle } from 'react-icons/fa';

const Page = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eaeaea;
  margin: 20px 0;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const DualInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
`;

const Title = styled.h1`
  color: black;
`;

const Verticaldiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AddMoreButton = styled.button`
  background: none;
  color: #003366;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    background: #e7f5fe;
  }

  &:focus {
    outline: none;
  }
`;

const PlusIcon = styled(FaPlusCircle)`
  margin-right: 5px;
`;

const Experience = ({ experienceEntries, setExperienceEntries }) => {
  const handleInputChange = (index, event) => {
    const values = [...experienceEntries];
    values[index][event.target.name] = event.target.value;
    setExperienceEntries(values);
  };

  const handleAddMore = () => {
    setExperienceEntries([...experienceEntries, { jobTitle: '', company: '', location: '', startDate: '', endDate: '' }]);
  };

  return (
    <Page>
      <Title>Job Experience</Title>
      <Form>
        {experienceEntries.map((entry, index) => (
          <div key={index}>
            {index > 0 && <Divider />}
            <Verticaldiv>
              <InputLabel>Job Title</InputLabel>
              <Input
                type="text"
                name="jobTitle"
                value={entry.jobTitle}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter Job Title"
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>Company</InputLabel>
              <Input
                type="text"
                name="company"
                value={entry.company}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter Company"
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>Location</InputLabel>
              <Input
                type="text"
                name="location"
                value={entry.location}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter Location"
                required
              />
            </Verticaldiv>
            <DualInputContainer>
              <div>
                <InputLabel>Start Date</InputLabel>
                <Input
                  type="text"
                  name="startDate"
                  value={entry.startDate}
                  onChange={event => handleInputChange(index, event)}
                  placeholder="DD/MM/YYYY"
                  required
                />
              </div>
              <div>
                <InputLabel>End Date</InputLabel>
                <Input
                  type="text"
                  name="endDate"
                  value={entry.endDate}
                  onChange={event => handleInputChange(index, event)}
                  placeholder="DD/MM/YYYY"
                  required
                />
              </div>
            </DualInputContainer>
          </div>
        ))}
       
        <AddMoreButton type="button" onClick={handleAddMore}>
          <PlusIcon />
          Add More
        </AddMoreButton>
        <Verticaldiv>
          <InputLabel>LinkedIn Profile</InputLabel>
          <Input
            type="url"
            name="linkedin"
            value={experienceEntries[0]?.linkedin || ''}
            onChange={event => {
              const values = [...experienceEntries];
              values.forEach((entry, index) => {
                if (index === 0) {
                  entry.linkedin = event.target.value;
                }
              });
              setExperienceEntries(values);
            }}
            placeholder="Enter LinkedIn Profile URL"
            required
          />
        </Verticaldiv>
      </Form>
    </Page>
  );
};

export default Experience;
