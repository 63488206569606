import React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
  padding-left: 20px;
  padding-right: 20px;
  position: fixed; /* Change to fixed */
  bottom: 0; /* Stick to the bottom of the page */
  left: 0;
  z-index: 1000;
`;

const BackButton = styled.button`
  position: absolute;
  left: 60px;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  background: none;
  border: none;
  color: #737c87;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 10px 16px 10px 10px;
  cursor: pointer;
  user-select: none;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const AdvanceButton = styled.button`
  height: 48px;
  width: 320px;
  text-align: center;
  border-radius: 4px;
  padding: 16px 20px;
  border: none;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 500;
  background: #003366;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
`;


const DonorFooter = () => {
  return (
    <Footer id="wizard-footer">
      <BackButton>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.25 13.5L6.75 9L11.25 4.5"
            stroke="#6D7B8C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Back</span>
      </BackButton>
      <AdvanceButton aria-label="Continue">
        <span>Continue</span>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.75 13.5L11.25 9L6.75 4.5"
            stroke="#ACB4BF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </AdvanceButton>
    </Footer>
  );
};

export default DonorFooter;
