import React, { useEffect } from 'react';
import styled from 'styled-components';
import { auth, db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

const Page = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eaeaea;
  margin: 20px 0;
`;

const Select = styled.select`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
`;

const Title = styled.h1`
  color: black;
`;

const Verticaldiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Personal = ({ personalEntries, setPersonalEntries }) => {
  const currentUserUid = auth.currentUser ? auth.currentUser.uid : 'anonymous';

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUserUid !== 'anonymous') {
        const userRef = doc(db, 'users', currentUserUid);
        try {
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            setPersonalEntries([{
              name: docSnap.data().name,
              phone: docSnap.data().mobile,
              email: docSnap.data().email,
              gender: docSnap.data().gender || '',
              date_of_birth: formatDateToMMDDYYYY(docSnap.data().date_of_birth || ''), // Format DOB to MM/DD/YYYY for display
              linkedin: docSnap.data().linkedin || '' // Add LinkedIn URL from user data
            }]);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [currentUserUid, setPersonalEntries]);

  const formatDateToMMDDYYYY = (date) => {
    if (!date) return '';
    // Assuming the date is stored in DD/MM/YYYY format, convert to MM/DD/YYYY
    const [dd, mm, yyyy] = date.split('/');
    return `${mm}/${dd}/${yyyy}`;  // Reformat to MM/DD/YYYY for display
  };

  const convertToDDMMYYYY = (date) => {
    if (!date) return '';
    // Convert from MM/DD/YYYY to DD/MM/YYYY for internal storage
    const [mm, dd, yyyy] = date.split('/');
    return `${dd}/${mm}/${yyyy}`;
  };

  const handleDateInputChange = (index, event) => {
    const values = [...personalEntries];
    const inputDate = event.target.value;

    // Convert input MM/DD/YYYY to DD/MM/YYYY for internal storage
    values[index].date_of_birth = convertToDDMMYYYY(inputDate);
    setPersonalEntries(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...personalEntries];
    values[index][event.target.name] = event.target.value;
    setPersonalEntries(values);
  };

  return (
    <Page>
      <Title>Personal Information</Title>
      <Form>
        {personalEntries && personalEntries.length > 0 && personalEntries.map((entry, index) => (
          <div key={index}>
            {index > 0 && <Divider />}
            <Verticaldiv>
              <InputLabel>Name</InputLabel>
              <Input
                type="text"
                name="name"
                value={entry.name}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter your name"
                required
              />
            </Verticaldiv>
            
            <Verticaldiv>
              <InputLabel>Phone</InputLabel>
              <Input
                type="text"
                name="phone"
                value={entry.phone}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter your number"
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>Gender</InputLabel>
              <Select
                name="gender"
                value={entry.gender}
                onChange={event => handleInputChange(index, event)}
                required
              >
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="non_binary">Non Binary</option>
                <option value="prefernottodisclose">Prefer not to disclose</option>
              </Select>
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>Date of Birth</InputLabel>
              <Input
                type="text"
                name="date_of_birth"
                value={entry.date_of_birth}  // Display as MM/DD/YYYY
                onChange={event => handleDateInputChange(index, event)}
                placeholder="MM/DD/YYYY"  // Keep placeholder as MM/DD/YYYY
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>LinkedIn URL</InputLabel>
              <Input
                type="text"
                name="linkedin"
                value={entry.linkedin}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter your LinkedIn profile URL"
              />
            </Verticaldiv>
          </div>
        ))}
      </Form>
    </Page>
  );
};

export default Personal;
