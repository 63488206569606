import React from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  overflow-y: auto;
  height: 60vh;
`;

const Button = styled.button`
  background-color: ${props => (props.selected ? '#003366' : 'white')};
  color: ${props => (props.selected ? 'white' : 'black')};
  border: 1px solid #ddd;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  width: calc(50% - 20px); /* Adjust width to fit 2 buttons in a row */
  max-width: 200px; /* Limit button width */
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
  
  @media screen and (max-width: 768px) {
    width: calc(100% - 20px); /* Full width for small screens */
  }
`;

const Title = styled.h1`
  font-size: 18px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const Interests = ({ selectedInterests, setSelectedInterests }) => {
  const interests = [
    'History', 'Sport', 'Art', 'Depression', 'Politics', 'Obesity', 'Social',
    'Health', 'Concerts', 'Environment', 'Movie Maker', 'Space', 'Museum',
    'Water', 'Migration', 'Animals', 'Food', 'Energy', 'Insects', 'Nuclear',
    'Transportation', 'Poverty', 'World Peace', 'Violence', 'Electricity',
    'Vaccines', 'Agriculture', 'Hunger', 'Ecology', 'Ocean', 'Education',
  ];

  const toggleInterest = interest => {
    if (selectedInterests.includes(interest)) {
      setSelectedInterests(selectedInterests.filter(i => i !== interest));
    } else {
      if (selectedInterests.length < 5) {
        setSelectedInterests([...selectedInterests, interest]);
      } else {
        toast.error('You can only select 5 interests'); // Using toast for notification
      }
    }
  };

  return (
    <Container>
      <Title>Choose 5 or more areas of interest you want to fund</Title>
      <ButtonContainer>
        {interests.map(interest => (
          <Button
            key={interest}
            selected={selectedInterests.includes(interest)}
            onClick={() => toggleInterest(interest)}
          >
            {interest}
          </Button>
        ))}
      </ButtonContainer>
      <ToastContainer position="top-center" autoClose={3000} />
    </Container>
  );
};

export default Interests;
