import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../component/cropImage'; // We'll create this utility function
import { v4 as uuidv4 } from 'uuid';
// import { FaCheck, FaPencilAlt } from 'react-icons/fa';
import { auth, db, storage } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoadingAnimation from '../component/utils/LoadingAnimation';
import 'react-toastify/dist/ReactToastify.css';

const Page = styled.div`
  background: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

const Container = styled.div`
  background: #fff;
  padding: 20px;
  max-width: 600px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  text-align: center;
  @media (max-width: 768px) {
    width: 90%;
    box-shadow: none;
  }
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 0.5em;
`;

const ProfilePictureWrapper = styled.div`
  position: relative;
  margin: 20px auto;
`;

const ProfilePictureInput = styled.input`
  display: none;
`;

const ProfilePictureLabel = styled.label`
  cursor: pointer;
  display: inline-block;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #ccc;
`;

const Link = styled.a`
  display: block;
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SignupText = styled.p`
  color: #666;
  text-align: center;
`;

// const PencilIcon = styled(FaPencilAlt)`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   background: #fff;
//   border-radius: 50%;
//   padding: 5px;
//   border: 1px solid #ccc;
//   color: #333;
// `;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color:black;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

// const TermsServiceCheckmark = styled(FaCheck)`
//   color: limegreen;
//   margin-left: 5px;
// `;

const Button = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
`;

// const SignInLink = styled.div`
//   margin-top: 10px;
//   text-align: center;
// `;

const LoadingAnimationDots = styled.div`
  position: absolute;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// const Spinner = styled.div`
//   border: 4px solid rgba(0, 0, 0, 0.1);
//   border-top: 4px solid #003366;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   animation: ${spin} 1s linear infinite;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background: white;
`;

const UserDataInput = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    mobile: '',
    agreeToTerms: false,
    profilePicture: null,
  });
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        setUserData((prevState) => ({ ...prevState, email: user.email }));
      } else {
        console.log("there is currently no user");
      }
    });

    return () => unsubscribe();
  }, []);

  const addUserInformation = async (userId, data) => {
    try {
      await setDoc(doc(db, 'users', userId), data);
      console.log("User information added to Firestore");
    } catch (error) {
      console.error("Error adding user information: ", error);
    }
  };

  const uploadProfilePicture = async (userId, file) => {
    const storageRef = ref(storage, `profilePictures/${userId}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const isValidMobile = (mobile) => {
    const mobileRegex = /^[0-9+]*$/;
    return mobileRegex.test(mobile);
  };

  const checkIfDonor = async (email) => {
    const donorDoc = await getDoc(doc(db, 'donorsEmail', 'donors'));
    if (donorDoc.exists()) {
      const donorData = donorDoc.data();
      return Object.values(donorData).includes(email);
    }
    return false;
  };

  const handleUserData = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (!userData.agreeToTerms) {
      toast.error("You must agree to the terms of service before signing up.");
      return;
    }

    if (!isValidEmail(userData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!isValidPassword(userData.password)) {
      toast.error("Password must be at least 8 characters long and contain at least one uppercase letter.");
      return;
    }

    if (!isValidMobile(userData.mobile)) {
      toast.error("Please enter a valid mobile number.");
      return;
    }

    try {
      const isDonor = await checkIfDonor(userData.email) ? 1 : 0;

      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
      const user = userCredential.user;

      let profilePictureURL = '';
      if (croppedImage) {
        const croppedFile = new File([croppedImage], `${uuidv4()}.jpg`, { type: 'image/jpeg' });
        profilePictureURL = await uploadProfilePicture(user.uid, croppedFile);
      }

      await addUserInformation(user.uid, {
        name: userData.name,
        email: userData.email,
        mobile: userData.mobile,
        profilePictureURL,
        isDonor,
        sign_up_step_completed: 0,
      });

      navigate('/signupinformation');
    } catch (error) {
      console.error('Error updating user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      setUserData((prevState) => ({
        ...prevState,
        profilePicture: file,
      }));
      setShowCropper(true);
    } else {
      setUserData((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropImage = useCallback(async () => {
    try {
      const imageUrl = URL.createObjectURL(userData.profilePicture);
      console.log('Cropping image:', imageUrl);
      const croppedImg = await getCroppedImg(imageUrl, croppedAreaPixels);
      setCroppedImage(croppedImg);
      setShowCropper(false);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  }, [croppedAreaPixels, userData.profilePicture]);
  
  const gotosignin = async () => {
    navigate("/logging");
  };

  return (
    <Page>
      {loading && <LoadingAnimationDots><LoadingAnimation /></LoadingAnimationDots>}
      <Container>
        <Title>Welcome {userData.name || 'student'}</Title>
        <ProfilePictureWrapper>
          {showCropper ? (
            <CropContainer>
              <Cropper
                image={URL.createObjectURL(userData.profilePicture)}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
              
            </CropContainer>
          ) : (
            <ProfilePictureLabel htmlFor="profilePicture">
              {croppedImage ? (
                <ProfileImage src={URL.createObjectURL(croppedImage)} alt="Profile" />
              ) : (
                <ProfileImage src="https://firebasestorage.googleapis.com/v0/b/studentevaluation-9d972.appspot.com/o/profilePictures%2FPencil-6.svg?alt=media&token=bfae5e10-90a5-48a8-9d83-cc7e0429e053" alt="Pencil" />
              )}
              <ProfilePictureInput
                id="profilePicture"
                name="profilePicture"
                type="file"
                accept="image/*"
                onChange={handleChange}
              />
            </ProfilePictureLabel>
          )}
          {showCropper && 
            <Button onClick={handleCropImage}>Crop Image</Button>
          }
        </ProfilePictureWrapper>
        <Form onSubmit={handleUserData}>
          <Input
            name="name"
            placeholder="Name"
            value={userData.name}
            onChange={handleChange}
            autoFocus
          />
          <Input
            name="email"
            type="email"
            placeholder="Email"
            value={userData.email}
            onChange={handleChange}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={userData.password}
            onChange={handleChange}
          />
          <Input
            name="mobile"
            placeholder="Mobile"
            value={userData.mobile}
            onChange={handleChange}
          />
          <CheckboxLabel>
            <Checkbox
              name="agreeToTerms"
              type="checkbox"
              checked={userData.agreeToTerms}
              onChange={handleChange}
            />
            I agree to the Terms of Service
          </CheckboxLabel>
          <Button type="submit">Sign Up</Button>
        </Form>
        <SignupText>
          Have an account? <Link onClick={gotosignin}>Sign In</Link>
        </SignupText>
      </Container>
      <ToastContainer />
    </Page>
  );
};

export default UserDataInput;
