import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUserGraduate, FaHandHoldingUsd, FaUniversity, FaCheck } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';

const Page = styled.div`
  background: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background: #fff;
  padding: 20px;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
`;

const Title = styled.h2`
  color: #003366; // dark blue
  text-align: center;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 20px;
`;

const OptionLabel = styled.label`
  display: block;
  border: 2px solid ${(props) => (props.selected ? '#003366' : '#ccc')}; // dark blue when selected, gray otherwise
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  position: relative;
  background-color: ${(props) => (props.selected ? '#003366' : 'white')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  cursor: pointer;
  transition: border-color 0.3s;
`;

const IconContainer = styled.span`
  margin-right: 10px;
`;

const OptionText = styled.span`
  font-size: 1.1em;
  font-weight: bold;
`;

const SubText = styled.span`
  display: block;
  font-size: 0.8em;
`;

const Checkmark = styled(FaCheck)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${(props) => (props.selected ? 'limegreen' : 'transparent')};
`;

const ContinueButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  background-color: #003366; // dark blue
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
`;

const ContinuePage = () => {
  const [selectedOption, setSelectedOption] = useState('students');
  const navigate = useNavigate();
  const start = () => {
    console.log("selected opt", selectedOption)
    if (selectedOption === 'students') {
      navigate("/logging");
    } else if (selectedOption === 'donors') {
      navigate("/swipe");
    }
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Page>
      <Container>
        <Title>Continue as</Title>
        <Description>To continue to the next page, please select which one you are</Description>
        <form>
          {[
            { value: 'students', Icon: FaUserGraduate, title: 'Students', description: 'Show your career successes' },
            { value: 'donors', Icon: FaHandHoldingUsd, title: 'Donors', description: 'Fund great people with amazing accomplishments' },
            { value: 'lenders', Icon: FaUniversity, title: 'Lenders, Banks and Universities', description: 'Provide accurate information' },
          ].map((option) => (
            <OptionLabel key={option.value} selected={selectedOption === option.value}>
              <input
                type="radio"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={handleChange}
                style={{ display: 'none' }} // Hide the default radio button
              />
              <IconContainer>
                <option.Icon size="1.5em" color={selectedOption === option.value ? 'white' : '#003366'} />
              </IconContainer>
              <OptionText>{option.title}</OptionText>
              <SubText>{option.description}</SubText>
              <Checkmark selected={selectedOption === option.value} size="1.5em" />
            </OptionLabel>
          ))}
          <ContinueButton onClick={() => {start()}}>Continue</ContinueButton>
        </form>
      </Container>
    </Page>
  );
};

export default ContinuePage;
