import React from 'react';
import styled from 'styled-components';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  color: #003366;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
    min-width: 0;
    max-height: 6%;
    box-shadow: none;
  }
`;

const FileUploadButton = styled.label`
  display: inline-block;
  padding: 12px 24px;
  margin-bottom: 15px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #002244;
  }

  input {
    display: none;
  }
`;

const Button = styled.button`
  padding: 12px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #002244;
  }
`;

const Title = styled.h1`
  padding-top: 30px;
  }
`;

const ScholarshipExcelInput = () => {

  const handleExcelUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName], { defval: '' });

      for (const row of worksheet) {
        const scholarshipName = row['ScholarshipName'];
        const scholarshipImage = row['Image'];
        const applyTime = parseInt(row['ApplicationTime'], 10) || 0;
        const isEssayRequired = Boolean(row['EssayRequired']);
        const description = row['Description'];
        const categories = row['Categories'] ? row['Categories'].split(',').map(cat => cat.trim()) : [];
        const deadline = XLSX.SSF.format("yyyy-mm-dd", row['ApplicationDeadline']);
        const amount = parseFloat(row['ScholarshipAmount']) || 0;

        if (!scholarshipName || !description || !deadline || !amount || !applyTime) {
          toast.error(`Missing required fields in the row with scholarship name: ${scholarshipName || 'Unnamed'}`);
          console.log(`Row skipped due to missing data:`, row);
          continue;
        }

        try {

          const scholarshipData = {
            scholarshipName,
            scholarshipImage: scholarshipImage || '',
            applyTime,
            isEssayRequired,
            description,
            categories,
            deadline,
            amount,
          };

          const docRef = doc(db, 'scholarships', scholarshipName);
          await setDoc(docRef, scholarshipData);
          toast.success(`Scholarship "${scholarshipName}" added successfully!`);
          console.log(`Scholarship data uploaded successfully:`, scholarshipData);

        } catch (error) {
          toast.error(`Error uploading scholarship: ${scholarshipName}`);
          console.log(`Error uploading data for scholarship: ${scholarshipName}`, error);
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Wrapper>
        <Title>Upload Scholarship Excel File</Title>
      <FileUploadButton>
        Upload Excel File
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleExcelUpload}
        />
      </FileUploadButton>
      <ToastContainer position="top-center" autoClose={3000} />
    </Wrapper>
  );
};

export default ScholarshipExcelInput;
