import React, { useState } from 'react';
import styled from 'styled-components';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTrash } from 'react-icons/fa';

const ScholarshipContainer = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  min-width: 100%;
`;

// const Wrapper = styled.div`
//   overflow-y: auto;
//   scrollbar-width: none; /* Firefox */
//   -ms-overflow-style: none;  /* IE and Edge */

//   &::-webkit-scrollbar {
//     display: none; /* Chrome, Safari, and Opera */
//   }
// `;



const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
`;

const InputLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 5px;
  color: #333;
  text-align: left;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const FileUploadButton = styled.label`
  display: inline-block;
  padding: 12px 24px;
  margin-bottom: 15px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #002244;
  }

  input {
    display: none;
  }
`;

const CategoryTag = styled(motion.div)`
  background-color: #003366;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  position: relative;

  svg {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: red;
    }
  }
`;

const ImageContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  img {
    width: 50px;
    margin-right: 10px;
  }

  svg {
    cursor: pointer;
    color: #003366;
    transition: color 0.3s ease;

    &:hover {
      color: red;
    }
  }
`;

const Button = styled.button`
  padding: 12px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #002244;
  }
`;

const Select = styled.select`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const AddMoreButton = styled.button`
  background: none;
  color: #003366;
  display: flex;
  font-size: 0.9em;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    background: #e7f5fe;
  }

  &:focus {
    outline: none;
  }
`;

const ScholarshipForm = () => {
  const [scholarships, setScholarships] = useState([
    {
      scholarshipName: '',
      scholarshipImage: null,
      applyTime: '',
      isEssayRequired: false,
      description: '',
      categories: [],
      deadline: '',
      amount: '',
    },
  ]);
  const storage = getStorage();

  const handleInputChange = (index, field, value) => {
    const newScholarships = [...scholarships];
    if ((field === 'applyTime' || field === 'amount') && value < 0) {
      toast.error(`${field === 'applyTime' ? 'Application time' : 'Amount'} cannot be negative.`);
      return;
    }
    newScholarships[index][field] = value;
    setScholarships(newScholarships);
  };

  const handleImageUpload = async (index, e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `scholarships/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);

    const newScholarships = [...scholarships];
    newScholarships[index].scholarshipImage = downloadURL;
    setScholarships(newScholarships);

    toast.success(`Uploaded image: ${file.name}`);
  };

  const handleCategoryChange = (index, e) => {
    const category = e.target.value;
    if (!category) return;

    const newScholarships = [...scholarships];

    if (newScholarships[index].categories.length >= 5) {
      toast.error('You can only select up to 5 categories.');
      return;
    }

    if (!newScholarships[index].categories.includes(category)) {
      newScholarships[index].categories.push(category);
      setScholarships(newScholarships);
    }
  };

  const handleCategoryDelete = (index, categoryIndex) => {
    const newScholarships = [...scholarships];
    newScholarships[index].categories.splice(categoryIndex, 1);
    setScholarships(newScholarships);

    toast.info('Category removed.');
  };

  const handleImageDelete = (index) => {
    const newScholarships = [...scholarships];
    newScholarships[index].scholarshipImage = null;
    setScholarships(newScholarships);

    toast.info('Image removed.');
  };

  const handleAddScholarship = () => {
    setScholarships([
      ...scholarships,
      {
        scholarshipName: '',
        scholarshipImage: null,
        applyTime: '',
        isEssayRequired: false,
        description: '',
        categories: [],
        deadline: '',
        amount: '',
      },
    ]);
  };

  const handleDeleteScholarship = (index) => {
    const newScholarships = [...scholarships];
    newScholarships.splice(index, 1);
    setScholarships(newScholarships);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    scholarships.forEach(async (scholarship) => {
      if (
        !scholarship.scholarshipName ||
        !scholarship.scholarshipImage ||
        !scholarship.applyTime ||
        !scholarship.description ||
        scholarship.categories.length === 0 ||
        !scholarship.deadline ||
        !scholarship.amount
      ) {
        toast.error('Please fill out all fields.');
        return;
      }

      try {
        const docRef = doc(db, 'scholarships', scholarship.scholarshipName);
        await setDoc(docRef, scholarship);

        toast.success(`Scholarship "${scholarship.scholarshipName}" added successfully!`);
      } catch (error) {
        toast.error('Error adding scholarship: ' + error.message);
      }
    });
  };

  const interests = [
    'History', 'Sport', 'Art', 'Depression', 'Politics', 'Obesity', 'Social',
    'Health', 'Concerts', 'Environment', 'Movie Maker', 'Space', 'Museum',
    'Water', 'Migration', 'Animals', 'Food', 'Energy', 'Insects', 'Nuclear',
    'Transportation', 'Poverty', 'World Peace', 'Violence', 'Electricity',
    'Vaccines', 'Agriculture', 'Hunger', 'Ecology', 'Ocean', 'Education',
  ];

  return (
    <>
      {scholarships.map((scholarship, index) => (
        <ScholarshipContainer key={index}>
          <Form>
            <InputLabel>Scholarship Name</InputLabel>
            <Input
              type="text"
              placeholder="Enter Scholarship Name"
              value={scholarship.scholarshipName}
              onChange={(e) => handleInputChange(index, 'scholarshipName', e.target.value)}
              required
            />

            <InputLabel>Upload Scholarship Image</InputLabel>
            <FileUploadButton>
              Upload Image
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(index, e)}
                required
              />
            </FileUploadButton>
            {scholarship.scholarshipImage && (
              <ImageContainer
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              >
                <img
                  src={scholarship.scholarshipImage}
                  alt="Scholarship"
                />
                <FaTrash onClick={() => handleImageDelete(index)} />
              </ImageContainer>
            )}

            <InputLabel>Description</InputLabel>
            <TextArea
              placeholder="Enter Scholarship Description"
              value={scholarship.description}
              onChange={(e) => handleInputChange(index, 'description', e.target.value)}
              required
            />

            <InputLabel>Categories (Select up to 5)</InputLabel>
            <Select onChange={(e) => handleCategoryChange(index, e)} defaultValue="">
              <option value="" disabled>Select a category</option>
              {interests.map((interest) => (
                <option key={interest} value={interest}>
                  {interest}
                </option>
              ))}
            </Select>

            <AnimatePresence>
              {scholarship.categories.map((category, catIndex) => (
                <CategoryTag
                  key={catIndex}
                >
                  {category}
                  <FaTrash onClick={() => handleCategoryDelete(index, catIndex)} />
                </CategoryTag>
              ))}
            </AnimatePresence>

            <InputLabel>Application Time (in minutes)</InputLabel>
            <Input
              type="number"
              placeholder="Enter Time Required"
              value={scholarship.applyTime}
              onChange={(e) => handleInputChange(index, 'applyTime', e.target.value)}
              required
            />

            <InputLabel>Scholarship Amount</InputLabel>
            <Input
              type="number"
              placeholder="Enter Scholarship Amount"
              value={scholarship.amount}
              onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
              required
            />

            <InputLabel>
              <input
                type="checkbox"
                checked={scholarship.isEssayRequired}
                onChange={() => handleInputChange(index, 'isEssayRequired', !scholarship.isEssayRequired)}
              />
              Essay Required?
            </InputLabel>
            
            <InputLabel>Application Deadline</InputLabel>
            <Input
              type="date"
              value={scholarship.deadline}
              onChange={(e) => handleInputChange(index, 'deadline', e.target.value)}
              required
            />

            {index > 0 && (
              <Button onClick={() => handleDeleteScholarship(index)}>
                Remove Scholarship
              </Button>
            )}
          </Form>
        </ScholarshipContainer>
      ))}

      <AddMoreButton onClick={handleAddScholarship}>+ Add Another Scholarship</AddMoreButton>
      <Button onClick={handleSubmit}>Submit All Scholarships</Button>
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default ScholarshipForm;
