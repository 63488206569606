import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { db, auth } from '../firebase';
import { collection, addDoc, query, orderBy, onSnapshot, doc, serverTimestamp } from 'firebase/firestore';
import { FaPaperPlane, FaArrowLeft } from 'react-icons/fa';

const MessagesSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // overflow-y: auto;
  padding: 10px;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    max-height: 95%;
    // padding-bottom: 70px;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
`;
const MessagesContainer = styled.div`
  overflow-y: scroll; /* Allow scrolling */
  height: 50vh; /* 50% of the viewport height */
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  padding: 10px;
  border-bottom: 1px solid #ccc;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */

  &::-webkit-scrollbar {
    width: 0; /* Safari and Chrome: Set scrollbar width to 0 */
    height: 0; /* Safari and Chrome: Set scrollbar height to 0 */
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    height: 65vh; /* Ensure the height is consistent on smaller screens */
  }
`;



const Message = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: ${(props) => (props.isCurrentUser ? '#003366' : '#e5e5e5')};
  color: ${(props) => (props.isCurrentUser ? 'white' : 'black')};
  align-self: ${(props) => (!props.isCurrentUser ? 'flex-start' : 'flex-end')};
  max-width: 60%;
`;

const MessageInputContainer = styled.form`
  display: flex;
  padding: 10px;
 
  padding-bottom: 60px;
  

  @media (max-width: 768px) {
    padding: 0 10px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MessageInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;

  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const SendButton = styled.button`
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #002244;
  }

  &:focus {
    outline: none;
  }
`;

const BackButton = styled.button`
  background-color: transparent;
  border: none;
  color: #003366;
  font-size: 1.5em;
  cursor: pointer;
  margin-right: 10px;
`;

const DonateButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    background-color: #218838;
  }

  &:focus {
    outline: none;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  position: relative;
`;

const PopupHeading = styled.h2`
  color: #003366;
  margin-bottom: 10px;
`;

const PopupSubheading = styled.p`
  color: #333;
  margin-bottom: 20px;
`;

const MoneyPicker = styled.input`
  width: 80%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const FundSuccessButton = styled.button`
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #002244;
  }

  &:focus {
    outline: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #003366;

  &:hover {
    color: #002244;
  }
`;

const ChatMessages = ({ selectedUser, onBackClick, currentUser }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [donationAmount, setDonationAmount] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const messagesEndRef = useRef(null);
  const currentUserUid = auth.currentUser ? auth.currentUser.uid : 'anonymous';

  const getConversationId = (user1, user2) => {
    return [user1, user2].sort().join('_');
  };

  useEffect(() => {
    if (selectedUser) {
      const conversationId = getConversationId(currentUserUid, selectedUser.id);
      const q = query(collection(db, 'conversations', conversationId, 'messages'), orderBy('timestamp', 'asc'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const msgs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMessages(msgs);
      });

      return () => unsubscribe();
    }
  }, [selectedUser, currentUserUid]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '' || !selectedUser) return;

    const conversationId = getConversationId(currentUserUid, selectedUser.id);
    const conversationRef = doc(db, 'conversations', conversationId);

    const newMessageData = {
      text: newMessage,
      senderId: currentUserUid,
      receiverId: selectedUser.id,
      timestamp: serverTimestamp(),
    };

    await addDoc(collection(db, 'conversations', conversationRef.id, 'messages'), newMessageData);

    setNewMessage('');
  };

  const handleGrantScholarship = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleDonate = async () => {
    setShowPopup(false);
    const donationMessage = `🎉 Amazing! ${currentUser.name} has just donated $${donationAmount} to support ${selectedUser.name}'s success! 🙌`;
    const conversationId = getConversationId(currentUserUid, selectedUser.id);
    const conversationRef = doc(db, 'conversations', conversationId);

    const donationMessageData = {
      text: donationMessage,
      senderId: currentUserUid,
      receiverId: selectedUser.id,
      timestamp: serverTimestamp(),
    };

    await addDoc(collection(db, 'conversations', conversationRef.id, 'messages'), donationMessageData);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const randomImage = "https://via.placeholder.com/40";

  return (
    <MessagesSection>
      <TopSection>
        <BackButton onClick={onBackClick}>
          <FaArrowLeft />
        </BackButton>
        <ProfileImage
          src={selectedUser.profilePictureURL || randomImage}
          alt="Profile"
        />
        {selectedUser.name}
      </TopSection>
      <MessagesContainer>
        {messages.map((msg) => (
          <Message key={msg.id} isCurrentUser={msg.senderId === currentUserUid}>
            {msg.text}
          </Message>
        ))}
        <div ref={messagesEndRef}></div>
      </MessagesContainer>
      {currentUser.isDonor === 1 && (
        <DonateButton onClick={handleGrantScholarship}>
          Grant Scholarship
        </DonateButton>
      )}
      <MessageInputContainer onSubmit={handleSendMessage}>
        <MessageInput
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <SendButton type="submit">
          <FaPaperPlane />
        </SendButton>
      </MessageInputContainer>
      {showPopup && (
        <PopupOverlay>
          <PopupContainer>
            <CloseButton onClick={handleClosePopup}>×</CloseButton>
            <PopupHeading>Thank you so much, {currentUser.name}!</PopupHeading>
            <PopupSubheading>
              We and {selectedUser.name} appreciate your trust and generosity.
            </PopupSubheading>
            <MoneyPicker
              type="number"
              value={donationAmount}
              onChange={(e) => setDonationAmount(e.target.value)}
              placeholder="Amount in dollars"
            />
            <FundSuccessButton onClick={handleDonate}>
              Fund Success
            </FundSuccessButton>
          </PopupContainer>
        </PopupOverlay>
      )}
    </MessagesSection>
  );
};

export default ChatMessages;
