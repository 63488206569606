import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


// Styled components for the layout grid
const GridContainer = styled.div`
//   margin-bottom: 7rem;
  margin-top: 3.5rem;
  display: grid;
  grid-template-areas: 'header' 'testemonial' 'logo';
  gap: 2rem;

  @media (min-width: 1024px) {
    grid-template-areas: 'header testemonial' 'logo testemonial';
    grid-template-columns: 1fr 395px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-top: 5rem;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 1fr 515px;
    padding-left: 0;
    padding-right: 0;
  }
`;

const HeaderContainer = styled.div`
  grid-area: header;
  padding: 0 1.25rem;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const HeaderText = styled.div`
  margin-bottom: 1.25rem;

  @media (min-width: 1024px) {
    max-width: 300px;
  }

  @media (min-width: 1280px) {
    margin-top: 1.25rem;
    max-width: none;
  }
`;

const Heading = styled.h1`
  color: #333;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.25;
  span {
    color: #003366; /* Brand color */
  }

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Description = styled.p`
  color: #333;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-direction: column; /* This makes the buttons stack on top of each other */

  a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    text-decoration: none;
    border-radius: 0.375rem;

    &.primary {
      background-color: #003366;
      color: white;
    }

    &.secondary {
      color: #003366;
      background-color: transparent;
      border: 1px solid #efefef;
    }

    @media (min-width: 768px) {
      flex: none;
      padding: 0 1.25rem;
      height: 4rem;
      line-height: 4rem;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row; /* On larger screens, revert back to side by side */
  }
`;


const InfoText = styled.div`
  display: flex;
  gap: 1.25rem;
  font-size: 0.875rem;
  color: #666;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const TestimonialContainer = styled.div`
  grid-area: testemonial;
  margin: 2rem auto 0;
  max-width: 395px;

  @media (min-width: 1024px) {
    margin-top: 0;
  }

  @media (min-width: 1280px) {
    max-width: 515px;
  }
`;

const TestimonialImageWrapper = styled.div`
  position: relative;
  min-height: 475px;

  @media (min-width: 768px) {
    min-height: 500px;
  }

  @media (min-width: 1280px) {
    min-height: 650px;
  }

  img {
    max-height: 500px;
    width: 100%;
    object-fit: contain;

    @media (min-width: 768px) {
      width: 395px;
    }

    @media (min-width: 1280px) {
      max-height: none;
      width: 515px;
    }
  }
`;

const Badge = styled.div`
  position: absolute;
  bottom: -6.25rem;
  left: 1.25rem;
  background-color: #003366;
  padding: 0.5rem 1.25rem;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
  }

  @media (min-width: 768px) {
    bottom: -12.5rem;
    left: -1.25rem;
    width: 440px;
  }

  @media (min-width: 1280px) {
    bottom: 0;
    left: -2rem;
    width: 440px;
  }
`;

const QuoteContainer = styled.div`
  background-color: #003366;
  padding: 1.5rem 2.5rem;
  position: relative;

  p {
    color: white;
    margin-bottom: 1.25rem;
    font-size: 1.125rem;
  }

  .quote-mark {
    position: absolute;
    top: 0.5rem;
    left: 0;
    font-size: 6rem;
    font-weight: bold;
    color: #fff;
  }
`;

const Author = styled.div`
  font-weight: bold;
  color: white;
`;

const AuthorDescription = styled.div`
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
`;

const LogoContainer = styled.div`
  grid-area: logo;
  margin-top: 12rem;

  @media (min-width: 1024px) {
    margin-top: 3.5rem;
  }
`;

const LogoSection = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: #666;
    font-size: 0.75rem;
  }

  .logos {
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    opacity: 0.5;

    img {
      max-height: 62px;
      width: auto;

      @media (min-width: 1024px) {
        width: 100px;
      }

      @media (min-width: 1280px) {
        width: 110px;
      }
    }
  }
`;

const ScholarshipSection = () => {
    const navigate = useNavigate();
  return (
    <GridContainer>
      {/* Header Section */}
      <HeaderContainer>
        <HeaderText>
          <Heading>
            <span>Change</span> Someone’s
            <br className="hidden md:inline" />
            <span>Life</span> Today
          </Heading>
        </HeaderText>
        <Description>
          Create scholarships and funds for the people and causes you care most
          about.
        </Description>
        <ButtonGroup>
          <a className="primary" onClick={() => navigate('/donor-signup')}>
            Start a Scholarship
          </a>
          <a href="/how-to" className="secondary">
            How it Works
          </a>
        </ButtonGroup>
        <InfoText>
          <div><strong>100%</strong> Goes To Deserving Recipients</div>
          <div><strong>100%</strong> Tax Deductible</div>
        </InfoText>
      </HeaderContainer>

      {/* Testimonial Section */}
      <TestimonialContainer>
        <TestimonialImageWrapper>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/studentevaluation-9d972.appspot.com/o/profilePictures%2F3ekNmd1KN9g6TmW754cngrJqevA3?alt=media&token=03991bd4-091a-4c27-954c-9aa45857c2d6"
            alt="Jan Steinhauer"
          />
        
          <QuoteContainer>
            <span className="quote-mark">“</span>
            <p>
              I started my own business to help my community and be a symbol of
              hope, rather than only individually embodying STEM excellence.
            </p>
            <Author>Jan Steinhauer</Author>
            <AuthorDescription>
              University of Texas at Austin, Entrepreneur, and Computer Engineer
            </AuthorDescription>
          </QuoteContainer>
        </TestimonialImageWrapper>
      </TestimonialContainer>

      {/* Logo Section */}
    </GridContainer>
  );
};

export default ScholarshipSection;
