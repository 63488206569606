import React from 'react';
import styled from 'styled-components';
import { FaLinkedin } from 'react-icons/fa';
import { LuShare } from "react-icons/lu";

const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: #003366;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 999;
  top: 10px;
  right: 10px;
  background: #003366;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: '×';
    font-size: 24px;
    color: white;
  }
`;

const ProfileImage = styled.img`
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: white;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const OverlayWrapper = styled.div`
  max-width: 600px;
  min-width: 600px;

  @media (max-width: 768px) {
    width: 90%;
    min-width: unset;
    padding-right: 20px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 5px 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const InfoLabel = styled.span`
  font-weight: bold;
  color: white;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const InfoValue = styled.span`
  color: white;
`;

const NameImg = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 50px;
  transform: scale(1.5);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    transform: scale(1);
  }
`;

const ProjectScore = styled.p`
  color: black;
  text-align: left;
  font-weight: bold;
`;

const ProjectContainer = styled.div`
  width: 90%;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DeckOverlay = ({ overlayProps, closeOverlay, selectedScholarship, selectedUser, setShowResume }) => {
  return (
    <Overlay style={{ transform: overlayProps.y.to((y) => `translate3d(0, ${y}, 0)`) }}>
      <CloseButton onClick={closeOverlay} />
      <NameImg>
        <ProfileImage
          src={
            selectedScholarship
              ? selectedScholarship.scholarshipImage
              : selectedUser?.profilePictureURL ||
                'https://firebasestorage.googleapis.com/v0/b/studentevaluation-9d972.appspot.com/o/profilePictures%2FBeaverProfilePicture.png?alt=media&token=99139914-31c6-41f3-9419-fd9b858a376e'
          }
          alt={selectedScholarship ? selectedScholarship.scholarshipName : selectedUser?.name}
        />
        <SectionTitle>
          {selectedScholarship ? selectedScholarship.scholarshipName : selectedUser?.name}
        </SectionTitle>
      </NameImg>

      {selectedScholarship ? (
        <OverlayWrapper>
          <InfoRow>
            <InfoLabel>Amount:</InfoLabel>
            <InfoValue>{selectedScholarship.amount}$</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Deadline:</InfoLabel>
            <InfoValue>
              {new Date(selectedScholarship.deadline).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              }).replace(/\//g, '.')}
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Description:</InfoLabel>
            <InfoValue>{selectedScholarship.description}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Categories:</InfoLabel>
            <InfoValue>{selectedScholarship.categories.join(', ')}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Essay Required:</InfoLabel>
            <InfoValue>{selectedScholarship.isEssayRequired ? 'Yes' : 'No'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Apply Time:</InfoLabel>
            <InfoValue>{selectedScholarship.applyTime} minutes</InfoValue>
          </InfoRow>
        </OverlayWrapper>
      ) : (
        selectedUser && (
          <OverlayWrapper>
            {selectedUser.educationData && selectedUser.educationData[0].major && (
              <InfoRow>
                <InfoLabel>Major:</InfoLabel>
                <InfoValue>{selectedUser.educationData[0].major}</InfoValue>
              </InfoRow>
            )}
            {selectedUser.projectData && selectedUser.projectData.length > 0 && (
              <>
                <SectionTitle>Accomplishments</SectionTitle>
                <ProjectContainer>
                  {selectedUser.projectData.map((project, projectIndex) => (
                    <ProjectScore key={projectIndex}>- {project.name}</ProjectScore>
                  ))}
                </ProjectContainer>
              </>
            )}
            {selectedUser.loanData && (
              <>
                <SectionTitle>Why am I requesting funds?</SectionTitle>
                <ProjectContainer>
                  <ProjectScore>- {selectedUser.loanData.fund_request_reason}</ProjectScore>
                </ProjectContainer>
                <InfoRow>
                  <InfoLabel>Student loan amount</InfoLabel>
                  <InfoValue>${selectedUser.loanData.student_total_loan_amount} USD</InfoValue>
                </InfoRow>
                <InfoRow>
                  <InfoLabel>Share my profile</InfoLabel>
                  <InfoValue style={{ marginLeft: '10px' }}>
                    <LuShare onClick={() => setShowResume(true)} style={{ fontSize: 'x-large' }}/>
                    <FaLinkedin 
                      style={{ fontSize: 'x-large', marginLeft: '10px'}}
                      onClick={() => window.open(selectedUser?.personalData[0]?.linkedin, '_blank')} // Open LinkedIn in a new tab
                      cursor="pointer"
                    />
                  </InfoValue>
                </InfoRow>
              </>
            )}
          </OverlayWrapper>
        )
      )}
    </Overlay>
  );
};

export default DeckOverlay;
