import React, { useState } from 'react';
import styled from 'styled-components';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import Slider from '@mui/material/Slider';

const Page = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eaeaea;
  margin: 20px 0;
`;

const Input = styled.input`
  width: 90%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
`;

const Title = styled.h1`
  color: black;
`;

const Verticaldiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SliderWrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TrashWrapper = styled.div`
  width: 20px;
  color: #003366;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const AddMoreButton = styled.button`
  background: none;
  color: #003366;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background: #e7f5fe;
  }

  &:focus {
    outline: none;
  }
`;

const PlusIcon = styled(FaPlusCircle)`
  margin-right: 5px;
`;

const Universities = ({ educationEntries = [], setEducationEntries }) => {
  const isValidEducationEntries = () => {
    return (
      Array.isArray(educationEntries) &&
      educationEntries.every(entry => 
        Array.isArray(entry.university) &&
        entry.university.every(university => typeof university.university === 'string') &&
        Array.isArray(entry.majors) &&
        entry.majors.every(major => typeof major.major === 'string') &&
        Array.isArray(entry.gpa) &&
        entry.gpa.length === 2 &&
        entry.gpa.every(gpaValue => typeof gpaValue === 'number') &&
        typeof entry.startDate === 'string' &&
        typeof entry.endDate === 'string'
      )
    );
  };

  const handleInputChange = (index, event) => {
    const values = [...educationEntries];
    values[index][event.target.name] = event.target.value;
    setEducationEntries(values);
  };

  const handleUniversityChange = (uniIndex, universityIndex, event) => {
    const values = [...educationEntries];
    values[uniIndex].university[universityIndex][event.target.name] = event.target.value;
    setEducationEntries(values);
  };

  const handleMajorChange = (uniIndex, majorIndex, event) => {
    const values = [...educationEntries];
    values[uniIndex].majors[majorIndex][event.target.name] = event.target.value;
    setEducationEntries(values);
  };

  const handleAddMore = () => {
    setEducationEntries([
      ...educationEntries, 
      { university: [{ university: '' }], majors: [{ major: '' }], gpa: [2.0, 3.5], startDate: '', endDate: '' }
    ]);
  };

  const handleAddMajor = (index) => {
    const values = [...educationEntries];
    values[index].majors.push({ major: '' });
    setEducationEntries(values);
  };

  const handleAddUniversity = (index) => {
    const values = [...educationEntries];
    values[index].university.push({ university: '' });
    setEducationEntries(values);
  };

  const handleGpaChange = (index, newValue) => {
    const values = [...educationEntries];
    values[index].gpa = newValue;
    setEducationEntries(values);
  };

  const handleRemoveUniversity = (uniIndex, universityIndex) => {
    const values = [...educationEntries];
    values[uniIndex].university.splice(universityIndex, 1);
    setEducationEntries(values);
  };

  const handleRemoveMajor = (uniIndex, majorIndex) => {
    const values = [...educationEntries];
    values[uniIndex].majors.splice(majorIndex, 1);
    setEducationEntries(values);
  };

  const valuetext = (value) => `${value.toFixed(1)}`;

  return (
    <Page>
      <Title>What are your expectations?</Title>
      <Form>
        {isValidEducationEntries() && educationEntries.map((entry, index) => (
          <div key={index}>
            {index > 0 && <Divider />}
            <Verticaldiv>
              <InputLabel>University</InputLabel>
              {entry.university.map((university, universityIndex) => (
                <InputWrapper key={universityIndex}>
                 <Input
                  type="text"
                  name="university"
                  value={university.university}
                  onChange={event => handleUniversityChange(index, universityIndex, event)}
                  placeholder="Enter your preferred University"
                  required
                />
                {entry.university.length > 1 && (
                  <TrashWrapper onClick={() => handleRemoveUniversity(index, universityIndex)}>
                    <FaTrashAlt />
                  </TrashWrapper>
                )}
                </InputWrapper>
              ))}
            </Verticaldiv>
            <AddMoreButton type="button" onClick={() => handleAddUniversity(index)}>
              <PlusIcon />
              Add University
            </AddMoreButton>
            <Verticaldiv>
              <InputLabel>Majors</InputLabel>
              {entry.majors.map((major, majorIndex) => (
                <InputWrapper key={majorIndex}>
                  <Input
                    type="text"
                    name="major"
                    value={major.major}
                    onChange={event => handleMajorChange(index, majorIndex, event)}
                    placeholder="Enter Major"
                    required
                  />
                  {entry.majors.length > 1 && (
                    <TrashWrapper onClick={() => handleRemoveMajor(index, majorIndex)}>
                      <FaTrashAlt />
                    </TrashWrapper>
                  )}
                </InputWrapper>
              ))}
            </Verticaldiv>
            <AddMoreButton type="button" onClick={() => handleAddMajor(index)}>
                <PlusIcon />
                Add Major
              </AddMoreButton>
            <Verticaldiv>
              <InputLabel>GPA Range</InputLabel>
              <SliderWrapper>
              <Slider
                getAriaLabel={() => 'GPA range'}
                value={entry.gpa || [2.0, 3.5]}
                onChange={(event, newValue) => handleGpaChange(index, newValue)}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={0.1}
                max={4.0}
                step={0.1}
                style={{ color: '#003366' }}
              />
              </SliderWrapper>
            </Verticaldiv>
          </div>
        ))}
      </Form>
    </Page>
  );
};

export default Universities;
