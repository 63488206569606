import React from 'react';
import styled from 'styled-components';

// Container for the entire dashboard
const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #002b53;
  color: white;
  padding: 20px;
  justify-content: space-between;
`;

// Left column container
const LeftColumn = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Right column container
const RightColumn = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
`;

// Profile picture
const ProfilePicture = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 15px;
`;

// Student name and location
const StudentName = styled.h1`
  font-size: 24px;
  margin-bottom: 5px;
`;

const StudentLocation = styled.p`
  font-size: 16px;
  color: #aad4f0;
`;

// Tabs for donor, general information, amet
const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 15px;
`;

const TabButton = styled.button`
  background-color: #004878;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #006399;
  }
`;

// Funding and student information box
const InfoBox = styled.div`
  background-color: #3a556a;
  padding: 15px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 10px;
`;

const InfoTitle = styled.h3`
  font-size: 16px;
  color: #aad4f0;
  margin-bottom: 10px;
`;

const InfoContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

// Fund amount and progress
const FundAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const FundText = styled.p`
  font-size: 18px;
`;

const FundProgress = styled.div`
  font-size: 24px;
  color: white;
`;

// Middle section with project description and AI Evaluation
const MiddleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ProjectDescription = styled.div`
  width: 45%;
  background-color: #004878;
  padding: 15px;
  border-radius: 10px;
`;

const DescriptionTitle = styled.h2`
  font-size: 18px;
  color: #aad4f0;
  margin-bottom: 10px;
`;

const DescriptionText = styled.p`
  font-size: 16px;
`;

const AISection = styled(ProjectDescription)`
  width: 45%;
`;

const GPABox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #006399;
  padding: 20px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
`;

// Right column student impact section
const StudentImpactSection = styled.div`
  background-color: #3a556a;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const ImpactTitle = styled.h3`
  font-size: 18px;
  color: #aad4f0;
  margin-bottom: 15px;
`;

const Chart = styled.div`
  background-color: #005288;
  width: 100%;
  height: 150px;
  border-radius: 10px;
`;

const FundingSummary = styled.div`
  background-color: #3a556a;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const FundingAmount = styled.h4`
  font-size: 24px;
  color: #aad4f0;
`;

const FundingButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const FundingButton = styled.button`
  background-color: ${(props) => (props.continue ? '#006399' : '#cc3300')};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

// Main component
const StudentProgress = () => {
  return (
    <Container>
      {/* Left Column */}
      <LeftColumn>
        <ProfilePicture src="path_to_profile_picture" alt="Student" />
        <StudentName>Student Name</StudentName>
        <StudentLocation>Austin, TX</StudentLocation>

        <Tabs>
          <TabButton>Donor</TabButton>
          <TabButton>General Information</TabButton>
          <TabButton>Amet</TabButton>
        </Tabs>

        <InfoBox>
          <InfoTitle>Amount</InfoTitle>
          <InfoContent>$20,000</InfoContent>
          <FundAmount>
            <FundText>Tuition Fee</FundText>
            <FundProgress>$6,000</FundProgress>
          </FundAmount>
          <FundAmount>
            <FundText>Housing</FundText>
            <FundProgress>$14,000</FundProgress>
          </FundAmount>
        </InfoBox>
      </LeftColumn>

      {/* Right Column */}
      <RightColumn>
        <MiddleSection>
          <ProjectDescription>
            <DescriptionTitle>Project description</DescriptionTitle>
            <DescriptionText>
              The Monarch Butterfly Migration Project tracks the annual journey of monarchs from North America to Mexico, studying their patterns, habitats, and challenges.
            </DescriptionText>
          </ProjectDescription>

          <AISection>
            <DescriptionTitle>AI Evaluation</DescriptionTitle>
            <DescriptionText>
              Exhibits strong leadership and collaboration skills, consistently delivering high-quality work; would highly recommend funding further projects due to their proven ability to create impactful, mission-driven initiatives.
            </DescriptionText>
            <GPABox>3.7 GPA</GPABox>
          </AISection>
        </MiddleSection>

        <StudentImpactSection>
          <ImpactTitle>Student Impact Over Time</ImpactTitle>
          <Chart></Chart>
        </StudentImpactSection>

        <FundingSummary>
          <FundingAmount>Total funds given to student: $20,000.00</FundingAmount>
          <FundingAmount>Student request for next semester: $6,000.00</FundingAmount>

          <FundingButtons>
            <FundingButton>Stop funding</FundingButton>
            <FundingButton continue>Continue funding</FundingButton>
          </FundingButtons>
        </FundingSummary>
      </RightColumn>
    </Container>
  );
};

export default StudentProgress;
