import React, { useState } from 'react';
import styled from 'styled-components';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import { FaAngleDown, FaAngleUp, FaTrash } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import ExperienceComponent from '../component/profile/ExperienceComponent';
import EducationComponent from '../component/profile/EducationComponent';
import InterestsComponent from '../component/profile/InterestsComponent';
import LoansComponent from '../component/profile/LoansComponent';
import ProjectsComponent from '../component/profile/ProjectsComponent';
import UniversitiesComponent from '../component/profile/donor/UniversitiesComponent';
import LoanDonorComponent from '../component/profile/donor/LoanDonorComponent';
import DeckOverlay from '../component/DeckOverlay';

const ProfileContainer = styled.div`
  padding: 20px;
  margin: 20px;
  width: 90%;
  max-width: 500px;
  font-family: 'Arial', sans-serif;
  text-align: center;
  color: black;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
  max-height: 80vh;
  padding-bottom: 40px;
`;

const UpdateButtonContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

const InputLabelViewProfil = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const UpdateButton = styled.button`
  padding: 12px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;

  &:hover {
    background-color: #002244;
  }

  &:focus {
    outline: none;
  }
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.2em;
`;

// New styling for file input and custom label
const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  padding: 10px 15px;
  background-color: #003366;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;

  &:hover {
    background-color: #002244;
  }

  &:focus {
    outline: none;
  }
`;

const WrapperProfilePictureViewProfile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const WrapperProfilePictureViewProfileInner = styled.div`
  display: flex;
  flex-direction: column;
`;



// New styling for the small image preview and trash icon container
const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const SmallImagePreview = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

const TrashIcon = styled(FaTrash)`
  color: #ff0000;
  cursor: pointer;
  &:hover {
    color: #cc0000;
  }
`;
const Profile = ({ userData, setUserData }) => {
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [name, setName] = useState(userData.name);
  const [email, setEmail] = useState(userData.email);
  const [experience, setExperience] = useState(userData.experienceData || []);
  const [education, setEducation] = useState(userData.educationData || []);
  const [interests, setInterests] = useState(userData.interests || []);
  const [loans, setLoans] = useState(userData.loanData || []);
  const [projects, setProjects] = useState(userData.projectData || []);
  const [showExperience, setShowExperience] = useState(false);
  const [showEducation, setShowEducation] = useState(false);
  const [showInterests, setShowInterests] = useState(false);
  const [showLoans, setShowLoans] = useState(false);
  const [showUniversities, setShowUniversities] = useState(false);
  const [showDonorsLoan, setShowDonorsLoan] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const currentUserUid = auth.currentUser ? auth.currentUser.uid : 'anonymous';
   // State to control the DeckOverlay visibility and selected user
   const [showDeckOverlay, setShowDeckOverlay] = useState(false);
   const [selectedUser, setSelectedUser] = useState(null);
 
   // Function to handle the "View Profile" click
   const handleViewProfile = () => {
     setSelectedUser(userData); // Set the current user's data
     console.log("test", showDeckOverlay, selectedUser)
     setShowDeckOverlay(true); // Show the DeckOverlay
   };

  const handleProfilePictureChange = (event) => {
    if (event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setNewProfilePicture(selectedFile);

      const newProfilePictureUrl = URL.createObjectURL(selectedFile);
      setUserData({
        ...userData,
        profilePictureURL: newProfilePictureUrl,
      });

      toast.success('Profile picture applied. Click Update Profile to Save');
    }
  };

  const handleRemoveProfilePicture = () => {
    setNewProfilePicture(null);
    setUserData({
      ...userData,
      profilePictureURL: userData.profilePictureURL,
    });
    toast.info('Profile picture removed');
  };

  const handleUpdateProfile = async () => {
    let newProfilePictureUrl = userData.profilePictureURL;

    if (newProfilePicture) {
      const storage = getStorage();
      const storageRef = ref(storage, `profilePictures/${currentUserUid}`);
      await uploadBytes(storageRef, newProfilePicture);
      newProfilePictureUrl = await getDownloadURL(storageRef);
    }

    try {
      await setDoc(doc(db, 'users', currentUserUid), {
        ...userData,
        name,
        email,
        profilePictureURL: newProfilePictureUrl,
        experience,
        education,
        interests,
        loans,
        projects
      }, { merge: true });
      setUserData({
        ...userData,
        name,
        email,
        profilePictureURL: newProfilePictureUrl,
        experience,
        education,
        interests,
        loans,
        projects
      });
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error updating profile');
    }
  };

  return (
    <ProfileContainer>
      <ToastContainer />
      <ProfileImage src={userData.profilePictureURL || 'https://via.placeholder.com/150'} alt="Profile Picture" />
      <InputLabel>
        Name
        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </InputLabel>
      <InputLabel>
        Email
        <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      </InputLabel>
      <WrapperProfilePictureViewProfile>
          <InputLabel>
            Change Profile Picture
            <FileInputLabel htmlFor="profile-picture">Choose a picture</FileInputLabel>
            <FileInput id="profile-picture" type="file" accept="image/*" onChange={handleProfilePictureChange} />
          </InputLabel>
          <InputLabelViewProfil>
          <WrapperProfilePictureViewProfileInner>
          View Profile
          <FileInputLabel onClick={handleViewProfile}>View Profile</FileInputLabel> {/* View Profile button */}
      
          {/* Other Profile form fields and toggles */}
          
          {showDeckOverlay && selectedUser && (
            <DeckOverlay
              overlayProps={{ y: { to: () => '0%' } }} // Placeholder for overlay spring animation
              closeOverlay={() => setShowDeckOverlay(false)} // Function to close the DeckOverlay
              selectedUser={selectedUser}
              setShowResume={() => {}} // If you have resume logic, pass it here
            />)}
          </WrapperProfilePictureViewProfileInner>
        </InputLabelViewProfil>
      </WrapperProfilePictureViewProfile>
     

      {newProfilePicture && (
        <ImagePreviewContainer>
          <SmallImagePreview
            src={URL.createObjectURL(newProfilePicture)}
            alt="New Profile Preview"
          />
          <TrashIcon onClick={handleRemoveProfilePicture} />
        </ImagePreviewContainer>
      )}

      {userData.isDonor === 0 && (
        <>
          <ToggleButton onClick={() => setShowExperience(!showExperience)}>
            <span>Experience</span>
            {showExperience ? <FaAngleUp /> : <FaAngleDown />}
          </ToggleButton>
          {showExperience && <ExperienceComponent experience={experience} setExperience={setExperience} />}

          <ToggleButton onClick={() => setShowEducation(!showEducation)}>
            <span>Education</span>
            {showEducation ? <FaAngleUp /> : <FaAngleDown />}
          </ToggleButton>
          {showEducation && <EducationComponent education={education} setEducation={setEducation} />}

              <ToggleButton onClick={() => setShowLoans(!showLoans)}>
            <span>Loans</span>
            {showLoans ? <FaAngleUp /> : <FaAngleDown />}
          </ToggleButton>
          {showLoans && <LoansComponent loans={loans} setLoans={setLoans} />}

          <ToggleButton onClick={() => setShowProjects(!showProjects)}>
            <span>Accomplishments</span>
            {showProjects ? <FaAngleUp /> : <FaAngleDown />}
          </ToggleButton>
          {showProjects && <ProjectsComponent projects={projects} setProjects={setProjects} />}
        </>
      )}

      {userData.isDonor === 1 && (
        <>
          <ToggleButton onClick={() => setShowUniversities(!showUniversities)}>
            <span>Universities Preferences</span>
            {showUniversities ? <FaAngleUp /> : <FaAngleDown />}
          </ToggleButton>
          {showUniversities && <UniversitiesComponent universities={education} setUniversities={setEducation} />}

          <ToggleButton onClick={() => setShowDonorsLoan(!showDonorsLoan)}>
            <span>Loans Preferences</span>
            {showDonorsLoan ? <FaAngleUp /> : <FaAngleDown />}
          </ToggleButton>
          {showDonorsLoan && <LoanDonorComponent loanEntries={loans} setLoanEntries={setLoans} />}
        </>
      )}

      {/* Shared between both types of users */}
      {/* <ToggleButton onClick={() => setShowInterests(!showInterests)}>
        <span>Interests</span>
        {showInterests ? <FaAngleUp /> : <FaAngleDown />}
      </ToggleButton>
      {showInterests && <InterestsComponent interests={interests} setInterests={setInterests} />} */}

   
      <UpdateButtonContainer>
        <UpdateButton onClick={handleUpdateProfile}>Update Profile</UpdateButton>
      </UpdateButtonContainer>
    </ProfileContainer>
  );
};

export default Profile;