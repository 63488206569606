import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

const SectionContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SectionItem = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const AddItemButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #577483;
  }

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled(FaTrash)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #577483;

  &:hover {
    color: #003366;
  }
`;

const InterestsComponent = ({ interests, setInterests }) => {
  const [newInterest, setNewInterest] = useState('');

  const handleAddItem = () => {
    if (newInterest) {
      setInterests((prev) => [...prev, newInterest]);
      setNewInterest('');
    }
  };

  const handleDeleteItem = (index) => {
    setInterests((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <SectionContainer>
      {interests.map((interest, index) => (
        <SectionItem key={index}>
          <DeleteButton onClick={() => handleDeleteItem(index)} />
          <span>{interest}</span>
        </SectionItem>
      ))}
      <InputLabel>
        Add Interest
        <Input type="text" value={newInterest} onChange={(e) => setNewInterest(e.target.value)} />
      </InputLabel>
      <AddItemButton onClick={handleAddItem}>Add Interest</AddItemButton>
    </SectionContainer>
  );
};

export default InterestsComponent;
