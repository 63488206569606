import React, { useState, useEffect } from 'react';
import { useSprings, animated, to as interpolate, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import styled from 'styled-components';
import Resume from './Resume'; // Adjust the path accordingly
import { motion } from 'framer-motion';
import './Deck.css';
import { db, auth } from '../firebase'; // Ensure 'auth' is imported from your firebase configuration
import { collection, getDocs, query, limit, doc, updateDoc, arrayUnion, getDoc, where } from 'firebase/firestore';
import { FaCheck, FaTimes, FaLinkedin, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { LuShare } from "react-icons/lu";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeckOverlay from '../component/DeckOverlay';

const to = (i) => ({
  x: 20,
  y: i * -4,
  scale: 1.7,
  rot: 0,
  delay: i * 100,
});
const from = () => ({ x: 0, rot: 0, scale: 1.7, y: -1000 });
const trans = (r, s) => `perspective(1500px) rotateX(0deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

const Overlay = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: #003366;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 999;
  top: 10px;
  right: 10px;
  background: #003366;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: '×';
    font-size: 24px;
    color: white;
  }
`;

const ProfileImage = styled.img`
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: white;
  margin: 10px 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 600px;
  min-width: 600px;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
    min-width: 0;
    box-shadow: none;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 5px 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;

const NameImg = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 50px;
  transform: scale(1.5);
`;

const ProjectInformation = styled.div`
  display: table-column;
`;

const InfoLabel = styled.span`
  font-weight: bold;
  color: white;
`;

const InfoValue = styled.span`
  color: white;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const ButtonContainerProject = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #0055a5;
  color: white;
  border: 1px solid #ddd;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  width: calc(33% - 20px); /* Adjust width to fit 3 buttons in a row */
  max-width: 200px; /* Limit button width */
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px); /* Full width for small screens */
  }
`;

const ButtonProject = styled.button`
  background-color: #0055a5;
  color: white;
  border: 1px solid #ddd;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  width: calc(50% - 20px); /* Adjust width to fit 3 buttons in a row */
  max-width: 200px; /* Limit button width */
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
  }
`;

const ButtonDeck = styled.button`
  background-color: #577483;
  color: white;
  border: 1px solid #ddd;
  width:43%;
  margin: 5px;
  border-radius: 15px;
  outline: none;
  max-width: 200px; /* Limit button width */
  text-align: center;
  font-size: xx-small;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    // width: calc(100%); /* Full width for small screens */
  }
`;

const ProjectContainer = styled.div`
  width: 90%;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the left */
`;


const ProjectContainer2 = styled.div`
  width: 90%;
  margin-top: 0px;
  padding-right: 10px;
  padding-left: 10px
  padding-top: 0px;
  padding-bottom: 0px;
  // background-color: rgba(255, 255, 255, 0.1);
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProjectRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
`;

const ProjectImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ProjectImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 8px;
`;

const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeckHeadline = styled.div`
  font-size: 1.3em;
  color: white;
  margin-bottom: 5px;
`;

const RedXIcon = styled.div`
  color: red;
`;

const GreenCheckmark = styled.div`
  color: green;
`;

const DeckSubHeadline = styled.div`
  font-size: 0.9em;
  color: white;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`;

const LoanAmount = styled.span`
  text-align: right;
`;

const ProjectDescription = styled.p`
  color: white;
  margin: 10px 0;
`;

const ProjectScore = styled.p`
  color: black;
  text-align: left;
  font-weight: bold;
`;

const IconContainer = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const Deck = ({ userProfilePictureURL, isDonor, userData }) => {
  const [gone] = useState(() => new Set());
  const [users, setUsers] = useState([]);
  const [scholarshipData, setScholarshipData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedScholarship, setSelectedScholarship] = useState(null);
  const [overlayProps, overlayApi] = useSpring(() => ({ y: '100%' }));
  const [currentMx, setCurrentMx] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [showResume, setShowResume] = useState(false);


  const dataLength = isDonor === 1 ? users.length : scholarshipData.length;

  const [props, api] = useSprings(dataLength, (i) => ({
    ...to(i),
    from: from(i),
  }));

  useEffect(() => {
    if (isDonor === 1) {
      const fetchUsers = async () => {
        const usersCollection = collection(db, 'users');
        const usersQuery = query(usersCollection, where('sign_up_step_completed', '==', 4));
        const usersSnapshot = await getDocs(usersQuery);
        const usersList = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);

        // Update springs for users
        api.start((i) => to(i));
      };

      fetchUsers();
    } else {
      const fetchScholarshipData = async () => {
        const scholarshipsCollection = collection(db, 'scholarships');
        const scholarshipsSnapshot = await getDocs(scholarshipsCollection);
        const scholarshipList = scholarshipsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setScholarshipData(scholarshipList);

        // Update springs for scholarships
        api.start((i) => to(i));
      };

      fetchScholarshipData();
    }
  }, [isDonor, api]);

  useEffect(() => {
    // Update springs when the length of the relevant data changes
    api.start((i) => to(i));
  }, [dataLength, api]);

  const handleSwipe = async (index, direction) => {
    const currentUserUid = auth.currentUser ? auth.currentUser.uid : 'anonymous';
    if (direction === 'right' && currentUserUid !== 'anonymous' && (isDonor === 1)) {
      const userRef = doc(db, 'users', currentUserUid);
      const swipedUserRef = doc(db, 'users', users[index].id);

      await updateDoc(userRef, {
        rightSwipes: arrayUnion(users[index].id),
      });

      await updateDoc(swipedUserRef, {
        rightSwipes: arrayUnion(currentUserUid),
      });

      const swipedUserSnap = await getDoc(swipedUserRef);
      if (swipedUserSnap.exists()) {
        const swipedUserData = swipedUserSnap.data();
        if (swipedUserData.rightSwipes && swipedUserData.rightSwipes.includes(currentUserUid) && isDonor === 1) {
          await updateDoc(userRef, {
            matches: arrayUnion(users[index].id),
          });
          await updateDoc(swipedUserRef, {
            matches: arrayUnion(currentUserUid),
          });
        }
      }
    }
  };

  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    setCurrentMx(mx); 
    const triggerVelocity = velocity > 0.2;
    const isSmallScreen = window.innerWidth <= 768; // Consider 768px and below as a smartphone
    const outburst = isSmallScreen ? 100 : 300;
    const triggerPosition = Math.abs(mx) > outburst;
    const dir = xDir < 0 ? -1 : 1;

    if (triggerVelocity || triggerPosition) {
      gone.add(index);

      if (dir > 0) {
        setSwipeDirection('right');
        handleSwipe(index, 'right'); 
      } else {
        setSwipeDirection('left');
      }

      setTimeout(() => {
        setSwipeDirection(null);
      }, 500);

      api.start((i) => {
        if (index !== i) return;
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0;
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0);
        const scale = down ? 1.4 : 1.8;
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
    } else {
      setSwipeDirection(null);

      api.start((i) => {
        if (index !== i) return;
        const isGone = gone.has(index);
        const x = down ? mx : 0;
        const rot = mx / 100;
        const scale = down ? 1.4 : 1.8;
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
    }

    if (!down && gone.size === users.length) {
      setTimeout(() => {
        gone.clear();
        api.start((i) => to(i));
      }, 600);
    }
  });

  const handleClick = (index) => {
    if (currentMx > -70 && currentMx < 70) {
      if (isDonor != 1) {
        setSelectedScholarship(scholarshipData[index]);
      } else {
        setSelectedUser(users[index]);
      }
      overlayApi.start({ y: '0%' });
    }
  };
  
  const closeOverlay = () => {
    console.log("close data ")
    overlayApi.start({ y: '100%' });
    setSelectedUser(null);
    setSelectedScholarship(null);
  };
  
  const [currentProjectImage, setCurrentProjectImage] = useState({});

  const handlePreviousImage = (projectIndex) => {
    setCurrentProjectImage((prev) => {
      const currentImageIndex = prev[projectIndex] || 0;
      const newIndex = currentImageIndex > 0 ? currentImageIndex - 1 : 0;

      if (currentImageIndex === 0) {
        toast.info('There are no more pictures', {
          position: 'top-center',
        });
      }

      return { ...prev, [projectIndex]: newIndex };
    });
  };

  const handleNextImage = (projectIndex, projectPictures) => {
    setCurrentProjectImage((prev) => {
      const currentImageIndex = prev[projectIndex] || 0;
      const newIndex =
        currentImageIndex < projectPictures.length - 1
          ? currentImageIndex + 1
          : projectPictures.length - 1;

      if (currentImageIndex === projectPictures.length - 1) {
        toast.info('There are no more pictures', {
          position: 'top-center',
        });
      }

      return { ...prev, [projectIndex]: newIndex };
    });
  };

  return (
    <>
      <ToastContainer />
      {isDonor != 1 ? (
        scholarshipData.map((scholarship, i) => (
         
          <animated.div
            className="deck"
            key={i}
            style={{
              transform: interpolate([props[i]?.x, props[i]?.y], (x, y) => `translate3d(${x}px,${y}px,0)`),
              marginBottom: '90px',
            }}
          >
            <animated.div
              {...bind(i)}
              onClick={() => handleClick(i)}
              style={{
                transform: interpolate([props[i]?.rot, props[i]?.scale], trans),
              }}
            >
               {swipeDirection === 'left' && (
                <IconContainer
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <RedXIcon>
                    <FaTimes />
                  </RedXIcon>
                </IconContainer>
              )}
              {swipeDirection === 'right' && (
                <IconContainer
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <GreenCheckmark>
                    <FaCheck />
                  </GreenCheckmark>
                </IconContainer>
              )}

              <div
                style={{
                  backgroundImage: `url(${scholarship?.scholarshipImage || 'https://firebasestorage.googleapis.com/v0/b/studentevaluation-9d972.appspot.com/o/profilePictures%2FBeaverProfilePicture.png?alt=media&token=99139914-31c6-41f3-9419-fd9b858a376e'})`,
                  backgroundSize: 'cover',
                  height: '50%',
                  width: '100%',
                }}
              />
              <div
                style={{
                  backgroundColor: '#003366',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                <DeckHeadline>{scholarship.scholarshipName}</DeckHeadline>
                <DeckSubHeadline>Amount: {scholarship.amount}$</DeckSubHeadline>
                <DeckSubHeadline>
                Deadline: {new Date(scholarship.deadline).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                }).replace(/\//g, '.')}
              </DeckSubHeadline>

                <div
                  style={{
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '10px',
                    marginTop: '10px',
                  }}
                >
                  {scholarship.categories?.slice(0, 3).map((category, index) => (
                    <ButtonDeck key={index}>{category}</ButtonDeck>
                  ))}
                </div>
              </div>
            </animated.div>
          </animated.div>
        ))
      ) : (
        props.map(({ x, y, rot, scale }, i) => (
          <animated.div
            className="deck"
            key={i}
            style={{
              transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`),
              
            }}
          >
            <animated.div
              {...bind(i)}
              onClick={() => handleClick(i)}
              style={{
                transform: interpolate([rot, scale], trans),
              }}
            >
              {swipeDirection === 'left' && (
                <IconContainer
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <RedXIcon>
                    <FaTimes />
                  </RedXIcon>
                </IconContainer>
              )}
              {swipeDirection === 'right' && (
                <IconContainer
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <GreenCheckmark>
                    <FaCheck />
                  </GreenCheckmark>
                </IconContainer>
              )}

              <div
                style={{
                  backgroundImage: `url(${users[i]?.profilePictureURL || 'https://firebasestorage.googleapis.com/v0/b/studentevaluation-9d972.appspot.com/o/profilePictures%2FBeaverProfilePicture.png?alt=media&token=99139914-31c6-41f3-9419-fd9b858a376e'})`,
                  backgroundSize: 'cover',
                  height: '50%',
                  width: '100%',
                }}
              />
              <div
                style={{
                  height: '50%',
                  width: '100%',
                  backgroundColor: '#003366',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                <DeckHeadline>{users[i]?.name}</DeckHeadline>
                {/* {users[i]?.educationData?.[0]?.university && (
                  <DeckSubHeadline>{users[i]?.educationData[0].university}</DeckSubHeadline>
                )} */}
                {users[i]?.projectData[0]?.name && (
                  <DeckSubHeadline style={{ textAlign: 'left'}}> 
                 <span>- {users[i]?.projectData[0]?.name}</span>
               </DeckSubHeadline>
                )}
                {/* {users[i]?.educationData[0]?.university && (
                 <DeckSubHeadline>
                 <span>{users[i]?.educationData[0]?.university}</span>
               </DeckSubHeadline>
                )} */}
                {users[i]?.loanData?.student_total_loan_amount && (
                 <DeckSubHeadline>
                 <span>Loan:</span>
                 <LoanAmount>${users[i]?.loanData?.student_total_loan_amount} USD</LoanAmount>
               </DeckSubHeadline>
                )}
                 
                <div
                  style={{
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '10px',
                    marginTop: '10px',
                  }}
                >
                  {users[i]?.interests?.slice(0, 3).map((interest, index) => (
                    <ButtonDeck key={index}>{interest}</ButtonDeck>
                  ))}
                </div>
              </div>
            </animated.div>
          </animated.div>
        ))
      )}
 
{(selectedUser || selectedScholarship) && (
    <DeckOverlay
      overlayProps={overlayProps}
      closeOverlay={closeOverlay}
      selectedUser={selectedUser}
      selectedScholarship={selectedScholarship}
      setShowResume={setShowResume}
    />
)}

{showResume && (
  <Resume user={selectedUser} closeResume={() => setShowResume(false)} />
)}
    </>
  );
}

export default Deck;