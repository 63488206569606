import * as React from "react"
const FundingSuccessLogoSVGNoAnimation = (props) => (
  <svg
    width="100%" // Ensure explicit size
    height="auto"
    viewBox="0 0 390 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="FxSLogo 1">
      <g id="funding">
        <path
          id="Vector"
          d="M19.5 22.02V45.4H16.48V22.02H12.26V19.34H16.48V16.97C16.48 15.86 16.56 14.83 16.72 13.89C16.88 12.95 17.18 12.14 17.63 11.47C18.08 10.8 18.69 10.28 19.48 9.90998C20.26 9.53998 21.26 9.34998 22.48 9.34998C22.93 9.34998 23.34 9.36997 23.73 9.39997C24.11 9.42997 24.55 9.47998 25.03 9.54998V12.28C24.61 12.21 24.23 12.15 23.88 12.1C23.53 12.05 23.18 12.02 22.82 12.02C21.99 12.02 21.35 12.15 20.9 12.42C20.45 12.69 20.12 13.05 19.92 13.51C19.72 13.97 19.59 14.49 19.56 15.1C19.53 15.71 19.51 16.36 19.51 17.07V19.34H24.4V22.02H19.51H19.5Z"
          fill="#003366"
        />
        <path
          id="Vector_2"
          d="M44.2601 45.39V40.69H44.1601C43.3601 42.44 42.2001 43.78 40.6801 44.7C39.1601 45.63 37.4701 46.09 35.6201 46.09C34.0501 46.09 32.7201 45.86 31.6201 45.41C30.5201 44.96 29.6201 44.31 28.9301 43.47C28.2401 42.63 27.7401 41.6 27.4201 40.39C27.1001 39.18 26.9401 37.8 26.9401 36.25V19.34H29.9601V36.3C30.0201 38.66 30.5401 40.44 31.5201 41.65C32.5001 42.86 34.1801 43.47 36.5801 43.47C37.8901 43.47 39.0101 43.18 39.9401 42.59C40.8701 42 41.6301 41.22 42.2401 40.24C42.8501 39.26 43.3001 38.15 43.5801 36.88C43.8701 35.62 44.0101 34.33 44.0101 33.02V19.34H47.0301V45.39H44.2501H44.2601Z"
          fill="#003366"
        />
        <path
          id="Vector_3"
          d="M55.18 19.34V23.83H55.28C55.86 22.25 56.88 20.98 58.35 20.02C59.82 19.06 61.43 18.58 63.19 18.58C64.95 18.58 66.36 18.82 67.53 19.29C68.7 19.76 69.63 20.43 70.34 21.28C71.04 22.14 71.54 23.19 71.83 24.44C72.12 25.69 72.26 27.08 72.26 28.63V45.39H69.24V29.13C69.24 28.02 69.14 26.98 68.95 26.03C68.76 25.07 68.42 24.24 67.94 23.53C67.46 22.82 66.81 22.27 66 21.86C65.19 21.45 64.17 21.25 62.95 21.25C61.73 21.25 60.66 21.48 59.71 21.93C58.77 22.39 57.97 23.01 57.31 23.8C56.65 24.59 56.14 25.53 55.77 26.63C55.4 27.72 55.2 28.91 55.17 30.19V45.39H52.15V19.34H55.17H55.18Z"
          fill="#003366"
        />
        <path
          id="Vector_4"
          d="M96.3701 45.39V40.44H96.2701C95.9501 41.28 95.4801 42.06 94.8501 42.76C94.2201 43.46 93.5101 44.06 92.7201 44.55C91.9201 45.04 91.0701 45.42 90.1501 45.69C89.2301 45.96 88.3401 46.09 87.4401 46.09C85.5501 46.09 83.9101 45.73 82.5201 45.01C81.1301 44.29 79.9701 43.29 79.0401 42.03C78.1101 40.77 77.4201 39.3 76.9801 37.64C76.5301 35.97 76.3101 34.22 76.3101 32.36C76.3101 30.5 76.5301 28.75 76.9801 27.08C77.4301 25.41 78.1101 23.95 79.0401 22.69C79.9701 21.43 81.1301 20.43 82.5201 19.69C83.9101 18.95 85.5501 18.58 87.4401 18.58C88.3701 18.58 89.2701 18.7 90.1501 18.93C91.0301 19.17 91.8501 19.53 92.6201 20.02C93.3901 20.51 94.0701 21.1 94.6601 21.81C95.2501 22.52 95.7101 23.34 96.0301 24.28H96.1301V9.33997H99.1501V45.39H96.3701ZM79.8001 36.38C80.1001 37.71 80.5801 38.9 81.2401 39.94C81.8901 40.98 82.7301 41.83 83.7601 42.49C84.7801 43.15 86.0101 43.47 87.4501 43.47C89.0501 43.47 90.4001 43.14 91.5001 42.49C92.6001 41.84 93.5001 40.98 94.1901 39.94C94.8801 38.9 95.3701 37.71 95.6801 36.38C95.9801 35.05 96.1401 33.71 96.1401 32.37C96.1401 31.03 95.9901 29.69 95.6801 28.36C95.3801 27.03 94.8801 25.84 94.1901 24.8C93.5001 23.76 92.6101 22.91 91.5001 22.25C90.3901 21.59 89.0501 21.27 87.4501 21.27C86.0101 21.27 84.7801 21.6 83.7601 22.25C82.7401 22.91 81.9001 23.76 81.2401 24.8C80.5801 25.84 80.1001 27.03 79.8001 28.36C79.5001 29.69 79.3401 31.03 79.3401 32.37C79.3401 33.71 79.4901 35.05 79.8001 36.38Z"
          fill="#003366"
        />
        <path
          id="Vector_5"
          d="M107.35 9.34998V14.45H104.33V9.34998H107.35ZM107.35 19.34V45.39H104.33V19.34H107.35Z"
          fill="#003366"
        />
        <path
          id="Vector_6"
          d="M115.54 19.34V23.83H115.64C116.22 22.25 117.24 20.98 118.71 20.02C120.18 19.06 121.79 18.58 123.55 18.58C125.31 18.58 126.72 18.82 127.89 19.29C129.06 19.76 129.99 20.43 130.7 21.28C131.4 22.14 131.9 23.19 132.19 24.44C132.48 25.69 132.62 27.08 132.62 28.63V45.39H129.6V29.13C129.6 28.02 129.5 26.98 129.31 26.03C129.12 25.08 128.78 24.24 128.3 23.53C127.82 22.82 127.17 22.27 126.36 21.86C125.55 21.45 124.53 21.25 123.31 21.25C122.09 21.25 121.02 21.48 120.07 21.93C119.13 22.39 118.33 23.01 117.67 23.8C117.01 24.59 116.5 25.53 116.13 26.63C115.76 27.72 115.56 28.91 115.53 30.19V45.39H112.51V19.34H115.53H115.54Z"
          fill="#003366"
        />
        <path
          id="Vector_7"
          d="M158.08 48.47C157.68 50.02 157.05 51.33 156.19 52.41C155.33 53.49 154.2 54.31 152.81 54.88C151.42 55.45 149.72 55.74 147.7 55.74C146.45 55.74 145.24 55.59 144.06 55.29C142.88 54.99 141.81 54.52 140.87 53.88C139.93 53.24 139.14 52.42 138.52 51.43C137.9 50.44 137.54 49.25 137.44 47.87H140.46C140.62 48.85 140.93 49.66 141.4 50.32C141.86 50.98 142.42 51.51 143.08 51.91C143.73 52.31 144.46 52.61 145.26 52.79C146.06 52.97 146.87 53.07 147.71 53.07C150.52 53.07 152.55 52.23 153.8 50.55C155.05 48.87 155.67 46.44 155.67 43.28V39.75H155.57C154.87 41.37 153.83 42.66 152.48 43.64C151.12 44.62 149.53 45.1 147.71 45.1C145.73 45.1 144.03 44.76 142.63 44.06C141.22 43.37 140.06 42.42 139.15 41.21C138.24 40 137.57 38.58 137.16 36.94C136.74 35.31 136.54 33.57 136.54 31.71C136.54 29.85 136.79 28.24 137.28 26.64C137.78 25.04 138.49 23.64 139.44 22.45C140.38 21.26 141.55 20.31 142.94 19.62C144.33 18.93 145.92 18.59 147.71 18.59C148.64 18.59 149.51 18.72 150.32 18.99C151.14 19.26 151.88 19.64 152.55 20.13C153.22 20.62 153.82 21.18 154.35 21.82C154.88 22.46 155.29 23.13 155.57 23.84H155.67V19.35H158.69V43.28C158.69 45.2 158.49 46.93 158.09 48.48L158.08 48.47ZM151.15 41.53C152.14 40.94 152.97 40.17 153.64 39.21C154.31 38.25 154.82 37.15 155.15 35.9C155.49 34.65 155.65 33.38 155.65 32.06C155.65 30.74 155.51 29.5 155.22 28.22C154.93 26.94 154.47 25.78 153.83 24.74C153.19 23.7 152.37 22.86 151.36 22.22C150.35 21.58 149.13 21.26 147.69 21.26C146.25 21.26 145.02 21.57 144 22.19C142.98 22.81 142.13 23.63 141.46 24.64C140.79 25.65 140.3 26.8 140 28.1C139.7 29.4 139.54 30.72 139.54 32.06C139.54 33.4 139.7 34.65 140.02 35.9C140.34 37.15 140.84 38.25 141.51 39.21C142.18 40.17 143.03 40.94 144.05 41.53C145.07 42.12 146.29 42.41 147.69 42.41C149 42.41 150.15 42.12 151.14 41.53H151.15Z"
          fill="#003366"
        />
      </g>
      <g id="Success">
        <path
          id="Vector_8"
          d="M207.49 18.2001C207.49 17.1201 207.27 16.2101 206.83 15.4501C206.39 14.6901 205.72 14.3101 204.81 14.3101C203.8 14.3101 203.06 14.6401 202.59 15.2901C202.12 15.9501 201.88 16.6801 201.88 17.4901C201.88 18.6701 202.26 19.6001 203.02 20.2901C203.78 20.9801 204.73 21.5801 205.87 22.0801C207.01 22.5801 208.24 23.1001 209.56 23.6201C210.87 24.1401 212.1 24.8201 213.25 25.6601C214.39 26.5001 215.35 27.6001 216.1 28.9701C216.86 30.3301 217.24 32.1101 217.24 34.3001C217.24 38.1401 216.15 40.9201 213.96 42.6601C211.77 44.3901 208.61 45.2601 204.47 45.2601C202.52 45.2601 200.79 45.1001 199.3 44.7801C197.8 44.4601 196.54 43.9001 195.51 43.1101C194.48 42.3201 193.7 41.2801 193.16 40.0101C192.62 38.7301 192.35 37.1501 192.35 35.2601V34.0001H201.44V34.8601C201.44 36.4101 201.73 37.4901 202.3 38.1201C202.87 38.7401 203.61 39.0501 204.52 39.0501C205.5 39.0501 206.24 38.7101 206.77 38.0401C207.29 37.3701 207.55 36.5401 207.55 35.5701C207.55 34.3901 207.19 33.4601 206.47 32.7701C205.75 32.0801 204.84 31.4901 203.74 31.0001C202.65 30.5101 201.46 30.0201 200.18 29.5101C198.9 29.0101 197.71 28.3501 196.62 27.5401C195.53 26.7301 194.62 25.6701 193.89 24.3601C193.17 23.0501 192.81 21.3301 192.81 19.2101C192.81 15.5401 193.79 12.7501 195.74 10.8301C197.69 8.91007 200.65 7.95007 204.63 7.95007C208.61 7.95007 211.71 8.83007 213.54 10.6001C215.37 12.3701 216.29 15.1901 216.29 19.0601H207.51V18.2001H207.49Z"
          fill="#003366"
        />
        <path
          id="Vector_9"
          d="M229.56 34.66C229.56 36.41 229.81 37.58 230.32 38.17C230.83 38.76 231.51 39.05 232.39 39.05C233.27 39.05 233.96 38.76 234.46 38.17C234.96 37.58 235.22 36.41 235.22 34.66V8.66003H244.61V32.29C244.61 34.81 244.34 36.92 243.8 38.6C243.26 40.28 242.48 41.63 241.45 42.64C240.42 43.65 239.15 44.34 237.64 44.71C236.13 45.08 234.37 45.26 232.39 45.26C230.41 45.26 228.7 45.08 227.16 44.71C225.62 44.34 224.35 43.65 223.32 42.64C222.29 41.63 221.51 40.28 220.97 38.6C220.43 36.92 220.16 34.81 220.16 32.29V8.66003H229.55V34.66H229.56Z"
          fill="#003366"
        />
        <path
          id="Vector_10"
          d="M262.42 15.4201C262.02 14.6801 261.36 14.3101 260.45 14.3101C259.74 14.3101 259.18 14.4901 258.76 14.8601C258.34 15.2301 258.02 15.8901 257.8 16.8301C257.58 17.7701 257.44 19.0401 257.37 20.6401C257.3 22.2401 257.27 24.2501 257.27 26.6701C257.27 29.0901 257.3 31.1001 257.37 32.7001C257.44 34.3001 257.58 35.5701 257.8 36.5101C258.02 37.4501 258.34 38.1101 258.76 38.4801C259.18 38.8501 259.74 39.0401 260.45 39.0401C261.02 39.0401 261.49 38.9001 261.86 38.6101C262.23 38.3201 262.52 37.8501 262.72 37.1701C262.92 36.5001 263.06 35.6001 263.15 34.4701C263.23 33.3401 263.28 31.9201 263.28 30.2001H272.37V32.8801C272.37 35.4001 272.03 37.4701 271.36 39.0901C270.69 40.7101 269.79 41.9801 268.68 42.9001C267.57 43.8301 266.3 44.4501 264.87 44.7701C263.44 45.0901 261.97 45.2501 260.45 45.2501C257.83 45.2501 255.67 44.9301 253.99 44.2901C252.31 43.6501 250.99 42.5801 250.05 41.0801C249.11 39.5801 248.46 37.6501 248.11 35.2701C247.76 32.8901 247.58 30.0301 247.58 26.6601C247.58 23.2901 247.79 20.3201 248.21 17.9301C248.63 15.5401 249.35 13.6101 250.36 12.1201C251.37 10.6401 252.7 9.57005 254.35 8.91005C256 8.25005 258.04 7.93005 260.46 7.93005C262.58 7.93005 264.38 8.20005 265.86 8.74005C267.34 9.28005 268.54 10.0401 269.47 11.0401C270.4 12.0301 271.07 13.2301 271.49 14.6201C271.91 16.0101 272.12 17.5901 272.12 19.3401V21.6101H263.03V19.5901C263.03 17.5401 262.83 16.1401 262.42 15.4001V15.4201Z"
          fill="#003366"
        />
        <path
          id="Vector_11"
          d="M289.53 15.4201C289.13 14.6801 288.47 14.3101 287.56 14.3101C286.85 14.3101 286.29 14.4901 285.87 14.8601C285.45 15.2301 285.13 15.8901 284.91 16.8301C284.69 17.7701 284.55 19.0401 284.48 20.6401C284.41 22.2401 284.38 24.2501 284.38 26.6701C284.38 29.0901 284.41 31.1001 284.48 32.7001C284.55 34.3001 284.69 35.5701 284.91 36.5101C285.13 37.4501 285.45 38.1101 285.87 38.4801C286.29 38.8501 286.85 39.0401 287.56 39.0401C288.13 39.0401 288.6 38.9001 288.97 38.6101C289.34 38.3201 289.63 37.8501 289.83 37.1701C290.03 36.5001 290.17 35.6001 290.26 34.4701C290.34 33.3401 290.39 31.9201 290.39 30.2001H299.48V32.8801C299.48 35.4001 299.14 37.4701 298.47 39.0901C297.8 40.7101 296.9 41.9801 295.79 42.9001C294.68 43.8301 293.41 44.4501 291.98 44.7701C290.55 45.0901 289.08 45.2501 287.56 45.2501C284.94 45.2501 282.78 44.9301 281.1 44.2901C279.42 43.6501 278.1 42.5801 277.16 41.0801C276.22 39.5801 275.57 37.6501 275.22 35.2701C274.87 32.8901 274.69 30.0301 274.69 26.6601C274.69 23.2901 274.9 20.3201 275.32 17.9301C275.74 15.5401 276.46 13.6101 277.47 12.1201C278.48 10.6401 279.81 9.57005 281.46 8.91005C283.11 8.25005 285.15 7.93005 287.57 7.93005C289.69 7.93005 291.49 8.20005 292.97 8.74005C294.45 9.28005 295.65 10.0401 296.58 11.0401C297.51 12.0301 298.18 13.2301 298.6 14.6201C299.02 16.0101 299.23 17.5901 299.23 19.3401V21.6101H290.14V19.5901C290.14 17.5401 289.94 16.1401 289.53 15.4001V15.4201Z"
          fill="#003366"
        />
        <path
          id="Vector_12"
          d="M324.22 8.66003V16.33H312.26V22.49H323.47V29.86H312.26V37.03H324.68V44.7H302.87V8.66003H324.23H324.22Z"
          fill="#003366"
        />
        <path
          id="Vector_13"
          d="M342.14 18.2001C342.14 17.1201 341.92 16.2101 341.48 15.4501C341.04 14.6901 340.37 14.3101 339.46 14.3101C338.45 14.3101 337.71 14.6401 337.24 15.2901C336.77 15.9501 336.53 16.6801 336.53 17.4901C336.53 18.6701 336.91 19.6001 337.67 20.2901C338.43 20.9801 339.38 21.5801 340.52 22.0801C341.66 22.5801 342.89 23.1001 344.21 23.6201C345.52 24.1401 346.75 24.8201 347.9 25.6601C349.04 26.5001 350 27.6001 350.75 28.9701C351.51 30.3301 351.89 32.1101 351.89 34.3001C351.89 38.1401 350.8 40.9201 348.61 42.6601C346.42 44.3901 343.26 45.2601 339.12 45.2601C337.17 45.2601 335.44 45.1001 333.95 44.7801C332.45 44.4601 331.19 43.9001 330.16 43.1101C329.13 42.3201 328.35 41.2801 327.81 40.0101C327.27 38.7301 327 37.1501 327 35.2601V34.0001H336.09V34.8601C336.09 36.4101 336.38 37.4901 336.95 38.1201C337.52 38.7401 338.26 39.0501 339.17 39.0501C340.15 39.0501 340.89 38.7101 341.42 38.0401C341.94 37.3701 342.2 36.5401 342.2 35.5701C342.2 34.3901 341.84 33.4601 341.12 32.7701C340.4 32.0801 339.49 31.4901 338.39 31.0001C337.3 30.5101 336.11 30.0201 334.83 29.5101C333.55 29.0101 332.36 28.3501 331.27 27.5401C330.18 26.7301 329.27 25.6701 328.54 24.3601C327.82 23.0501 327.46 21.3301 327.46 19.2101C327.46 15.5401 328.44 12.7501 330.39 10.8301C332.34 8.91007 335.3 7.95007 339.28 7.95007C343.26 7.95007 346.36 8.83007 348.19 10.6001C350.02 12.3701 350.94 15.1901 350.94 19.0601H342.16V18.2001H342.14Z"
          fill="#003366"
        />
        <path
          id="Vector_14"
          d="M369.25 18.2001C369.25 17.1201 369.03 16.2101 368.59 15.4501C368.15 14.6901 367.48 14.3101 366.57 14.3101C365.56 14.3101 364.82 14.6401 364.35 15.2901C363.88 15.9501 363.64 16.6801 363.64 17.4901C363.64 18.6701 364.02 19.6001 364.78 20.2901C365.54 20.9801 366.49 21.5801 367.63 22.0801C368.77 22.5801 370 23.1001 371.32 23.6201C372.63 24.1401 373.86 24.8201 375.01 25.6601C376.15 26.5001 377.11 27.6001 377.86 28.9701C378.62 30.3301 379 32.1101 379 34.3001C379 38.1401 377.91 40.9201 375.72 42.6601C373.53 44.3901 370.37 45.2601 366.23 45.2601C364.28 45.2601 362.55 45.1001 361.06 44.7801C359.56 44.4601 358.3 43.9001 357.27 43.1101C356.24 42.3201 355.46 41.2801 354.92 40.0101C354.38 38.7301 354.11 37.1501 354.11 35.2601V34.0001H363.2V34.8601C363.2 36.4101 363.49 37.4901 364.06 38.1201C364.63 38.7401 365.37 39.0501 366.28 39.0501C367.26 39.0501 368 38.7101 368.53 38.0401C369.05 37.3701 369.31 36.5401 369.31 35.5701C369.31 34.3901 368.95 33.4601 368.23 32.7701C367.51 32.0801 366.6 31.4901 365.5 31.0001C364.41 30.5101 363.22 30.0201 361.94 29.5101C360.66 29.0101 359.47 28.3501 358.38 27.5401C357.29 26.7301 356.38 25.6701 355.65 24.3601C354.93 23.0501 354.57 21.3301 354.57 19.2101C354.57 15.5401 355.55 12.7501 357.5 10.8301C359.45 8.91007 362.41 7.95007 366.39 7.95007C370.37 7.95007 373.47 8.83007 375.3 10.6001C377.13 12.3701 378.05 15.1901 378.05 19.0601H369.27V18.2001H369.25Z"
          fill="#003366"
        />
      </g>
      <path
        id="Vector_15"
        d="M173.51 16.7L176.24 24.02L179.37 16.7H188.46L181.59 29.93L188.81 44.02H179.72L176.54 35.79L173.21 44.02H164.27L171.34 29.93L164.42 16.7H173.51Z"
        fill="white"
        stroke="#577483"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
)
export default FundingSuccessLogoSVGNoAnimation
