import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import Swipe from './Swipe';
import Profile from './Profile';
import Chat from './Chat';
import Deck from './Deck';
import Successes from './Successes';
import ScholarshipForm from '../component/addScholarship/ScholarshipForm';
import { FaHome, FaComments, FaProjectDiagram, FaPlus } from 'react-icons/fa';
import BeaverLogoBlinkAnimation from './BeaverLogoBlinkAnimation';
import DonorMedalBronze from '../component/SVG/donorMedals/DonorMedalBronze'
import MedalPopUp from '../component/MainPage/MedalPopUp'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  justify-content: center; /* Centers content vertically */
  padding: 30px;
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    // padding: 10px;
    min-width: 100%; /* Takes up the entire width of the screen on mobile */

  }
`;


const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  // margin-bottom: ${({ selectedComponent }) => (selectedComponent === 'swipe' ? '40px' : '0px')};
  // margin-bottom: ${({ selectedComponent }) => (selectedComponent === 'chat' ? '0px' : '40px')};
  // margin-bottom: ${({ selectedComponent }) => (selectedComponent === 'addScholarship' ? '0px' : '40px')};

  @media (max-width: 768px) {
    width: 100vw;
    margin-bottom: ${({ selectedComponent }) => (selectedComponent === 'profswipeile' ? '20px' : '0px')};
    // margin-bottom: ${({ selectedComponent }) => (selectedComponent === 'chat' ? '0px' : '20px')};
    // margin-bottom: ${({ selectedComponent }) => (selectedComponent === 'addScholarship' ? '0px' : '20px')};
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
`;

const StreakButton = styled.div`
  padding: 5px 10px;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const StreakNumber = styled.span`
  margin-left: 5px;
  font-size: 1.2em;
`;

const ContentWrapper = styled.div`
  width: 100%;
  // height: 140%;
  // // flex-grow: 1;
  // min-height: ${props => (props.selectedComponent === 'chat' ? '75%' : '95%')};
  min-height: 95%;
  position: relative;
  display: flex;
  flex-direction: column;
  // padding-right: 30px;
  overflow-y: ${props => (props.selectedComponent === 'chat' ? 'hidden' : 'visible')};
  overflow-y: ${props => (props.selectedComponent === 'success' ? 'auto' : 'visible')};
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  @media (max-width: 768px) {
    padding-right: ${props => (props.selectedComponent === 'addScholarship' ? '30px' : '0px')};
    width: ${props => (props.selectedComponent === 'addScholarship' ? '90%' : '100%')};
    min-height: ${props => (props.selectedComponent === 'chat' ? '85%' : '95%')};
    margin-left: ${props => (props.selectedComponent === 'profile' ? '-45px' : '0px')};
  }
`;

const NavBarWrapper = styled.div`
  width: 100%;
  // margin-left: -11px;
  max-width: 600px;
  min-width: 600px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 0;
    margin-left: 0;
    left: 0;
    transform: none;
  }
`;

const BeaverContainer = styled.div`
  display: flex;
  height: 100%;
  width: 10%;
`;

const DonorMedalBronzeWrapper = styled.div`
  width: 30px;
  height: 30px;
`;

const ScholarshipContainer = styled.div`
  // height: 65%;
  max-height: 80%; /* Adjust the height as needed */
  padding-right: 30px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;


const NavBar = styled.div`
  display: flex;
  justify-content: space-between; /* Space between the items */
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #003366;
  color: white;
  @media (max-width: 768px) {
    width: 100%;

  }
`;

const ScholarshipTitle = styled.h1`
  color: #003366;
  padding-left: 30px;
`;


const NavItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
  cursor: pointer;
  width: 33.33%; /* Each NavItem takes 1/3 of the NavBar's width */
  text-align: center; /* Centers content within each NavItem */
`;

const DeckStyle = styled.div`
  // cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39, auto;
  display: flex;
  align-items: center;
  height: 70%; /* Adjust the height as needed */
  justify-content: center;
  width: 100%;
  padding-top: 80px;
`;

const SwipeWrapperTotal = styled.div`
  margin-top: 40px; /* Matching margin-top with Header's margin-bottom */

  @media (max-width: 768px) {
    margin-top: 20px; /* Matching margin-top with Header's margin-bottom for small screens */
  }
`;

const SwipeStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
`;

const ChatOverlay = styled.div`
  // overflow-y: auto;
  height: 100%;
  width: 100%;
  
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  // @media (max-width: 768px) {
  //   margin-left: -30px;
  // }
`;

function Mainpage() {
  const [selectedComponent, setSelectedComponent] = useState('swipe');
  const [userData, setUserData] = useState({ name: '', email: '', profilePictureURL: '', isDonor: 0 });
  const [showMedalPopUp, setShowMedalPopUp] = useState(false); // State to control the popup visibility

  const handleDonorMedalClick = () => {
    setShowMedalPopUp(true);
  };

  const closeMedalPopUp = () => {
    setShowMedalPopUp(false);
  };


  const currentUserUid = auth.currentUser ? auth.currentUser.uid : 'anonymous';

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUserUid !== 'anonymous') {
        const userRef = doc(db, 'users', currentUserUid);
        try {
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            setUserData(docSnap.data());
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [currentUserUid]);

  const updateUserStreak = async (uid) => {
    const userRef = doc(db, 'users', uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      const lastLoginDate = userData.lastLoginDate?.toDate();
      const currentDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      console.log("last login date", lastLoginDate)
      if (lastLoginDate) {
        const diffDays = Math.round(Math.abs((currentDate - lastLoginDate) / oneDay));
        if (diffDays === 1) {
          await updateDoc(userRef, {
            currentStreak: (userData.currentStreak || 0) + 1,
            lastLoginDate: Timestamp.fromDate(currentDate)
          });
        } else if (diffDays > 1) {
          await updateDoc(userRef, {
            currentStreak: 0,
            lastLoginDate: Timestamp.fromDate(currentDate)
          });
        }
      } else {
        await updateDoc(userRef, {
          currentStreak: 1,
          lastLoginDate: Timestamp.fromDate(currentDate)
        });
      }
    } else {
      console.log('No such document!');
    }
  };
  
  auth.onAuthStateChanged(user => {
    if (user) {
      updateUserStreak(user.uid);
    }
  });

  const handleUserImageClick = () => {
    setSelectedComponent('profile');
  };
  
  const renderContent = () => {
    if (!userData || !userData.email) {
      return (
        <div>Loading...</div> // You can replace this with a loading spinner or any other placeholder
      );
    }
  
    switch (selectedComponent) {
      case 'profile':
        return <Profile userData={userData} setUserData={setUserData} />;
      case 'chat':
        return (
          <ChatOverlay>
            <Chat />
          </ChatOverlay>
        );
      case 'success':
        return <Successes userData={userData} />;
      case 'addScholarship':
        return (
          <ScholarshipContainer>
            <ScholarshipTitle>Add Scholarships</ScholarshipTitle>
            <ScholarshipForm />
          </ScholarshipContainer>
        );
      case 'swipe':
      default:
        return (
          <DeckStyle>
            <Deck 
              userProfilePictureURL={userData.profilePictureURL} 
              isDonor={userData.isDonor} 
              userData={userData} 
            />
          </DeckStyle>
        );
    }
  };

  const popEffect = {
    whileTap: { scale: 0.9 },
    whileHover: { scale: 1.1 },
  };

  return (
    <Wrapper>
     <Header selectedComponent={selectedComponent}>
        <BeaverContainer>
            <BeaverLogoBlinkAnimation />
        </BeaverContainer>
        <RightSection>
            {userData.isDonor === 1 ? (
                <DonorMedalBronzeWrapper onClick={handleDonorMedalClick}>
                <DonorMedalBronze />
              </DonorMedalBronzeWrapper>
              ) : (
                <StreakButton>
                🔥
                <StreakNumber>{userData.currentStreak || 0}</StreakNumber>
              
            </StreakButton>
              )}
            <UserImage
                src={userData.profilePictureURL || 'https://via.placeholder.com/50'}
                alt="User"
                onClick={handleUserImageClick}
            />
        </RightSection>
      </Header>

      <ContentWrapper selectedComponent={selectedComponent}>
        {renderContent()}
      </ContentWrapper>

      {showMedalPopUp && <MedalPopUp onClose={closeMedalPopUp} />} {/* Render the MedalPopUp when triggered */}
      
      <NavBarWrapper>
        <NavBar>
          <NavItem onClick={() => setSelectedComponent('swipe')} {...popEffect}>
            <FaHome />
            <span>Home</span>
          </NavItem>
          <NavItem onClick={() => setSelectedComponent('chat')} {...popEffect}>
            <FaComments />
            <span>Chat</span>
          </NavItem>
          {userData.isDonor === 1 ? (
            <NavItem onClick={() => setSelectedComponent('addScholarship')} {...popEffect}>
              <FaPlus />
              <span>Scholarship</span>
            </NavItem>
          ) : (
            <NavItem onClick={() => setSelectedComponent('success')} {...popEffect}>
              <FaProjectDiagram />
              <span>Successes</span>
            </NavItem>
          )}
        </NavBar>
      </NavBarWrapper>
    </Wrapper>
  );
}

export default Mainpage;
