import React from 'react';
import Personal from './Personal';
import Experience from './Experience';
import LoanDonor from './donor/LoanDonor';
import Universities from './donor/Universities'
import Interests from './Interests';
import Education from './Education';
import Project from './Project';
import Loan from './Loan'

const StepContent = ({
  step,
  personalEntries,
  setPersonalEntries,
  experienceEntries,
  setExperienceEntries,
  loanEntries,
  setLoanEntries,
  selectedInterests,
  setSelectedInterests,
  educationEntries,
  setEducationEntries,
  projectEntries,
  setProjectEntries,
  isDonor
}) => {
  const stepsDonor = [
    <Personal personalEntries={personalEntries} setPersonalEntries={setPersonalEntries} />,
    <Universities educationEntries={educationEntries} setEducationEntries={setEducationEntries} />,
    <Interests selectedInterests={selectedInterests} setSelectedInterests={setSelectedInterests} />,
    <LoanDonor loanEntries={loanEntries} setLoanEntries={setLoanEntries} />,
  ];

  const steps = [ 
    
    <Personal personalEntries={personalEntries} setPersonalEntries={setPersonalEntries} />,
    <Education educationEntries={educationEntries} setEducationEntries={setEducationEntries} />,
    <Project projectEntries={projectEntries} setProjectEntries={setProjectEntries} />,
    <Loan loanEntries={loanEntries} setLoanEntries={setLoanEntries} />,
    // <Experience experienceEntries={experienceEntries} setExperienceEntries={setExperienceEntries} />,
   
    // <Interests selectedInterests={selectedInterests} setSelectedInterests={setSelectedInterests} />,
    
   
  ];

  return isDonor ? stepsDonor[step] : steps[step];
};

export default StepContent;
