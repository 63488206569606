import React from 'react';
import styled from 'styled-components';
import { FaPlusCircle } from 'react-icons/fa';

const Page = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eaeaea;
  margin: 20px 0;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const DualInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
`;

const Title = styled.h1`
  color: black;
`;

const Verticaldiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AddMoreButton = styled.button`
  background: none;
  color: #003366;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    background: #e7f5fe;
  }

  &:focus {
    outline: none;
  }
`;

const PlusIcon = styled(FaPlusCircle)`
  margin-right: 5px;
`;

const Education = ({ educationEntries, setEducationEntries }) => {
  // Function to convert MM/DD/YYYY to DD/MM/YYYY
  const convertToInternalFormat = (date) => {
    if (!date) return '';
    const [mm, dd, yyyy] = date.split('/');
    return `${dd}/${mm}/${yyyy}`; // Return the date in DD/MM/YYYY format
  };

  const handleDateInputChange = (index, field, event) => {
    const values = [...educationEntries];
    const inputDate = event.target.value;

    // Keep the input in MM/DD/YYYY format for display and internally save it in DD/MM/YYYY
    values[index][field] = convertToInternalFormat(inputDate); // Convert to DD/MM/YYYY for internal storage
    setEducationEntries(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...educationEntries];
    values[index][event.target.name] = event.target.value;
    setEducationEntries(values);
  };

  const handleAddMore = () => {
    setEducationEntries([...educationEntries, { university: '', major: '', gpa: '', startDate: '', endDate: '' }]);
  };

  return (
    <Page>
      <Title>Education</Title>
      <Form>
        {educationEntries.map((entry, index) => (
          <div key={index}>
            {index > 0 && <Divider />}
            <Verticaldiv>
              <InputLabel>University</InputLabel>
              <Input
                type="text"
                name="university"
                value={entry.university}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter your University"
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>Major</InputLabel>
              <Input
                type="text"
                name="major"
                value={entry.major}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter Major"
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>GPA</InputLabel>
              <Input
                type="text"
                name="gpa"
                value={entry.gpa}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter GPA"
                required
              />
            </Verticaldiv>
            <DualInputContainer>
              <div>
                <InputLabel>Start Date</InputLabel>
                <Input
                  type="text"
                  name="startDate"
                  value={entry.startDate}  // Keep in MM/DD/YYYY for display
                  onChange={event => handleDateInputChange(index, 'startDate', event)}
                  placeholder="MM/DD/YYYY"  // Placeholder as MM/DD/YYYY
                  required
                />
              </div>
              <div>
                <InputLabel>End Date</InputLabel>
                <Input
                  type="text"
                  name="endDate"
                  value={entry.endDate}  // Keep in MM/DD/YYYY for display
                  onChange={event => handleDateInputChange(index, 'endDate', event)}
                  placeholder="MM/DD/YYYY"  // Placeholder as MM/DD/YYYY
                  required
                />
              </div>
            </DualInputContainer>
          </div>
        ))}
        <AddMoreButton type="button" onClick={handleAddMore}>
          <PlusIcon />
          Add More
        </AddMoreButton>
      </Form>
    </Page>
  );
};

export default Education;
