import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

const SectionContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SectionItem = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const AddItemButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #577483;
  }

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled(FaTrash)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #577483;

  &:hover {
    color: #003366;
  }
`;

const formatDateForInput = (dateStr) => {
  const [dd, mm, yyyy] = dateStr.split('/');
  return `${yyyy}-${mm}-${dd}`;
};

const formatDateForDisplay = (dateStr) => {
  const [yyyy, mm, dd] = dateStr.split('-');
  return `${dd}/${mm}/${yyyy}`;
};

const ExperienceComponent = ({ experience, setExperience }) => {
  const [newExperience, setNewExperience] = useState(null);

  const handleChange = (index, field, value) => {
    const updatedExperience = [...experience];
    updatedExperience[index][field] = value;
    setExperience(updatedExperience);
  };

  const handleAddItem = (newItem) => {
    if (newItem) {
      setExperience((prev) => [...prev, newItem]);
      setNewExperience(null);
    }
  };

  const handleDeleteItem = (index) => {
    setExperience((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <SectionContainer>
      {experience.map((exp, index) => (
        <SectionItem key={index}>
          <DeleteButton onClick={() => handleDeleteItem(index)} />
          <InputLabel>
            Job Title
            <Input type="text" value={exp.jobTitle} onChange={(e) => handleChange(index, 'jobTitle', e.target.value)} />
          </InputLabel>
          <InputLabel>
            Company
            <Input type="text" value={exp.company} onChange={(e) => handleChange(index, 'company', e.target.value)} />
          </InputLabel>
          <InputLabel>
            Location
            <Input type="text" value={exp.location} onChange={(e) => handleChange(index, 'location', e.target.value)} />
          </InputLabel>
          <InputLabel>
            Start Date
            <Input type="date" value={formatDateForInput(exp.startDate)} onChange={(e) => handleChange(index, 'startDate', formatDateForDisplay(e.target.value))} />
          </InputLabel>
          <InputLabel>
            End Date
            <Input type="date" value={formatDateForInput(exp.endDate)} onChange={(e) => handleChange(index, 'endDate', formatDateForDisplay(e.target.value))} />
          </InputLabel>
        </SectionItem>
      ))}
      {newExperience === null ? (
        <AddItemButton onClick={() => setNewExperience({ jobTitle: '', company: '', location: '', startDate: '', endDate: '' })}>Add Experience</AddItemButton>
      ) : (
        <SectionItem>
          <InputLabel>
            Job Title
            <Input type="text" value={newExperience.jobTitle} onChange={(e) => setNewExperience({ ...newExperience, jobTitle: e.target.value })} />
          </InputLabel>
          <InputLabel>
            Company
            <Input type="text" value={newExperience.company} onChange={(e) => setNewExperience({ ...newExperience, company: e.target.value })} />
          </InputLabel>
          <InputLabel>
            Location
            <Input type="text" value={newExperience.location} onChange={(e) => setNewExperience({ ...newExperience, location: e.target.value })} />
          </InputLabel>
          <InputLabel>
            Start Date
            <Input type="date" value={newExperience.startDate} onChange={(e) => setNewExperience({ ...newExperience, startDate: e.target.value })} />
          </InputLabel>
          <InputLabel>
            End Date
            <Input type="date" value={newExperience.endDate} onChange={(e) => setNewExperience({ ...newExperience, endDate: e.target.value })} />
          </InputLabel>
          <AddItemButton onClick={() => handleAddItem(newExperience)}>Add Experience</AddItemButton>
        </SectionItem>
      )}
    </SectionContainer>
  );
};

export default ExperienceComponent;
