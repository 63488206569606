import React, {useState, useEffect} from 'react';
// import Home from './page/Home';
import Signup from './page/Signup';
// import Login from './page/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import {auth} from './firebase'; // Make sure this path is correct
// import Register from './page/Register';
// import UserForm from './page/UserForm';
// import UsersList from './page/Userslist';
// import Chat from './page/Chat';
import LandingPage from './page/LandingPage';
import ContinuePage from './page/ContinuePage';
import Logging from './page/Logging';
// import Maps from './page/Maps';
import UserDataInput from './page/UserDataInput';
// import LanguageSelection from './page/LanguageSelection';
// import EducationForm from './page/EducationForm';
// import ExperienceForm from './page/ExperienceForm';
// import LoanPreferences from './page/LoanPreference';
// import LocationAccessComponent from './page/LocationAccessComponent';
// import Swipe from './page/Swipe'
// import PersonalData from './page/PersonalData';
// import SwipeLocation from './page/SwipeLocation';
// import SwipeSetLocation from './page/SwipeSetLocation';
// import Evaluation from './page/Evaluation';
// import InterestSelector from './page/InterestSelector';
import Mainpage from './page/Mainpage';
// import Profile from './page/Profile';
import SignupInformation from './page/SignupInformation';
import AddScholarship from './page/AddScholarship';
import ScholarshipExcelInput from './page/ScholarshipExcelInput';
import ForgotPassword from './page/ForgotPassword';
import LandingPageV2 from './page/LandingpageV2';
import AboutUsPage from './page/AboutUsPage';
import Career from './page/Career';
import Statistics from './page/Statisitics';
import DonorsLogin from './page/DonorsLogin';
import StartScholarship from './page/StartScholarship';
import HowTo from './page/HowTo';
import StudentProgress from './page/StudentProgress';
import BetaUser from './page/BetaUser';
 
// ProtectedRoute component
const ProtectedRoute = ({children}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
              setUser(user);
          } else {
              setUser(null);
          }
          setLoading(false);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
  }, []);

  if (loading) {
      return <div>Loading...</div>; // Or any loading spinner
  }

  return user ? children : <Navigate to="/login" />;
};

// Then wrap your Home route with ProtectedRoute in your App component
function App() {
  return (
      <Router>
          <div>
              <section>
                  <Routes>
                      <Route path="/" element={
                     
                         <LandingPageV2/>
                   
                             
                      
                      }/>
                      {/* <Route path="/register" element={
                          <ProtectedRoute>
                              <Register/>
                          </ProtectedRoute>
                      }/>
                      <Route path="/userform" element={
                          <ProtectedRoute>
                              <UserForm/>
                          </ProtectedRoute>
                      }/> */}
                      {/* <Route path="/chat" element={
                          <ProtectedRoute>
                              <Chat/>
                          </ProtectedRoute>
                      }/> */}
                      {/* <Route path="/userslist" element={
                          <ProtectedRoute>
                              <UsersList/>
                          </ProtectedRoute>
                      }/>
                      <Route path="/experienceform" element={
                          <ProtectedRoute>
                              <ExperienceForm/>
                          </ProtectedRoute>
                      }/>
                      <Route path="/education" element={
                          <ProtectedRoute>
                              <EducationForm/>
                          </ProtectedRoute>
                      }/>
                       <Route path="/language" element={
                          <ProtectedRoute>
                              <LanguageSelection/>
                          </ProtectedRoute>
                      }/>
                      <Route path="/swipesetlocation/:city" element={
                            <ProtectedRoute>
                                <SwipeSetLocation/>
                            </ProtectedRoute>
                        }/> */}
                      {/* <Route path="/location" element={
                          <ProtectedRoute>
                              <LocationAccessComponent/>
                          </ProtectedRoute>
                      }/> */}

                    {/* <Route path="/loan" element={
                          <ProtectedRoute>
                              <LoanPreferences/>
                          </ProtectedRoute>
                      }/>
                      <Route path="/swipesetlocation" element={
                          <ProtectedRoute>
                              <SwipeSetLocation/>
                          </ProtectedRoute>
                      }/>
                      <Route path="/swipe" element={
                          <ProtectedRoute>
                              <Swipe/>
                          </ProtectedRoute>
                      }/> */}
                      {/* <Route path="/personaldata" element={
                          <ProtectedRoute>
                              <PersonalData/>
                          </ProtectedRoute>
                      }/> */}
                        <Route path="/home" element={
                          <ProtectedRoute>
                              <Mainpage/>
                          </ProtectedRoute>
                      }/>
                       {/* <Route path="/profile" element={
                          <ProtectedRoute>
                              <Profile/>
                          </ProtectedRoute>
                      }/> */}
                        {/* <Route path="/chat" element={
                          <ProtectedRoute>
                              <Chat/>
                          </ProtectedRoute>
                      }/> */}
                       {/* <Route path="/chatuser" element={
                          
                              <ChatUser/>
                         
                      }/> */}
                      <Route path="/signup" element={<Signup/>}/>
                      <Route path="/login" element={<Logging/>}/>
                      <Route path="/continuepage" element={<ContinuePage/>}/>
                      {/* <Route path="/logging" element={<Logging/>}/> */}
                      {/* <Route path="/maps" element={<Maps/>}/> */}
                      <Route path="/createprofile" element={<UserDataInput/>}/>
                      {/* <Route path="/landingpage" element={<LandingPage/>}/> */}
                      <Route path="/signupinformation" element={<SignupInformation/>}/>
                      <Route path="/scholarship" element={<AddScholarship/>}/>
                      <Route path="/scholarshipexcel" element={<ScholarshipExcelInput/>}/>
                      <Route path="/forgot-password" element={<ForgotPassword />} />
                      {/* <Route path="/landingpage2" element={<LandingPageV2 />} /> */}
                      <Route path="/about" element={<AboutUsPage />} />
                      <Route path="/career" element={<Career/>} />
                      <Route path="/student" element={<StudentProgress/>} />
                      <Route path="/how-to/:section" element={<HowTo />} />
                      <Route path="/statistics" element={<Statistics />} />
                      <Route path="/donor-signup" element={<DonorsLogin />} />
                      <Route path="/scholarship-start" element={<StartScholarship />} />
                      <Route path="/how-to" element={<Navigate to="/how-to/giveBackSection" />} />
                      <Route path="/beta" element={<BetaUser />} />

                  </Routes>
              </section>
          </div>
      </Router>
  );
}

 
export default App;