import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa'; 
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import FundingSuccessLogoSVG from './SVG/FundingSuccessLogoSVG';

// Styled components for layout and design
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 20px;
  background-color: white;
  z-index: 1000;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const Logo = styled(motion.div)`
  cursor: pointer;

  svg {
    width: 150px; 
    height: auto;
  }
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  
  @media (max-width: 768px) {
    display: none;
  }

  a {
    color: #03377e;
    text-decoration: none;
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1em;
    cursor: pointer;

    &:hover {
      color: #022a5f;
    }

    svg {
      margin-left: 5px;
    }
  }
`;

const Dropdown = styled.div`
  position: relative;
`;

const DropdownLink = styled(motion.a)`
  display: flex;
  align-items: center;
  color: #03377e;
  text-decoration: none;
  padding: 8px 0;

  &:hover {
    color: #022a5f;
  }

  svg {
    margin-left: 5px;
    font-size: 1em; 
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.2); 
    }
  }
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 160px;

  a {
    display: flex;
    align-items: center;
  }
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  color: #003366;

  .user-icon {
    font-size: 1.5em;
    margin-right: 10px;
  }

  .account-text {
    font-size: 1em;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Navbardonor = () => {
  const navigate = useNavigate();
  const [showDropdownScholarship, setShowDropdownScholarship] = useState(false);
  const [showDropdownFunds, setShowDropdownFunds] = useState(false);
  const [showDropdownCompany, setShowDropdownCompany] = useState(false);

  return (
    <TopBar>
      <Logo whileHover={{ scale: 1.1 }} onClick={() => navigate('/')}>
        <FundingSuccessLogoSVG />
      </Logo>

      {/* Navbar with dropdowns */}
      <Navbar>
        {/* Scholarship Dropdown */}
        {/* <Dropdown
          onMouseEnter={() => setShowDropdownScholarship(true)}
          onMouseLeave={() => setShowDropdownScholarship(false)}
        >
          <a>
            Scholarship <FaChevronDown />
          </a>
          <DropdownContent show={showDropdownScholarship}>
            <DropdownLink href="/start-scholarship" whileHover={{ scale: 1.05 }}>Start a Scholarship</DropdownLink>
            <DropdownLink href="/how-it-works" whileHover={{ scale: 1.05 }}>How it works</DropdownLink>
            <DropdownLink href="/award-features" whileHover={{ scale: 1.05 }}>Award Features</DropdownLink>
            <DropdownLink href="/pricing" whileHover={{ scale: 1.05 }}>Pricing</DropdownLink>
            <DropdownLink href="/faq" whileHover={{ scale: 1.05 }}>FAQ</DropdownLink>
          </DropdownContent>
        </Dropdown> */}

        {/* Funds Dropdown */}
        {/* <Dropdown
          onMouseEnter={() => setShowDropdownFunds(true)}
          onMouseLeave={() => setShowDropdownFunds(false)}
        >
          <a>
            Funds <FaChevronDown />
          </a>
          <DropdownContent show={showDropdownFunds}>
            <DropdownLink href="/start-fund" whileHover={{ scale: 1.05 }}>Start a Fund</DropdownLink>
            <DropdownLink href="/how-it-works" whileHover={{ scale: 1.05 }}>How it works</DropdownLink>
            <DropdownLink href="/pricing" whileHover={{ scale: 1.05 }}>Pricing</DropdownLink>
            <DropdownLink href="/faq" whileHover={{ scale: 1.05 }}>FAQ</DropdownLink>
          </DropdownContent>
        </Dropdown> */}

        {/* Company Dropdown */}
        {/* <Dropdown
          onMouseEnter={() => setShowDropdownCompany(true)}
          onMouseLeave={() => setShowDropdownCompany(false)}
        >
          <a>
            Company <FaChevronDown />
          </a>
          <DropdownContent show={showDropdownCompany}>
            <DropdownLink onClick={() => navigate('/about')} whileHover={{ scale: 1.05 }}>About Us</DropdownLink>
            <DropdownLink onClick={() => navigate('/career')} whileHover={{ scale: 1.05 }}>Career</DropdownLink>
          </DropdownContent>
        </Dropdown> */}
      </Navbar>

      {/* Right side: User Section */}
      <UserSection onClick={() => navigate('/beta')}>
        <span className="account-text">Already have an account?</span>
      </UserSection>
    </TopBar>
  );
};

export default Navbardonor;
