import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  // background-color: #f9fafb;
  padding: 40px 20px;
`;

const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Heading = styled.h2`
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

const AccordionWrapper = styled.div``;

const AccordionItem = styled.div`
  margin-bottom: 20px;
`;

const AccordionHeading = styled.div`
  background-color: #ebecf0;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

const AccordionPanel = styled.div`
  max-height: ${(props) => (props.isOpen ? "200px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: white;
  padding: ${(props) => (props.isOpen ? "20px" : "0 20px")};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
`;

const IconWrapper = styled.div`
  margin-left: 10px;
`;

const AccordionIcon = styled.svg`
  width: 44px;
  height: 44px;
  stroke: #acaebf;
`;

const Accordion = ({ title, content, isOpen, onClick }) => (
  <AccordionItem>
    <AccordionHeading onClick={onClick}>
      {title}
      <IconWrapper>
        <AccordionIcon viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M34.2224 21.9997H9.77765" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          {isOpen ? (
            <path d="M22 9.77734V34.2221" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          ) : null}
        </AccordionIcon>
      </IconWrapper>
    </AccordionHeading>
    <AccordionPanel isOpen={isOpen}>
      <p>{content}</p>
    </AccordionPanel>
  </AccordionItem>
);

const DonationDetails = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Container>
      <ContentWrapper>
        <Heading>Donation Details</Heading>
        <AccordionWrapper>
          <Accordion
            title="Are award contributions tax deductible?"
            content="Yes! Donations are considered as a 501(c)(3) non-profit (Federal Tax ID #84-2719715). When your donation is processed, you’ll receive an email with documentation confirming your tax-deductible donation."
            isOpen={openIndex === 0}
            onClick={() => toggleAccordion(0)}
          />
         
         <Accordion
            title="Who are the typical donors on Funding Success?"
            content="Funding Success donors include change-makers—individuals passionate about supporting students who aim to make a positive impact on the world. Our donors also include purpose-driven philanthropists looking to give for meaningful causes with the benefit of 100% tax deductibility, as well as committed supporters who can contribute $1,000 or more per month to provide consistent support."
            isOpen={openIndex === 2}
            onClick={() => toggleAccordion(2)}
          />

           
           <Accordion
            title="How does Funding Success track the impact of my donation?"
            content="Funding Success uses a GenAI algorithm to track and verify student performance, providing donors with data-driven insights. You will receive reports comparing the recipient’s achievements to peers from top institutions, giving you a clear picture of your donation’s impact."
            isOpen={openIndex === 3}
            onClick={() => toggleAccordion(3)}
          />

          <Accordion
            title="Can I continue supporting the same student over multiple semesters?"
            content="Yes, our platform offers continuity by allowing donors to continue supporting the same student over multiple semesters. Donors can use detailed performance reports to decide whether to renew their support."
            isOpen={openIndex === 4}
            onClick={() => toggleAccordion(4)}
          />

          <Accordion
            title="What is smart matching, and how does it work?"
            content="Our GenAI Matching Algorithm connects students and donors based on shared interests and goals. By leveraging Large Language Models trained on student achievements from top institutions, we ensure that donors and students are matched meaningfully."
            isOpen={openIndex === 5}
            onClick={() => toggleAccordion(5)}
          />

          <Accordion
            title="How does the subscription model work?"
            content="Donors subscribe for $25/month, which grants access to tailored student matching services and detailed performance reports. This subscription helps ensure that you get the best match for your donations."
            isOpen={openIndex === 6}
            onClick={() => toggleAccordion(6)}
          />

          <Accordion
            title="What kinds of donors use Funding Success?"
            content="Our donors include individuals, families, companies, and foundations. They are passionate about supporting students and seeing a positive ROI on their contributions. We make it easy for any donor to create and manage scholarships in minutes."
            isOpen={openIndex === 7}
            onClick={() => toggleAccordion(7)}
          />

          <Accordion
            title="How do partnerships with financial institutions work?"
            content="We collaborate with loan institutions to generate revenue through strategic partnerships, marketing, and advertising opportunities. This allows us to keep the platform free for students and donors."
            isOpen={openIndex === 8}
            onClick={() => toggleAccordion(8)}
          />

           <Accordion
            title="Can I interview candidates or request letters of recommendation from them?"
            content="Yes, we can typically facilitate this upon request!"
            isOpen={openIndex === 9}
            onClick={() => toggleAccordion(9)}
          />
          
         
          {/* Add more Accordion items as needed */}
        </AccordionWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default DonationDetails;
