import * as React from "react"
const DonorMedalBronze = (props) => (
  <svg
    viewBox="0 0 137 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Bronze Medal" filter="url(#filter0_d_13_82)">
      <path
        id="Rectangle 12"
        d="M51.4592 98.7451L67.944 108.263L34.4687 166.244L24.8606 144.815L51.4592 98.7451Z"
        fill="#AA75CB"
      />
      <path
        id="Rectangle 13"
        d="M51.4592 98.7451L34.9744 89.2276L1.49909 147.209L24.8606 144.815L51.4592 98.7451Z"
        fill="#73488D"
      />
      <path
        id="Rectangle 14"
        d="M74.9773 98.7451L58.4925 108.263L91.9678 166.244L101.576 144.815L74.9773 98.7451Z"
        fill="#73488D"
      />
      <path
        id="Rectangle 15"
        d="M74.9773 98.7451L91.4621 89.2276L124.937 147.209L101.576 144.815L74.9773 98.7451Z"
        fill="#AA75CB"
      />
      <circle
        id="Ellipse 5"
        cx={63.1917}
        cy={58.8229}
        r={57.2539}
        fill="#DC9E42"
        stroke="#774700"
      />
      <circle
        id="Ellipse 6"
        cx={63.1917}
        cy={58.8229}
        r={44.3016}
        fill="#734C12"
      />
      <g id="Mask Group">
        <mask
          id="mask0_13_82"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={21}
          y={19}
          width={89}
          height={89}
        >
          <circle
            id="Ellipse 4"
            cx={65.5563}
            cy={63.5524}
            r={44.4047}
            fill="#C28B37"
          />
        </mask>
        <g mask="url(#mask0_13_82)">
          <circle
            id="Ellipse 3"
            cx={63.1916}
            cy={58.8229}
            r={44.4047}
            fill="#A36D1D"
          />
        </g>
      </g>
      <path
        id="Vector"
        d="M63.431 27.9315L73.0097 47.089L92.1671 49.4836L79.0124 64.2349L82.5884 85.4038L63.431 75.8251L44.2735 85.4038L47.8815 64.2349L34.6948 49.4836L53.8523 47.089L63.431 27.9315Z"
        fill="url(#paint0_linear_13_82)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_13_82"
        x={0.715526}
        y={0.285472}
        width={135.974}
        height={177.711}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={5.48449} dy={5.48449} />
        <feGaussianBlur stdDeviation={3.13399} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13_82"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_13_82"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_13_82"
        x1={63.431}
        y1={27.9315}
        x2={63.431}
        y2={85.4038}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFF80" />
        <stop offset={0.401042} stopColor="#FDE870" />
        <stop offset={1} stopColor="#FFC759" />
      </linearGradient>
    </defs>
  </svg>
)
export default DonorMedalBronze
