import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FundingSuccessLogoSVG from '../component/SVG/FundingSuccessLogoSVG';
import { useNavigate } from 'react-router-dom';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
//   justify-content: space-between;
  height: 100%;
  padding: 20px;
  background-color: white;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 4em;
  font-weight: bold;
  color: #03377e;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 2.5em;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5em;
  color: #577483;
  margin-bottom: 30px;

  span {
    color: #03377e;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 500px; // Adjusted for smaller screens
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Input = styled.input`
  width: 90%; // Takes full width of the FormContainer
  max-width: 90%; // Ensures the input never overflows the container
  padding: 10px;
  margin: 10px 0;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const TextArea = styled.textarea`
  width: 90%; // Takes full width of the FormContainer
  max-width: 90%; // Ensures the input never overflows the container
  padding: 10px;
  margin: 10px 0;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 150px;
`;

const SubmitButton = styled(motion.button)`
  padding: 10px 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #03377e;
  color: #ffffff;
  margin-top: 20px;

  &:hover {
    background-color: #022a5f;
  }

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 10px 15px;
  }
`;

const ConfirmationMessage = styled.p`
  font-size: 1.2em;
  color: green;
  margin-top: 20px;
`;


const StyledLogo = styled(FundingSuccessLogoSVG)`
  height: 30px; 
  position: absolute;
  left: 20px;
  top: 20px;

  @media (max-width: 768px) {
    height: 25px; /* Smaller logo for mobile */
    left: 10px;   /* Adjust left position for mobile */
    top: 10px;    /* Adjust top position for mobile */
  }

  @media (max-width: 480px) {
    height: 20px; /* Even smaller logo for very small screens */
    left: 8px;
    top: 8px;
  }
`;

const Career = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can integrate API to submit the form data here
    console.log('Form submitted:', formData);
    setIsSubmitted(true);
  };

  return (
    <Container>
       <StyledLogo onClick={() => navigate('/')}/>
      <TitleContainer>
        <Title>Join Our Team</Title>
        <Subtitle>Proactively propose to become part of our journey!</Subtitle>
      </TitleContainer>

      {!isSubmitted ? (
        <FormContainer>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextArea
              name="message"
              placeholder="Tell us how you can contribute..."
              value={formData.message}
              onChange={handleChange}
              required
            />
            <SubmitButton
              whileHover={{ scale: 1.1 }}
              type="submit"
            >
              Submit
            </SubmitButton>
          </form>
        </FormContainer>
      ) : (
        <ConfirmationMessage>Thank you for reaching out! We will get back to you soon.</ConfirmationMessage>
      )}
    </Container>
  );
};

export default Career;
