// GiveBackSection.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaEnvelope, FaQuestionCircle } from 'react-icons/fa'; // Import icons

import PaS1 from '../assets/images/PaS1.png';
import PaS2 from '../assets/images/PaS2.png';
import PaS3 from '../assets/images/PaS3.png';

// Styled components for sections
const Section = styled(motion.div)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 0;
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 2rem;
`;

const Button = styled(motion.a)`
  display: inline-block;
  background-color: #003366;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #085CA7;
  }
`;

const BenefitsSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #D1D5DB;
  padding: 2rem 5rem;
  margin: 2rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 2rem 5rem 7rem;
  }
`;

const BenefitItem = styled(motion.div)`
  display: flex;
  margin-bottom: 2rem;

  svg {
    margin-left: 1.25rem;
    margin-top: 0.75rem;
    color: #003366;
    font-size: 30px;
  }

  p {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1rem;
    color: #003366;
  }
`;

const ImageSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }

  img {
    max-width: 280px;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }
`;

const TextBox = styled(motion.div)`
  max-width: 420px;
  text-align: center;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: black;
  }

  p {
    margin-bottom: 1rem;
    color: #333;
  }
`;

const ContactSection = styled(motion.div)`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    text-decoration: none;
    font-size: 1.25rem;
    color: #003366;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

const GiveBackSection = ({ navigate }) => {
  return (
    <>
      <Section>
        <Title>You're ready to give back. We're here to help.</Title>
        <div className="my-10 flex justify-center">
          <Button
            whileHover={{ scale: 1.1 }}
            href="/login"
            style={{ color: "white" }}
          >
            Create a Scholarship
          </Button>
        </div>
      </Section>

      <BenefitsSection>
        <BenefitItem whileHover={{ scale: 1.05 }}>
          <FaCheckCircle />
          <p>100% tax-deductible for you and all contributors</p>
        </BenefitItem>
        <BenefitItem whileHover={{ scale: 1.05 }}>
          <FaCheckCircle />
          <p>100% goes directly to deserving recipients</p>
        </BenefitItem>
      </BenefitsSection>

      <ImageSection>
        <img src={PaS2} alt="Define and fund your award" />
        <TextBox>
          <h2>1. Define and fund your Scholarship.</h2>
          <p>
            Creating a Scholarship on funding-success is easy. You tell us what you want.{' '}
            <strong>We do the heavy lifting.</strong>
          </p>
        </TextBox>
      </ImageSection>

      <ImageSection>
        <img src={PaS1} alt="We'll create a shortlist of the best applications." />
        <TextBox>
          <h2>2. We'll create a shortlist of the best applications.</h2>
          <p>
            Based on the selection criteria,{' '}
            <strong>our GenAI algorithm review hundreds of applications</strong> to narrow
            down a list of finalists.
          </p>
        </TextBox>
      </ImageSection>

      <ImageSection>
        <img src={PaS3} alt="Work with our donor team to select deserving winners." />
        <TextBox>
          <h2>3. Start swiping to select deserving students.</h2>
          <p>
            Once students are selected,{' '}
            <strong>100% of your tax-deductible gift</strong> will go directly
            to the recipients.
          </p>
        </TextBox>
      </ImageSection>

      <ContactSection>
        <h2 style={{ color: "#003366" }}>Get started by creating your donor profile.</h2>
        <Button whileHover={{ scale: 1.1 }} style={{ color: "white" }} onClick={() => navigate('/login')}>
          Create Your Profile
        </Button>
        <a href="mailto:">
          <FaEnvelope /> Contact our Donor Team
        </a>
        <a href="/donors/faq/">
          <FaQuestionCircle /> Read our FAQs
        </a>
      </ContactSection>
    </>
  );
};

export default GiveBackSection;
