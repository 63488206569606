import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUser } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import FundingSuccessLogoSVG from '../component/SVG/FundingSuccessLogoSVG';
import AnonymousPersonal from '../component/signupInformation/donor/AnonymousPersonal';
import People from '../component/signupInformation/donor/Person';
import DonorFooter from '../component/signupInformation/donor/DonorFooter';

// Styled components for layout and design
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 20px;
  background-color: white;
  overflow-y: scroll;

  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
`;

const Logo = styled(motion.div)`
  cursor: pointer;

  svg {
    width: 150px;  /* Adjusted the size of the logo */
    height: auto;
  }
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  color: #003366;

  .user-icon {
    font-size: 1.5em;
    margin-right: 10px;
  }

  .account-text {
    font-size: 1em;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const UserIcon = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const BottomPadding = styled.div`
  padding-bottom: 90px;
`;



const DonorsLogin = () => {
  const navigate = useNavigate();

  // useState to track the current step in the sign-up process
  const [signupStep, setSignupStep] = useState(0);

  // useState to track whether the user is anonymous or not
  const [anonymous, setAnonymous] = useState(false);

  // Options for the "People" component
  const options = [
    "Individual",
    "Family",
    "Public Figure",
    "Non-profit or Philanthropic Organization",
    "Company",
    "Other",
  ];

  const goToHome = () => {
    navigate('/');
  };

  const goToLogin = () => {
    navigate('/login'); // Navigate to login page (adjust route as needed)
  };

  // Handle the sign-up click from AnonymousPersonal
  const handleSignUp = (isAnonymous) => {
    setSignupStep(1);  // Move to the next step
    setAnonymous(isAnonymous); // Set anonymous status based on the sign-up type
  };

  return (
    <Container>
      <TopBar>
        <Logo whileHover={{ scale: 1.1 }} onClick={goToHome}>
          <FundingSuccessLogoSVG />
        </Logo>

        <UserSection onClick={goToLogin}>
          <UserIcon>
            <FaUser className="user-icon" />
          </UserIcon>
          <span className="account-text">Already have an account?</span>
        </UserSection>
      </TopBar>

      {signupStep === 0 ? (
        <AnonymousPersonal onSignUp={handleSignUp} />
      ) : (
        // Once signed up, display the next component and pass options down
        <BottomPadding>
         <People anonymous={anonymous} options={options} />
        </BottomPadding>
      )}

      {signupStep === 1 && <DonorFooter/>}
     
    </Container>
  );
};

export default DonorsLogin;
