import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';


// Styled components
const SectionContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SectionItem = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const AddItemButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #577483;
  }

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled(FaTrash)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #577483;

  &:hover {
    color: #003366;
  }
`;

const AddImageButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;

const AddImageIcon = styled(FaPlus)`
  margin-right: 5px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const ProjectImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const ImageDeleteButton = styled(FaTrash)`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 2px;

  &:hover {
    color: #ff0000;
  }
`;

const ProjectsComponent = ({ projects, setProjects }) => {
  const [newProject, setNewProject] = useState(null);

  const handleChange = (index, field, value) => {
    const updatedProjects = [...projects];
    updatedProjects[index][field] = value;
    setProjects(updatedProjects);
  };

  const handleAddItem = (newItem) => {
    if (newItem) {
      setProjects((prev) => [...prev, newItem]);
      setNewProject(null);
    }
  };

  const handleDeleteItem = (index) => {
    setProjects((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddImage = async (index, file) => {
    if (file) {
      const storage = getStorage();
      const storageRef = ref(storage, `projects/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      const updatedProjects = [...projects];
      updatedProjects[index].pictures.push({ name: file.name, url: downloadURL });
      setProjects(updatedProjects);
    }
  };

  const handleDeleteImage = (projectIndex, imageIndex) => {
    const updatedProjects = [...projects];
    updatedProjects[projectIndex].pictures.splice(imageIndex, 1);
    setProjects(updatedProjects);
  };

  return (
    <SectionContainer>
      {projects.map((project, index) => (
        <SectionItem key={index}>
          <DeleteButton onClick={() => handleDeleteItem(index)} />
          <InputLabel>
            Name
            <Input
              type="text"
              value={project.name}
              onChange={(e) => handleChange(index, 'name', e.target.value)}
            />
          </InputLabel>
          <InputLabel>
            Description
            <TextArea
              type="text"
              value={project.description}
              onChange={(e) => handleChange(index, 'description', e.target.value)}
            />
          </InputLabel>
          <InputLabel>
            Who Benefits
            <TextArea
              type="text"
              value={project.whoBenefits}
              onChange={(e) => handleChange(index, 'whoBenefits', e.target.value)}
            />
          </InputLabel>
          {project.pictures && project.pictures.length > 0 && (
            <ImageContainer>
              {project.pictures.map((picture, picIndex) => (
                <div key={picIndex} style={{ position: 'relative' }}>
                  <ProjectImage src={picture.url} alt={`Project ${index} Image ${picIndex}`} />
                  <ImageDeleteButton onClick={() => handleDeleteImage(index, picIndex)} />
                </div>
              ))}
            </ImageContainer>
          )}
          <InputLabel>
            Add Image
            <AddImageButton onClick={() => document.getElementById(`fileInput${index}`).click()}>
              <AddImageIcon />
              Add Image
            </AddImageButton>
            <input
              id={`fileInput${index}`}
              type="file"
              accept="image/*"
              onChange={(e) => handleAddImage(index, e.target.files[0])}
              style={{ display: 'none' }}
            />
          </InputLabel>
        </SectionItem>
      ))}
      {newProject === null ? (
        <AddItemButton
          onClick={() =>
            setNewProject({
              name: '',
              description: '',
              whoBenefits: '',
              pictures: [],
            })
          }
        >
          Add Project
        </AddItemButton>
      ) : (
        <SectionItem>
          <InputLabel>
            Name
            <Input
              type="text"
              value={newProject.name}
              onChange={(e) =>
                setNewProject({ ...newProject, name: e.target.value })
              }
            />
          </InputLabel>
          <InputLabel>
            Description
            <TextArea
              type="text"
              value={newProject.description}
              onChange={(e) =>
                setNewProject({ ...newProject, description: e.target.value })
              }
            />
          </InputLabel>
          <InputLabel>
            Who Benefits
            <TextArea
              type="text"
              value={newProject.whoBenefits}
              onChange={(e) =>
                setNewProject({ ...newProject, whoBenefits: e.target.value })
              }
            />
          </InputLabel>
          <AddItemButton onClick={() => handleAddItem(newProject)}>
            Add Project
          </AddItemButton>
        </SectionItem>
      )}
    </SectionContainer>
  );
};

export default ProjectsComponent;
