import React from 'react';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import Coin from '../assets/images/money-coin.webp';
import { useNavigate } from 'react-router-dom';

// Styled Components
const ContentWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Container = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 40px 0;
  position: relative;
  z-index: 10;
`;

const Heading = styled.h1`
  margin-top: 8px;
  margin-bottom: 12px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  color: #333;
  @media (min-width: 768px) {
    font-size: 3.5rem;
  }
`;

const SubHeading = styled.p`
  margin: 0 auto 24px;
  text-align: center;
  font-size: 1.125rem;
  color: #555;
  max-width: 720px;
  line-height: 1.6;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ScholarshipContainer = styled.div`
  margin: 24px 0;
  padding: 24px;
  background: white;
  max-width: 580px;
  border-radius: 24px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
`;

const InfoText = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  border-radius: 16px;
  @media (min-width: 768px) {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #003366;
  margin: 0 12px;
`;

const FeatureIcon = styled(FaCheck)`
  margin-right: 16px;
  color: white;
  background-color: #003366;
  border-radius: 50%;
  padding: 8px;
  width: 24px;
  height: 24px;
`;

const FeatureText = styled.div`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
`;

const Button = styled.a`
  display: inline-block;
  background-color: #003366;
  color: white;
  padding: 12px 24px;
  border-radius: 24px;
  text-align: center;
  font-size: 1.25rem;
  margin-top: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: #003369;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  min-height: 450px;
  margin-top: 48px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const FooterTextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  text-align: center;
`;

const FooterHeading = styled.h2`
  font-size: 1.75rem;
  font-weight: 900;
  padding-bottom: 16px;
  color: #333;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const FooterParagraph = styled.p`
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;
const MaximizeImpact = () => {
  const navigate = useNavigate();

  return (
    <ContentWrapper>
      <Container>
        <div>
          <Heading>Maximize Your Impact</Heading>
          <SubHeading>
            100% of your donation will go directly to the educational costs of the selected students. With our transparent platform, you can confidently track the impact of your contribution.
          </SubHeading>
        </div>
        <ScholarshipContainer>
          <InfoText>Free for applicants, free for donors</InfoText>
          <Feature>
            <FeatureIcon />
            <FeatureText>Tailored GenAI student matching based on shared interests</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Verified student performance reviews with ROI insights</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Detailed reports to inform your funding decisions</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Direct engagement with finalists based on your scholarship criteria</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Featured communications to relevant schools for more reach</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Simple process for accepting third-party donations</FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon />
            <FeatureText>Promotion via high-engagement social channels to maximize visibility</FeatureText>
          </Feature>
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Button onClick={() => navigate('/beta')}>Create a Scholarship Now</Button>
          </div>
        </ScholarshipContainer>
        <ImageWrapper>
          <Image alt="Graphics" src={Coin} />
          <FooterTextContainer>
            <FooterHeading>We ensure 100% of your donation goes directly to education.</FooterHeading>
            <FooterParagraph>
              <span>Funding Success</span> is completely free for both students and donors. We generate revenue through partnerships with vetted companies, which allows us to maintain a no-fee structure for donors and students.
            </FooterParagraph>
            <FooterParagraph>
              Our platform connects high-achieving students with donors who want to invest in the leaders of tomorrow, ensuring that 100% of your donation goes towards the educational success of deserving students.
            </FooterParagraph>
          </FooterTextContainer>
        </ImageWrapper>
      </Container>
    </ContentWrapper>
  );
};

export default MaximizeImpact;
