import React from 'react';
import styled from 'styled-components';

const User = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#e7f5fe' : 'white')};

  &:hover {
    background-color: #f1f1f1;
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
`;

const ChatUser = ({ user, isSelected, onClick }) => {
  const randomImage = "https://via.placeholder.com/40";

  return (
    <User isSelected={isSelected} onClick={onClick}>
      <ProfileImage 
        src={user.profilePictureURL || randomImage} 
        alt="Profile"
      />
      {user.name}
    </User>
  );
};

export default ChatUser;