import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaRegStar, FaUserShield, FaDollarSign } from 'react-icons/fa';
import FundingSuccessLogoSVG from '../component/SVG/FundingSuccessLogoSVG';
import { motion } from 'framer-motion'; // Importing Framer Motion
import Statistics from './Statisitics';

// Keyframes for shimmering effect on button hover
const shimmer = keyframes`
  0% {
    background-position: -400% 0;
  }
  100% {
    background-position: 400% 0;
  }
`;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  background-color: white;
`;

const Navbar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  font-size: 0.9em;
  background-color: white;

  a {
    color: #03377e;
    text-decoration: none;
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      text-decoration: none;
      color: #022a5f;
    }

    svg {
      margin-left: 5px;
    }
  }
`;

const Dropdown = styled.div`
  position: relative;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 150px;
`;

const DropdownLink = styled(motion.a)`
  display: block;
  color: #03377e;
  text-decoration: none;
  padding: 8px 0;

  &:hover {
    color: #022a5f;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1.2em;
  margin-bottom: 50px;
  color: #577483;
  
  span {
    color: #03377e;
  }
`;

const Button = styled(motion.button)`
  padding: 10px 20px;
  font-size: 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #03377e;
  color: #ffffff;

  &:hover {
    background-color: #022a5f;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: white;
  font-size: 1em;
  color: #577483;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;

  svg {
    font-size: 2em;
    margin-bottom: 10px;
  }
`;

const MotionNavLink = styled(motion.a)` 
  display: flex;
  align-items: center;
  font-size: 1em;
  padding: 0 10px;
  color: #03377e;
  text-decoration: none;

  &:hover {
    color: #022a5f;
  }

  svg {
    margin-left: 5px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
`;

const LandingPageV2 = () => {
  const navigate = useNavigate();
  const [modalContent, setModalContent] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown state
// Suggested code may be subject to a license. Learn more: ~LicenseLog:73775272.
  const [showDropdownDonor, setShowDropdownDonor] = useState(false); // Dropdown state


  const start = () => {
    navigate("/beta");
  };

  const openModal = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <Container>
      <Navbar>
        <MotionNavLink
          as="div"
          whileHover={{ scale: 1.1 }}
          onClick={() => openModal('Students')}
        >
          For Students <FaChevronDown />
        </MotionNavLink>
        <Dropdown
          onMouseEnter={() => setShowDropdownDonor(true)}
          onMouseLeave={() => setShowDropdownDonor(false)}
        >
          <MotionNavLink
            as="div"
            whileHover={{ scale: 1.1 }}
          >
            For Donors <FaChevronDown />
          </MotionNavLink>
          <DropdownContent show={showDropdownDonor}>
            <DropdownLink
              href="/scholarship-start"
              whileHover={{ scale: 1.05 }}
            >
              Login
            </DropdownLink>
            <DropdownLink
              href="/how-to"
              whileHover={{ scale: 1.05 }}
            >
              How it works
            </DropdownLink>
          </DropdownContent>
        </Dropdown>
        {/* Dropdown for Company */}
        <Dropdown
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <MotionNavLink
            as="div"
            whileHover={{ scale: 1.1 }}
          >
            Company <FaChevronDown />
          </MotionNavLink>
          <DropdownContent show={showDropdown}>
            <DropdownLink
              href="/about"
              whileHover={{ scale: 1.05 }}
            >
              About Us
            </DropdownLink>
            <DropdownLink
              href="/how-to"
              whileHover={{ scale: 1.05 }}
            >
              How it works
            </DropdownLink>
            <DropdownLink
              href="/career"
              whileHover={{ scale: 1.05 }}
            >
              Career
            </DropdownLink>
           
          </DropdownContent>
        </Dropdown>
      </Navbar>

      <TitleContainer>
        <FundingSuccessLogoSVG />
        <Subtitle>
          <span>Donors</span>, Funding Success is like a portfolio manager for donors, using AI to match students and track academic success for lasting relationships.
        </Subtitle>
        <Subtitle>
          <span>Students</span>, access funds available to pay your student loans by showing and demonstrating your success.
        </Subtitle>
        <Button
          whileHover={{ scale: 1.1 }}
          onClick={start}
        >
          Start Now
        </Button>
      </TitleContainer>

      <Footer>
        <Icon>
          <FaDollarSign />
          <p>100% free</p>
        </Icon>
        <Icon>
          <FaUserShield />
          <p>Trusted by Donors and Students</p>
        </Icon>
        <Icon>
          <FaRegStar />
          <p>5-star reviews</p>
        </Icon>
      </Footer>

      {modalContent && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            {modalContent === 'Students' ? (
              <>
                <Subtitle>
                  <span>Students</span>, access funds available to pay your student loans by showing and demonstrating your success.
                </Subtitle>
                <Button onClick={start}>Start Now</Button>
              </>
            ) : (
              <>
                <Subtitle>
                  <span>Donor</span>, support and invest in students who have consistently demonstrated exceptional talent and expertise in their respective disciplines.
                </Subtitle>
                <Button onClick={start}>Start Now</Button>
              </>
            )}
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default LandingPageV2;
