import React from 'react';
import styled from 'styled-components';
import { LuShare } from 'react-icons/lu';
import { FaLinkedin } from 'react-icons/fa';
import { animated } from '@react-spring/web';

const Overlay = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: #f4f4f4;
  padding: 30px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #333;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: #f4f4f4;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
`;

const Header = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
`;

const ParentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Stack items on smaller screens */
    gap: 20px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: center;
  gap: 10px;
`;




const Name = styled.h1`
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #003366;
`;

const ContactInfo = styled.p`
  font-size: 1.2em;
  color: #666;
  margin: 5px 0;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #003366;
  border-bottom: 2px solid #003366;
  padding-bottom: 5px;
  margin-bottom: 15px;
`;

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 10px;
  margin-bottom: 10px;
`;

const InfoLabel = styled.span`
  font-weight: bold;
  color: #333;
`;

const InfoValue = styled.span`
  color: #333;
`;

const ProjectContainer = styled.div`
  margin-bottom: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row; /* Default layout for larger screens */
  
  @media (max-width: 768px) {
    flex-direction: column; /* Stack image and details on mobile screens */
    text-align: center; /* Center text for better mobile readability */
  }
`;

const ProjectImage = styled.img`
  width: 200px;
  height: auto;
  border-radius: 8px;
  margin-left: 20px;
  
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 15px; /* Add spacing between image and text */
    width: 100%; /* Make image full width on mobile for better visibility */
  }
`;

const ProjectDetails = styled.div`
  flex: 1;
`;

const ProjectTitle = styled.h3`
  text-align: left;
  font-size: 1.5em;
  color: #003366;
  margin-bottom: 10px;
`;

const ProjectDescription = styled.p`
  color: #555;
  line-height: 1.6;
`;



const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  position: absolute;
  top: 20px;
  right: 55px;
`;

const Resume = ({ user, closeResume }) => {
  const { profilePictureURL, name, email, mobile, educationData, loanData, projectData, personalData } = user;
  const personalInfo = personalData ? personalData[0] : {};

  const formatDate = (date) => {
    const [day, month, year] = date.split('/');
    return `${month}/${day}/${year}`;
  };

  return (
    <Overlay>
      <CloseButton onClick={closeResume}>×</CloseButton>
      
      <Header>
       
    <ParentContainer>
    <div style={{ textAlign: 'right'}}>
      <ProfileImage src={profilePictureURL || 'https://via.placeholder.com/150'} alt="Profile" />
    </div>
  <InfoContainer>
    
    <div style={{ textAlign: 'left'}}>
      <Name>{name || 'No Name Available'}</Name>
      {email && <ContactInfo>Email: {email}</ContactInfo>}
      {mobile && <ContactInfo>Phone: {mobile}</ContactInfo>}
    </div>
  </InfoContainer>
  </ParentContainer>

        {personalInfo.linkedin && (
          <ShareContainer>
            <FaLinkedin
              style={{ fontSize: 'x-large', cursor: 'pointer', color: '#003366' }}
              onClick={() => window.open(personalInfo.linkedin, '_blank')}
            />
           
          </ShareContainer>
        )}
      </Header>

      {educationData && educationData.length > 0 && (
        <Section>
          <SectionTitle>Education</SectionTitle>
          {educationData.map((education, index) => (
            <InfoRow key={index}>
              <div style={{ textAlign: 'right'}}>
                <InfoLabel>University:</InfoLabel> <InfoValue>{education.university}</InfoValue>
              </div>
              <div style={{ textAlign: 'left'}}>
              <InfoLabel>End Date:</InfoLabel> <InfoValue>{formatDate(education.endDate)}</InfoValue>
              </div>
              <div style={{ textAlign: 'right'}}>
              <InfoLabel>Major:</InfoLabel> <InfoValue>{education.major}</InfoValue>
              </div>
              <div style={{ textAlign: 'left'}}>
                <InfoLabel>Start Date:</InfoLabel> <InfoValue>{formatDate(education.startDate)}</InfoValue>
              </div>
              <div style={{ textAlign: 'right'}}>
              <InfoLabel>GPA:</InfoLabel> <InfoValue>{education.gpa}</InfoValue>
              </div>
            </InfoRow>
          ))}
        </Section>
      )}

      {loanData && (
        <Section>
          <SectionTitle>Loan Information</SectionTitle>
          <InfoRow>
            <div style={{ textAlign: 'right'}}> 
              <InfoLabel>Total Loan Amount:</InfoLabel>
              <InfoValue>${loanData.student_total_loan_amount} USD</InfoValue>
            </div>
            <div style={{ textAlign: 'left'}}>
              <InfoLabel>Fund Request Reason:</InfoLabel>
              <InfoValue>{loanData.fund_request_reason}</InfoValue>
            </div>
          </InfoRow>
        </Section>
      )}

      {projectData && projectData.length > 0 && (
        <Section>
          <SectionTitle>Accomplishments</SectionTitle>
          {projectData.map((project, index) => (
            <ProjectContainer key={index}>
              <ProjectDetails>
                <ProjectTitle style={{ textAlign: 'left'}}>{project.name}</ProjectTitle>
                <ProjectDescription style={{ textAlign: 'left'}}>{project.description}</ProjectDescription>
                <ProjectDescription  style={{ textAlign: 'left'}}><b>Who Benefits:</b> {project.whoBenefits}</ProjectDescription>
              </ProjectDetails>
              {project.pictures && project.pictures.length > 0 && (
                <ProjectImage src={project.pictures[0].url} alt={project.pictures[0].name} />
              )}
            </ProjectContainer>
          ))}
        </Section>
      )}
    </Overlay>
  );
};

export default Resume;
