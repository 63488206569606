import React, { useState } from 'react';
import styled from 'styled-components';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase'; // Ensure this path is correct based on your project structure
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FundingSuccessLogoSVGNoAnimation from '../component/SVG/FundingSuccessLogoSVGNoAnimation';

const Page = styled.div`
  background: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100%;
  justify-content: space-between;
`;

const ResetContainer = styled.div`
  background: #fff;
  padding: 32px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
`;

const Subtitle = styled.p`
  color: #666;
  text-align: center;
  margin-bottom: 24px;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
  background-color: #003366;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Link = styled.a`
  display: block;
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('A password reset link has been sent to your email address.', {
        position: 'top-center'
      });
    } catch (error) {
      console.error('Error sending password reset email:', error);
      toast.error('Failed to send password reset email. Please try again.', {
        position: 'top-center'
      });
    }
  };

  return (
    <Page>
      <ToastContainer />
      <LogoContainer>
        <FundingSuccessLogoSVGNoAnimation />
      </LogoContainer>
      <ResetContainer>
        <Title>Reset Password</Title>
        <Subtitle>Enter your email to reset your password</Subtitle>
        <form onSubmit={handleResetPassword}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit">Send Reset Link</Button>
        </form>
        <Link onClick={() => navigate("/login")}>Back to Sign In</Link>
      </ResetContainer>
    </Page>
  );
};

export default ForgotPassword;
