import * as React from "react"
import { motion } from "framer-motion"

const BeaverLogoPencilAnimation = (props) => (
  <svg
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={250} height={250} fill="FFFFFF" />
    <g id="Character0002" clipPath="url(#clip0_0_1)">
      <g id="Left_and_Right_Foot">
        <g id="Right_Foot">
          <g id="Group">
            <g id="Group_2">
              <g id="Group_3">
                <path
                  id="Vector"
                  d="M185.9 223.75L185.95 223.7C184.317 222.767 182.1 222.267 179.3 222.2L175.55 222.35C174.35 222.183 173.017 222.1 171.55 222.1C168.617 222.067 166.5 222.483 165.2 223.35C163.433 224.55 161.967 226.017 160.8 227.75C159.433 229.783 158.783 231.867 158.85 234C158.95 236.2 159.317 237.533 159.95 238C160.45 238.367 161.867 238.583 164.2 238.65C167.3 238.783 169.133 237.867 169.7 235.9C169.833 235.5 170.317 235.3 171.15 235.3C171.55 235.3 171.933 235.333 172.3 235.4L173.25 237.15C174.217 238.383 175.583 239.067 177.35 239.2C181.15 239.533 183.133 238.45 183.3 235.95C183.4 235.017 183.867 235.017 184.7 235.95C185.1 236.417 185.5 236.983 185.9 237.65L187.25 238.6C188.417 239.2 189.7 239.35 191.1 239.05C192.8 238.717 193.933 237.683 194.5 235.95C195.1 234.183 194.833 232.583 193.7 231.15C192.767 229.917 191.183 228.317 188.95 226.35L185.9 223.75Z"
                  fill="#754C29"
                />
              </g>
            </g>
          </g>
          <g id="Group_4">
            <g id="Group_5">
              <g id="Group_6">
                <path
                  id="Vector_2"
                  d="M186.6 232.85C185.833 233.417 185.45 234.117 185.45 234.95C185.45 235.783 185.833 236.5 186.6 237.1C187.367 237.7 188.283 238 189.35 238C190.417 238 191.333 237.7 192.1 237.1C192.867 236.5 193.25 235.783 193.25 234.95C193.25 234.117 192.867 233.417 192.1 232.85C191.333 232.25 190.417 231.95 189.35 231.95C188.283 231.95 187.367 232.25 186.6 232.85Z"
                  fill="#A97C50"
                />
              </g>
            </g>
          </g>
          <g id="Group_7">
            <g id="Group_8">
              <g id="Group_9">
                <path
                  id="Vector_3"
                  d="M178.2 231.95C177.133 231.95 176.217 232.25 175.45 232.85C174.683 233.417 174.3 234.117 174.3 234.95C174.3 235.783 174.683 236.5 175.45 237.1C176.217 237.7 177.133 238 178.2 238C179.267 238 180.167 237.7 180.9 237.1C181.667 236.5 182.05 235.783 182.05 234.95C182.05 234.117 181.667 233.417 180.9 232.85C180.167 232.25 179.267 231.95 178.2 231.95Z"
                  fill="#A97C50"
                />
              </g>
            </g>
          </g>
          <g id="Group_10">
            <g id="Group_11">
              <g id="Group_12">
                <path
                  id="Vector_4"
                  d="M164.1 232.05C163.033 232.05 162.117 232.35 161.35 232.95C160.617 233.517 160.25 234.217 160.25 235.05C160.25 235.917 160.617 236.633 161.35 237.2C162.117 237.8 163.033 238.1 164.1 238.1C165.167 238.1 166.083 237.8 166.85 237.2C167.617 236.6 168 235.883 168 235.05C168 234.217 167.617 233.517 166.85 232.95C166.083 232.35 165.167 232.05 164.1 232.05Z"
                  fill="#A97C50"
                />
              </g>
            </g>
          </g>
          <g id="Group_13">
            <g id="Group_14">
              <g id="Group_15">
                <path
                  id="Vector_5"
                  d="M178.05 234.75C178.783 234.75 179.517 234.733 180.25 234.7C180.083 234.2 179.533 233.95 178.6 233.95C177.033 233.95 176.15 234.167 175.95 234.6C175.883 234.733 176.583 234.783 178.05 234.75Z"
                  fill="#DDD3C0"
                />
              </g>
            </g>
          </g>
          <g id="Group_16">
            <g id="Group_17">
              <g id="Group_18">
                <path
                  id="Vector_6"
                  d="M161.9 234.6C161.833 234.8 163.283 234.833 166.25 234.7C166.05 234.2 165.5 233.95 164.6 233.95C163 233.95 162.1 234.167 161.9 234.6Z"
                  fill="#DDD3C0"
                />
              </g>
            </g>
          </g>
          <g id="Group_19">
            <g id="Group_20">
              <g id="Group_21">
                <path
                  id="Vector_7"
                  d="M192.05 234.7C191.883 234.233 191.333 234 190.4 234C188.733 234 187.85 234.2 187.75 234.6C187.65 234.833 189.083 234.867 192.05 234.7Z"
                  fill="#DDD3C0"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Left_Foot">
          <g id="Group_22">
            <g id="Group_23">
              <g id="Group_24">
                <path
                  id="Vector_8"
                  d="M95.1 227.65C93.8 225.717 92.15 224.083 90.15 222.75C88.6833 221.783 86.2833 221.3 82.95 221.3L78.4 221.6C77.1666 221.433 75.75 221.383 74.15 221.45C70.95 221.55 68.4333 222.117 66.6 223.15L63.15 226.05C60.65 228.283 58.8666 230.083 57.8 231.45C56.5 233.083 56.1833 234.883 56.85 236.85C57.4833 238.783 58.7833 239.933 60.75 240.3C62.35 240.6 63.8 240.433 65.1 239.8C65.7666 239.5 66.2666 239.15 66.6 238.75C67.0666 238.017 67.5333 237.383 68 236.85C68.9666 235.817 69.4833 235.817 69.55 236.85C69.7833 239.617 72.05 240.833 76.35 240.5C78.35 240.333 79.9 239.583 81 238.25L82.05 236.25L83.4 236.1C84.3333 236.133 84.8666 236.383 85 236.85C85.3 237.75 85.7666 238.433 86.4 238.9C87.4666 239.667 89.0833 240 91.25 239.9C93.95 239.833 95.5666 239.6 96.1 239.2C96.8333 238.667 97.25 237.167 97.35 234.7C97.4166 232.3 96.6666 229.95 95.1 227.65Z"
                  fill="#754C29"
                />
              </g>
            </g>
          </g>
          <g id="Group_25">
            <g id="Group_26">
              <g id="Group_27">
                <path
                  id="Vector_9"
                  d="M62.7 232.45C61.4667 232.45 60.4334 232.783 59.6 233.45C58.7334 234.083 58.3 234.867 58.3 235.8C58.3 236.733 58.7334 237.517 59.6 238.15C60.4334 238.817 61.4667 239.15 62.7 239.15C63.9334 239.15 64.9834 238.817 65.85 238.15C66.7167 237.517 67.15 236.733 67.15 235.8C67.15 234.867 66.7167 234.083 65.85 233.45C64.9834 232.783 63.9334 232.45 62.7 232.45Z"
                  fill="#A97C50"
                />
              </g>
            </g>
          </g>
          <g id="Group_28">
            <g id="Group_29">
              <g id="Group_30">
                <path
                  id="Vector_10"
                  d="M75.4 232.4C74.1667 232.4 73.1333 232.733 72.3 233.4C71.4333 234.067 71 234.867 71 235.8C71 236.733 71.4333 237.517 72.3 238.15C73.1333 238.817 74.1667 239.15 75.4 239.15C76.6333 239.15 77.6833 238.817 78.55 238.15C79.4167 237.517 79.85 236.733 79.85 235.8C79.85 234.867 79.4167 234.067 78.55 233.4C77.6833 232.733 76.6333 232.4 75.4 232.4Z"
                  fill="#A97C50"
                />
              </g>
            </g>
          </g>
          <g id="Group_31">
            <g id="Group_32">
              <g id="Group_33">
                <path
                  id="Vector_11"
                  d="M91.4 232.55C90.1667 232.55 89.1333 232.883 88.3 233.55C87.4333 234.183 87 234.967 87 235.9C87 236.833 87.4333 237.617 88.3 238.25C89.1333 238.917 90.1667 239.25 91.4 239.25C92.6333 239.25 93.6667 238.917 94.5 238.25C95.3667 237.617 95.8 236.833 95.8 235.9C95.8 234.967 95.3667 234.183 94.5 233.55C93.6667 232.883 92.6333 232.55 91.4 232.55Z"
                  fill="#A97C50"
                />
              </g>
            </g>
          </g>
          <g id="Group_34">
            <g id="Group_35">
              <g id="Group_36">
                <path
                  id="Vector_12"
                  d="M73.05 235.45C76.4167 235.65 78.0501 235.617 77.9501 235.35C77.7834 234.883 76.7834 234.65 74.9501 234.65C74.2834 234.65 73.7667 234.783 73.4001 235.05C73.2334 235.183 73.1167 235.317 73.05 235.45Z"
                  fill="#DDD3C0"
                />
              </g>
            </g>
          </g>
          <g id="Group_37">
            <g id="Group_38">
              <g id="Group_39">
                <path
                  id="Vector_13"
                  d="M89 235.45C92.3667 235.65 93.9833 235.617 93.85 235.35C93.6833 234.883 92.6833 234.65 90.85 234.65C90.2167 234.65 89.7167 234.783 89.35 235.05C89.1833 235.183 89.0667 235.317 89 235.45Z"
                  fill="#DDD3C0"
                />
              </g>
            </g>
          </g>
          <g id="Group_40">
            <g id="Group_41">
              <g id="Group_42">
                <path
                  id="Vector_14"
                  d="M59.65 235.45C63.0167 235.65 64.65 235.617 64.55 235.35C64.3834 234.883 63.3667 234.65 61.5 234.65C60.8667 234.65 60.3667 234.783 60 235.05C59.8334 235.183 59.7167 235.317 59.65 235.45Z"
                  fill="#DDD3C0"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <motion.g id="TAIL"
        animate={{ y: [-5, 0, -5 ]}}
        transition={{
            times: [0,1],
            duration: 5,
            repeat: Infinity,
            type: "keyframes",
            ease: "easeInOut",
        }}
      >
        <g id="Layer_1">
          <g id="Group_43">
            <g id="Group_44">
              <g id="Group_45">
                <path
                  id="Vector_15"
                  d="M69.35 113.85C56.8833 113.85 46.25 118.817 37.45 128.75C28.65 138.65 24.25 150.6 24.25 164.6C24.25 178.633 28.65 190.6 37.45 200.5C46.25 210.4 56.8833 215.35 69.35 215.35C81.8167 215.35 92.45 210.4 101.25 200.5C110.05 190.6 114.45 178.633 114.45 164.6C114.45 150.6 110.05 138.65 101.25 128.75C92.4167 118.817 81.7833 113.85 69.35 113.85Z"
                  fill="url(#paint0_linear_0_1)"
                />
              </g>
            </g>
          </g>
        </g>
      </motion.g>
      <motion.g id="Full_character"
        animate={{ y: [-5, 0, -5 ]}}
        transition={{
            times: [0,1],
            duration: 5,
            repeat: Infinity,
            type: "keyframes",
            ease: "easeInOut",
        }}
      >
        <g id="Full_character_Layer">
          <g id="Ears">
            <g id="Left_Ear">
              <g id="Group_46">
                <g id="Group_47">
                  <g id="Group_48">
                    <path
                      id="Vector_16"
                      d="M78 53.3001C80.9 50.4334 81.8666 46.4667 80.9 41.4001C79.9666 36.3667 77.3666 31.7334 73.1 27.5001C68.8666 23.2667 64.2333 20.6667 59.2 19.7001C54.1666 18.7667 50.2 19.7334 47.3 22.6001C44.4 25.5001 43.4333 29.4667 44.4 34.5001C45.3666 39.5334 47.9666 44.1667 52.2 48.4001C56.4333 52.6667 61.0666 55.2667 66.1 56.2001C71.1333 57.1667 75.1 56.2001 78 53.3001Z"
                      fill="#603913"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_49">
                <g id="Group_50">
                  <g id="Group_51">
                    <path
                      id="Vector_17"
                      d="M68.5 55.1501C73.5667 56.0834 77.5334 55.1168 80.4 52.2501C83.3 49.3501 84.2667 45.3834 83.3 40.3501C82.3667 35.3168 79.7667 30.6834 75.5 26.4501C71.2667 22.2168 66.6334 19.6168 61.6 18.6501C56.5667 17.6834 52.6 18.6501 49.7 21.5501C46.8334 24.4168 45.8667 28.3834 46.8 33.4501C47.7667 38.4834 50.3667 43.1168 54.6 47.3501C58.8334 51.5834 63.4667 54.1834 68.5 55.1501Z"
                      fill="#8B5E3C"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="Right_Ear">
              <g id="Group_52">
                <g id="Group_53">
                  <g id="Group_54">
                    <path
                      id="Vector_18"
                      d="M158.7 21.8501C155.267 26.7834 153.517 31.8167 153.45 36.9501C153.383 42.0834 155.017 45.8167 158.35 48.1501C161.683 50.4834 165.767 50.7501 170.6 48.9501C175.367 47.1167 179.483 43.7501 182.95 38.8501C186.383 33.9501 188.133 28.9334 188.2 23.8001C188.233 18.6667 186.583 14.9334 183.25 12.6001C179.917 10.2667 175.85 10.0001 171.05 11.8001C166.283 13.6001 162.167 16.9501 158.7 21.8501Z"
                      fill="#603913"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_55">
                <g id="Group_56">
                  <g id="Group_57">
                    <path
                      id="Vector_19"
                      d="M160.1 24.2C156.667 29.1334 154.917 34.1667 154.85 39.3C154.783 44.4334 156.417 48.1667 159.75 50.5C163.083 52.8334 167.167 53.1 172 51.3C176.767 49.4667 180.883 46.1001 184.35 41.2001C187.817 36.3001 189.583 31.2834 189.65 26.1501C189.717 21.0501 188.067 17.3167 184.7 14.95C181.367 12.6167 177.3 12.35 172.5 14.15C167.7 15.95 163.567 19.3 160.1 24.2Z"
                      fill="#8B5E3C"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Bodyshape">
            <g id="Group_58">
              <g id="Group_59">
                <path
                  id="Vector_20"
                  d="M209.05 91.9C204.917 77.2667 198.417 64.1167 189.55 52.45C181.15 41.45 171.383 32.7 160.25 26.2C149.283 19.8 138.15 16.2833 126.85 15.65C117.05 15.0833 107.183 16.9833 97.25 21.35C87.0167 25.85 77.8333 32.5 69.7 41.3C61 50.7333 54.2333 61.8833 49.4 74.75C44.1333 88.8167 41.5 104.217 41.5 120.95C41.5 134.75 44.1333 152.1 49.4 173C54.4 192.9 59.7667 208.4 65.5 219.5C74.8667 226.033 84.2333 230.533 93.6 233C102.067 235.233 112.917 236.35 126.15 236.35C140.717 236.35 151.267 235.667 157.8 234.3C166.967 232.367 176.083 228.133 185.15 221.6C192.383 207.333 198.017 194.95 202.05 184.45C208.15 168.617 211.833 154.467 213.1 142C214.867 124.5 213.517 107.8 209.05 91.9Z"
                  fill="url(#paint1_radial_0_1)"
                />
              </g>
            </g>
          </g>
          <g id="Voca_Nariz">
            <g id="Layer_1_2">
              <g id="Group_60">
                <g id="Group_61">
                  <g id="Group_62">
                    <path
                      id="Vector_21"
                      d="M206.8 105.5C205.833 106.333 203.5 106.533 199.8 106.1C194.567 105.467 186.95 103.567 176.95 100.4C167.35 97.3333 159.167 93.7 152.4 89.5C152.7 90.3 152.833 91.05 152.8 91.75C152.7 93.85 150.383 96.45 145.85 99.55C141.483 102.55 138.65 103.85 137.35 103.45C133.817 102.417 131.4 101.55 130.1 100.85C125.9 98.6833 122.583 95.5 120.15 91.3L121.25 89.85C109.783 97.15 99.85 102.6 91.45 106.2C83.65 109.533 76.4 111.033 69.7 110.7C64.9 110.5 59.1666 109.133 52.5 106.6C51.1333 106.067 50.25 106.233 49.85 107.1C49.4833 107.933 49.6666 109.233 50.4 111C52.2 115.367 56.0333 119.917 61.9 124.65C69.2666 130.617 78.25 135.3 88.85 138.7C101.983 142.9 116.733 144.783 133.1 144.35C161.967 143.55 182.433 137.95 194.5 127.55C198.633 123.95 201.75 119.783 203.85 115.05C204.717 113.117 205.7 109.933 206.8 105.5Z"
                      fill="#F7F2EA"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_63">
                <g id="Group_64">
                  <g id="Group_65">
                    <path
                      id="Vector_22"
                      d="M139.8 104.7C139.333 101.9 139.1 99.7833 139.1 98.35H137.8C137.8 99.8166 138.033 101.967 138.5 104.8C138.967 107.667 139.2 109.8 139.2 111.2C139.2 112.633 138.967 114.783 138.5 117.65C138.033 120.517 137.8 122.65 137.8 124.05H139.1C139.1 122.617 139.333 120.45 139.8 117.55C140.267 114.65 140.5 112.483 140.5 111.05C140.5 109.65 140.267 107.533 139.8 104.7Z"
                      fill="#C2B59B"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_66">
                <g id="Group_67">
                  <g id="Group_68">
                    <path
                      id="Vector_23"
                      d="M138.5 105.15C138 102.017 137.75 99.6834 137.75 98.15H135.7C135.7 99.6834 135.95 102.033 136.45 105.2C136.917 108.333 137.15 110.683 137.15 112.25C137.15 113.583 136.917 115.533 136.45 118.1C135.95 120.7 135.7 122.65 135.7 123.95H137.75C137.75 122.617 138 120.65 138.5 118.05C139 115.45 139.25 113.483 139.25 112.15C139.25 110.617 139 108.283 138.5 105.15Z"
                      fill="url(#paint2_linear_0_1)"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_69">
                <g id="Group_70">
                  <g id="Group_71">
                    <path
                      id="Vector_24"
                      d="M137.35 103.45C138.683 103.817 141.517 102.517 145.85 99.55C150.383 96.45 152.7 93.85 152.8 91.75C152.833 91.05 152.7 90.3 152.4 89.5C151.8 87.9 150.633 86.4167 148.9 85.05C147.1 83.6167 144.95 82.5334 142.45 81.8C140.583 81.3 138.8 81 137.1 80.9C136.267 80.9 135.45 80.95 134.65 81.05C130.15 81.7834 125.683 84.7167 121.25 89.85L120.15 91.3C122.583 95.5 125.9 98.6834 130.1 100.85C131.4 101.55 133.817 102.417 137.35 103.45Z"
                      fill="#603913"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_72">
                <g id="Group_73">
                  <g id="Group_74">
                    <path
                      id="Vector_25"
                      d="M137.65 84.1C136.683 84.1 135.85 84.3166 135.15 84.75C134.45 85.15 134.1 85.65 134.1 86.25C134.1 86.85 134.45 87.3666 135.15 87.8C135.85 88.2 136.683 88.4 137.65 88.4C138.617 88.4 139.45 88.2 140.15 87.8C140.817 87.3666 141.15 86.85 141.15 86.25C141.15 85.65 140.817 85.15 140.15 84.75C139.45 84.3166 138.617 84.1 137.65 84.1Z"
                      fill="#775233"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_75">
                <g id="Group_76">
                  <g id="Group_77">
                    <path
                      id="Vector_26"
                      d="M152.3 120.65C151.433 120.75 150.267 121 148.8 121.4C146.567 122 144.883 122.433 143.75 122.7C141.817 123.167 140.1 123.45 138.6 123.55C135.867 123.783 132.183 123.483 127.55 122.65C123.317 121.85 120.35 121.483 118.65 121.55C118.383 121.55 118.25 121.683 118.25 121.95C118.217 122.15 118.267 122.367 118.4 122.6C118.733 123.133 119.233 123.4 119.9 123.4C121.133 123.367 122.583 123.483 124.25 123.75C124.383 123.75 125.8 124 128.5 124.5C132.567 125.267 136.083 125.583 139.05 125.45C140.617 125.417 142.3 125.2 144.1 124.8C144.8 124.667 146.4 124.267 148.9 123.6C150.933 123.033 152.117 122.717 152.45 122.65C153.883 122.35 155.067 122.4 156 122.8C156.233 122.9 156.45 122.917 156.65 122.85C156.883 122.75 156.983 122.567 156.95 122.3C156.95 121.833 156.55 121.417 155.75 121.05C154.783 120.65 153.633 120.517 152.3 120.65Z"
                      fill="#603913"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="EyeOutlines">
            <g id="EyeOutlinesLeft">
              <g id="Group_78">
                <g id="Group_79">
                  <path
                    id="Vector_27"
                    d="M113.7 82.7001C115.967 78.1001 116.183 73.5667 114.35 69.1001C112.517 64.6667 109.167 61.6167 104.3 59.9501C99.4333 58.2834 94.4667 58.4834 89.4 60.5501C84.3667 62.6167 80.7167 65.9667 78.45 70.6001C76.1833 75.2334 75.9667 79.7667 77.8 84.2001C79.6333 88.6334 82.9833 91.6834 87.85 93.3501C92.6833 95.0167 97.6333 94.8167 102.7 92.7501C107.733 90.6834 111.4 87.3334 113.7 82.7001Z"
                    fill="url(#paint3_linear_0_1)"
                  />
                </g>
              </g>
            </g>
            <g id="EyeOutlinesRight">
              <g id="Group_80">
                <g id="Group_81">
                  <path
                    id="Vector_28"
                    d="M151.5 65.2C149.5 69.1 149.35 73.2167 151.05 77.55C152.75 81.9167 155.8 85.2333 160.2 87.5C164.633 89.7667 169.1 90.3167 173.6 89.15C178.133 88.0167 181.4 85.5167 183.4 81.65C185.4 77.75 185.55 73.6333 183.85 69.3C182.15 64.9333 179.1 61.6167 174.7 59.35C170.3 57.0833 165.833 56.5333 161.3 57.7C156.767 58.8333 153.5 61.3333 151.5 65.2Z"
                    fill="url(#paint4_linear_0_1)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Face_Shadows">
            <g id="Group_82">
              <g id="Group_83">
                <g id="Group_84">
                  <path
                    id="Vector_29"
                    d="M204.7 105C204.967 104.033 204.35 102.733 202.85 101.1C201.483 99.5668 200.333 98.6668 199.4 98.4001L188.5 95.6501C187.8 95.4834 186.9 95.6668 185.8 96.2001C184.367 97.0001 183.15 97.6334 182.15 98.1001L180.35 102.25C181.783 104.45 183.6 105.817 185.8 106.35L196.7 109.1C197.967 109.433 199.3 109.567 200.7 109.5C202.633 109.4 203.717 108.883 203.95 107.95L204.7 105Z"
                    fill="#FCC86B"
                  />
                </g>
              </g>
            </g>
            <g id="Group_85">
              <g id="Group_86">
                <g id="Group_87">
                  <path
                    id="Vector_30"
                    d="M62.2 101.2C61.2667 101.333 60.25 101.867 59.15 102.8C58.4833 103.367 57.3 104.45 55.6 106.05L55.25 110.65C57.9167 112.017 60.75 112.5 63.75 112.1L78.25 110.05C79.95 109.817 81.65 109.367 83.35 108.7C85.65 107.833 86.7333 106.933 86.6 106L86.15 103C86.0167 102 84.6833 101.05 82.15 100.15C79.7833 99.3167 77.9667 98.9834 76.7 99.1501L62.2 101.2Z"
                    fill="#FCC86B"
                  />
                </g>
              </g>
            </g>
            <g id="Group_88">
              <g id="Group_89">
                <g id="Group_90">
                  <path
                    id="Vector_31"
                    d="M193.1 102.1C193.567 101.7 193.683 101.267 193.45 100.8L193.1 102.1Z"
                    fill="#F7F2EA"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Belly">
            <g id="Group_91">
              <g id="Group_92">
                <g id="Group_93">
                  <path
                    id="Vector_32"
                    d="M104.854 169.041C102.854 171.941 100.037 177.625 96.4042 186.091C92.4708 195.158 90.1375 201.825 89.4042 206.091C89.0708 208.091 88.6708 210.025 88.2042 211.891C87.9708 213.091 87.9375 214.275 88.1042 215.441C88.4042 217.641 92.7542 219.991 101.154 222.491C106.454 224.058 112.954 225.558 120.654 226.991C124.688 227.758 130.171 227.791 137.104 227.091C140.504 226.725 145.254 226.025 151.354 224.991C152.421 224.825 154.438 224.591 157.404 224.291C160.238 223.925 162.621 223.408 164.554 222.741C170.287 220.741 173.537 216.825 174.304 210.991C175.071 205.258 175.087 199.258 174.354 192.991C173.187 185.225 172.288 178.675 171.654 173.341C170.988 167.708 168.954 163.525 165.554 160.791C163.387 159.058 161.188 158.141 158.954 158.041C158.488 158.041 157.821 157.941 156.954 157.741C154.354 157.075 150.521 156.575 145.454 156.241C138.754 155.808 132.338 155.958 126.204 156.691C117.471 157.791 111.804 159.508 109.204 161.841C107.638 163.275 106.187 165.675 104.854 169.041Z"
                    fill="#DDD3C3"
                  />
                </g>
              </g>
            </g>
            <g id="Group_94">
              <g id="Group_95">
                <g id="Group_96">
                  <path
                    id="Vector_33"
                    d="M106.054 170.041C104.121 172.875 101.437 178.308 98.0042 186.341C94.2708 195.008 92.0375 201.375 91.3042 205.441C91.0042 207.341 90.6375 209.191 90.2042 210.991C89.9708 212.125 89.9375 213.241 90.1042 214.341C90.4042 216.441 94.5542 218.675 102.554 221.041C107.554 222.541 113.754 223.991 121.154 225.391C124.988 226.125 130.221 226.141 136.854 225.441C140.021 225.108 144.554 224.441 150.454 223.441C151.488 223.275 153.404 223.058 156.204 222.791C158.938 222.458 161.221 221.975 163.054 221.341C168.521 219.408 171.621 215.658 172.354 210.091C173.121 204.591 173.154 198.858 172.454 192.891C171.288 185.491 170.404 179.241 169.804 174.141C169.171 168.775 167.238 164.791 164.004 162.191C161.904 160.525 159.804 159.658 157.704 159.591C157.271 159.558 156.621 159.441 155.754 159.241C153.321 158.608 149.671 158.125 144.804 157.791C138.404 157.391 132.288 157.558 126.454 158.291C118.121 159.325 112.704 160.958 110.204 163.191C108.738 164.525 107.354 166.808 106.054 170.041Z"
                    fill="url(#paint5_linear_0_1)"
                  />
                </g>
              </g>
            </g>
          </g>
          <motion.g id="Pencil"
                animate={{
                  rotate: ["0deg", "10deg", "-10deg", "10deg", "0deg", "0deg"],
                }}
                transition={{
                  times: [0, 0.2, 0.4, 0.6, 0.8, 1],
                  duration: 2,
                  repeat: Infinity,
                  repeatDelay: 2,
                  ease: "easeInOut",
                }}
          >
            <path
              id="Rectangle 18"
              d="M198.281 100.084C203.294 91.4005 214.397 88.4254 223.08 93.4386V93.4386C231.763 98.4518 234.738 109.555 229.725 118.238L228.023 121.186L196.579 103.031L198.281 100.084Z"
              fill="url(#paint6_linear_0_1)"
            />
            <rect
              id="Rectangle 19"
              x={196.579}
              y={103.031}
              width={36.3086}
              height={5.67322}
              transform="rotate(30 196.579 103.031)"
              fill="url(#paint7_linear_0_1)"
            />
            <g
              id="Rectangle 27"
              style={{
                mixBlendMode: "multiply",
              }}
            >
              <rect
                x={194.991}
                y={105.783}
                width={36.3086}
                height={1.13464}
                transform="rotate(30 194.991 105.783)"
                fill="url(#paint8_linear_0_1)"
              />
            </g>
            <g
              id="Rectangle 28"
              style={{
                mixBlendMode: "multiply",
              }}
            >
              <rect
                x={196.012}
                y={104.014}
                width={36.3086}
                height={1.13464}
                transform="rotate(30 196.012 104.014)"
                fill="url(#paint9_linear_0_1)"
              />
            </g>
            <g id="Rectangle 20" filter="url(#filter0_i_0_1)">
              <rect
                x={193.743}
                y={107.944}
                width={36.3086}
                height={95.3101}
                transform="rotate(30 193.743 107.944)"
                fill="url(#paint10_linear_0_1)"
              />
            </g>
            <g id="Polygon 1" filter="url(#filter1_i_0_1)">
              <path
                d="M148.081 223.342L146.208 190.424L177.525 208.504L148.081 223.342Z"
                fill="url(#paint11_linear_0_1)"
              />
            </g>
            <path
              id="Polygon 2"
              d="M147.982 223.286L147.642 216.211L154.279 220.043L147.982 223.286Z"
              fill="#294C65"
            />
            <g id="Rectangle 22" filter="url(#filter2_f_0_1)">
              <rect
                x={210.818}
                y={91.0759}
                width={4.99243}
                height={115.28}
                rx={2.49622}
                transform="rotate(30 210.818 91.0759)"
                fill="white"
              />
            </g>
            <g
              id="Rectangle 24"
              style={{
                mixBlendMode: "color-burn",
              }}
              opacity={0.5}
              filter="url(#filter3_f_0_1)"
            >
              <rect
                x={226.541}
                y={100.153}
                width={4.99243}
                height={115.28}
                rx={2.49622}
                transform="rotate(30 226.541 100.153)"
                fill="#294C65"
              />
            </g>
            <g id="Rectangle 23" opacity={0.5} filter="url(#filter4_f_0_1)">
              <rect
                x={199.547}
                y={112.868}
                width={2.49622}
                height={90.7715}
                rx={1.24811}
                transform="rotate(30 199.547 112.868)"
                fill="white"
              />
            </g>
            <g id="Rectangle 25" opacity={0.8} filter="url(#filter5_f_0_1)">
              <rect
                x={153.402}
                y={194.609}
                width={2.49622}
                height={22.4659}
                rx={1.24811}
                transform="rotate(10.7793 153.402 194.609)"
                fill="white"
              />
            </g>
            <g
              id="Rectangle 26"
              style={{
                mixBlendMode: "color-burn",
              }}
              opacity={0.5}
              filter="url(#filter6_f_0_1)"
            >
              <rect
                width={4.0707}
                height={21.6401}
                rx={2.03535}
                transform="matrix(-0.653147 -0.757231 -0.757231 0.653147 171.405 206.24)"
                fill="#294C65"
              />
            </g>
            <rect
              id="Rectangle 29"
              opacity={0.7}
              x={202.586}
              y={113.05}
              width={1.5885}
              height={94.8562}
              transform="rotate(30 202.586 113.05)"
              fill="#FFF0CC"
            />
            <rect
              id="Rectangle 30"
              opacity={0.5}
              x={214.771}
              y={120.085}
              width={1.5885}
              height={94.8562}
              transform="rotate(30 214.771 120.085)"
              fill="#FFF0CC"
            />
          </motion.g>
          <g id="Rightarm_and_hand">
            <g id="Group_97">
              <g id="Group_98">
                <g id="Group_99">
                  <path
                    id="Vector_34"
                    d="M191.05 163.55V163.5C181.783 171.967 176.767 176.833 176 178.1C175.433 179.067 175.333 180.617 175.7 182.75C175.867 183.817 176.1 184.783 176.4 185.65C177.6 188.117 180.117 189.083 183.95 188.55C185.825 188.291 187.591 187.791 189.25 187.05L184.5 189.3C185.533 191.267 187.25 192.1 189.65 191.8C190.883 191.667 192 191.333 193 190.8L200.9 186.15C200.167 183.383 199.233 180.467 198.1 177.4C195.867 171.433 193.5 166.85 191 163.65L191.05 163.55Z"
                    fill="#754C29"
                  />
                </g>
              </g>
            </g>
            <g id="Group_100">
              <g id="Group_101">
                <g id="Group_102">
                  <path
                    id="Vector_35"
                    d="M212 152.7L211.35 149.45C210.883 150.583 209.9 151.433 208.4 152L206.3 152.45C203.867 152.383 201.6 153.033 199.5 154.4L197.1 156.45L191.05 163.55L190.95 163.65C193.483 166.85 195.867 171.433 198.1 177.4C199.233 180.467 200.167 183.383 200.9 186.15L201 186.1L202.45 184.1C206.283 178.6 209.067 172.733 210.8 166.5C211.367 164.5 211.933 161.433 212.5 157.3C212.6 156.533 212.433 155 212 152.7Z"
                    fill="url(#paint12_linear_0_1)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Leftarm_and_hand">
            <g id="Hand2">
              <g id="Group_103">
                <g id="Group_104">
                  <path
                    id="Vector_36"
                    d="M64 162.6L64.1 162.7C61.5667 165.9 59.1667 170.483 56.9 176.45C55.7 179.617 54.75 182.55 54.05 185.25L62.05 189.85L65.45 190.9C67.8834 191.2 69.6167 190.367 70.65 188.4L65.75 186.1L71.25 187.65C75.1167 188.183 77.65 187.217 78.85 184.75C79.15 183.883 79.4 182.917 79.6 181.85C79.9667 179.717 79.85 178.167 79.25 177.2C78.5167 175.967 73.4334 171.1 64 162.6Z"
                    fill="#603913"
                  />
                </g>
              </g>
            </g>
            <g id="Hand1">
              <g id="Group_105">
                <g id="Group_106">
                  <path
                    id="Vector_37"
                    d="M64.25 161.25L64.35 161.35C61.8167 164.55 59.4167 169.15 57.15 175.15C55.95 178.283 55 181.2 54.3 183.9L62.3 188.5L65.7 189.55C68.1334 189.85 69.8667 189.017 70.9 187.05L66 184.75L71.5 186.3C75.3667 186.833 77.9 185.867 79.1 183.4C79.4 182.533 79.65 181.567 79.85 180.5C80.2167 178.367 80.1 176.817 79.5 175.85C78.7667 174.617 73.6834 169.75 64.25 161.25Z"
                    fill="#754C29"
                  />
                </g>
              </g>
            </g>
            <g id="Arm">
              <g id="Group_107">
                <g id="Group_108">
                  <path
                    id="Vector_38"
                    d="M43.7 147.2L43 150.45C42.5667 152.75 42.4167 154.283 42.55 155.05C43.1167 159.15 43.6833 162.217 44.25 164.25C45.9833 170.483 48.8 176.333 52.7 181.8L54.2 183.85L54.3 183.9C55 181.2 55.95 178.283 57.15 175.15C59.4167 169.15 61.8167 164.55 64.35 161.35L64.25 161.25L58.15 154.2C57.5833 153.5 56.7667 152.817 55.7 152.15C53.6 150.783 51.3167 150.133 48.85 150.2C48.1833 150.167 47.4833 150.017 46.75 149.75C45.2167 149.183 44.2 148.333 43.7 147.2Z"
                    fill="url(#paint13_linear_0_1)"
                  />
                </g>
              </g>
              <g id="Group_109">
                <path
                  id="Vector_39"
                  d="M43.7 147.2C44.2 148.333 45.2167 149.183 46.75 149.75C47.4833 150.017 48.1833 150.167 48.85 150.2C51.3167 150.133 53.6 150.783 55.7 152.15C56.7667 152.817 57.5833 153.5 58.15 154.2L64.25 161.25L64.35 161.35C61.8167 164.55 59.4167 169.15 57.15 175.15C55.95 178.283 55 181.2 54.3 183.9L54.2 183.85L52.7 181.8C48.8 176.333 45.9833 170.483 44.25 164.25C43.6833 162.217 43.1167 159.15 42.55 155.05C42.4167 154.283 42.5667 152.75 43 150.45L43.7 147.2Z"
                  stroke="url(#paint14_linear_0_1)"
                  strokeMiterlimit={10}
                />
              </g>
            </g>
          </g>
          <g id="Eyebrow">
            <g id="Group_110">
              <g id="Group_111">
                <g id="Group_112">
                  <path
                    id="Vector_40"
                    d="M87.4499 55.35C87.9166 54.95 88.3333 54.3833 88.6999 53.65C89.0333 52.95 89.1999 52.2833 89.1999 51.65V51.3C88.8666 50.8 88.3833 50.5 87.7499 50.4C87.1499 50.2667 86.5833 50.3833 86.0499 50.75C83.4499 51.7167 81.3833 52.7333 79.8499 53.8C78.3166 54.9 76.9999 56.2333 75.8999 57.8C75.3666 58.1333 75.0499 58.6167 74.9499 59.25C74.8166 59.85 74.9333 60.4 75.2999 60.9C75.6333 61.4333 76.1166 61.75 76.7499 61.85C77.5166 61.9833 78.0833 61.8667 78.4499 61.5C79.2833 61.1667 79.9999 60.8333 80.5999 60.5C81.1333 60.1667 82.0833 59.5333 83.4499 58.6C84.2166 58.0667 85.5499 56.9833 87.4499 55.35Z"
                    fill="#A97C50"
                  />
                </g>
              </g>
            </g>
            <g id="Group_113">
              <g id="Group_114">
                <g id="Group_115">
                  <path
                    id="Vector_41"
                    d="M166.25 51.7501C166.783 52.4168 167.333 53.0001 167.9 53.5001C168.267 53.8334 169.15 54.5501 170.55 55.6501C171.85 56.6168 173.283 57.5501 174.85 58.4501C175.417 58.7834 176.083 59.0001 176.85 59.1001C177.65 59.2001 178.35 59.1501 178.95 58.9501L179.25 58.8501C179.65 58.2834 179.8 57.7334 179.7 57.2001C179.633 56.7001 179.35 56.2001 178.85 55.7001C177.117 53.5334 175.5 51.8834 174 50.7501C173.067 50.0501 171.95 49.4168 170.65 48.8501L169 48.2001C168.5 47.8001 167.95 47.6501 167.35 47.7501C166.717 47.8168 166.217 48.1001 165.85 48.6001C165.483 49.1001 165.333 49.6501 165.4 50.2501C165.5 50.7834 165.783 51.2834 166.25 51.7501Z"
                    fill="#A97C50"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Hair">
            <g id="Group_116">
              <g id="Group_117">
                <g id="Group_118">
                  <path
                    id="Vector_42"
                    d="M119.05 31.5001L118.95 29.8501L102.8 20.4001L102.05 17.4001L100.1 15.1001L92.8501 15.3001L89.8 20.0501L93.05 25.8001L96.3501 29.0501C99.1167 31.2168 102.017 32.2001 105.05 32.0001C109.883 31.7334 114.55 31.5668 119.05 31.5001Z"
                    fill="#F7941D"
                  />
                </g>
              </g>
            </g>
            <g id="Group_119">
              <g id="Group_120">
                <g id="Group_121">
                  <path
                    id="Vector_43"
                    d="M93.8 16.65L93.4 15.3L89.6 19.65L93.05 25.4L96.1 26.75C98.3666 27.65 100.133 28.1667 101.4 28.3C103.4 28.5334 105.733 28.4667 108.4 28.1C106.9 27.7334 105.267 27.1834 103.5 26.45C100 24.9834 97.6333 23.3 96.4 21.4C95.2 19.5 94.3333 17.9167 93.8 16.65Z"
                    fill="#FBB040"
                  />
                </g>
              </g>
            </g>
            <g id="Group_122">
              <g id="Group_123">
                <g id="Group_124">
                  <path
                    id="Vector_44"
                    d="M108.2 28.0001L119.15 31.0501L117.95 28.4501L104.75 21.3001L100.1 15.1001L92.85 15.2001L92.1 16.5001L94.75 19.4501C96.6166 21.5834 97.8166 23.0501 98.35 23.8501C98.8833 24.6834 100.683 25.6334 103.75 26.7001C105.283 27.2001 106.767 27.6334 108.2 28.0001Z"
                    fill="url(#paint15_linear_0_1)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_125">
              <g id="Group_126">
                <g id="Group_127">
                  <path
                    id="Vector_45"
                    d="M148.65 20.4001L155.6 17.3501C154.267 17.4167 147.6 16.2834 135.6 13.9501L148.65 20.4001Z"
                    fill="#FBB040"
                  />
                </g>
              </g>
            </g>
            <g id="Group_128">
              <g id="Group_129">
                <g id="Group_130">
                  <path
                    id="Vector_46"
                    d="M148.8 20.6L155.75 17.55C154.417 17.6167 147.9 16.4834 136.2 14.15L148.8 20.6Z"
                    fill="#CB6D18"
                  />
                </g>
              </g>
            </g>
            <g id="Group_131">
              <g id="Group_132">
                <g id="Group_133">
                  <path
                    id="Vector_47"
                    d="M128.1 12.9501C134.3 15.6167 139.217 18.9001 142.85 22.8001C144.683 24.7667 145.883 26.4501 146.45 27.8501L156.75 23.9501C155.117 23.5167 152.2 22.2167 148 20.0501C144 18.0167 141.233 16.4667 139.7 15.4001C138.5 14.6001 136.2 13.9501 132.8 13.4501L128.1 12.9501Z"
                    fill="#CE7C24"
                  />
                </g>
              </g>
            </g>
            <g id="Group_134">
              <g id="Group_135">
                <g id="Group_136">
                  <path
                    id="Vector_48"
                    d="M142.75 22.55L146.25 27.95L153.5 25C152.833 25.2334 150.85 24.9 147.55 24L142.75 22.55Z"
                    fill="#C36118"
                  />
                </g>
              </g>
            </g>
            <g id="Group_137">
              <g id="Group_138">
                <g id="Group_139">
                  <path
                    id="Vector_49"
                    d="M130.15 13.15L128.3 12.9C127.692 13.0874 128.992 13.7207 132.2 14.8C132.3 14.8 132.4 14.85 132.5 14.95C133.7 14.85 134.867 14.9 136 15.1C137.267 15.3667 139.667 16.1667 143.2 17.5C142.7 17.0667 141.967 16.5834 141 16.05C139.1 14.9834 137.033 14.2334 134.8 13.8C133.433 13.5667 131.883 13.35 130.15 13.15Z"
                    fill="#F2C488"
                  />
                </g>
              </g>
            </g>
            <g id="Group_140">
              <g id="Group_141">
                <g id="Group_142">
                  <path
                    id="Vector_50"
                    d="M133.35 31.0501L115.55 17.7001L109.25 15.9501L105.1 18.6501L104.8 21.6501L115.1 23.3001L132 33.1001L133.35 31.0501Z"
                    fill="url(#paint16_linear_0_1)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_143">
              <g id="Group_144">
                <g id="Group_145">
                  <path
                    id="Vector_51"
                    d="M115.55 17.7L133.35 31.05L133.7 30.5L126.8 21.25C125.167 19.7834 121.883 18.4 116.95 17.1L110 15.55L109.25 15.95L115.55 17.7Z"
                    fill="url(#paint17_linear_0_1)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_146">
              <g id="Group_147">
                <g id="Group_148">
                  <path
                    id="Vector_52"
                    d="M132 33.1001L115.1 23.3001L104.8 21.6501L105.1 18.6501L108.45 16.4501L104.8 18.5001L103.7 21.3501L119.45 29.2501L124.85 33.7501L131.65 33.6501L132 33.1001Z"
                    fill="#F7941D"
                  />
                </g>
              </g>
            </g>
            <g id="Group_149">
              <g id="Group_150">
                <g id="Group_151">
                  <path
                    id="Vector_53"
                    d="M146.25 31.4501L145.75 27.0001L139 18.3501L130.35 13.1001L124.9 12.6001L146.25 31.4501Z"
                    fill="#F9C441"
                  />
                </g>
              </g>
            </g>
            <g id="Group_152">
              <g id="Group_153">
                <g id="Group_154">
                  <path
                    id="Vector_54"
                    d="M146.25 31.4501L124.35 23.8001L121.5 22.3501L126.7 28.0001L139.75 33.2501L146.25 31.4501Z"
                    fill="#D3842F"
                  />
                </g>
              </g>
            </g>
            <g id="Group_155">
              <g id="Group_156">
                <g id="Group_157">
                  <path
                    id="Vector_55"
                    d="M146.25 31.45L124.9 12.6L119.1 12.05L109.2 15.95L124.35 23.8L146.25 31.45Z"
                    fill="url(#paint18_linear_0_1)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_158">
              <g id="Group_159">
                <g id="Group_160">
                  <path
                    id="Vector_56"
                    d="M119.8 17.9001L125.25 8.6001L114.6 17.2001L119.8 17.9001Z"
                    fill="#F7941D"
                  />
                </g>
              </g>
            </g>
            <g id="Group_161">
              <g id="Group_162">
                <g id="Group_163">
                  <path
                    id="Vector_57"
                    d="M109.35 11.8C110.65 11.2 115.95 10.1334 125.25 8.60005L117.1 8.05005L106.9 10.5L98.05 20.05L103 16.25C106.433 13.65 108.55 12.1667 109.35 11.8Z"
                    fill="url(#paint19_linear_0_1)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_164">
              <g id="Group_165">
                <g id="Group_166">
                  <path
                    id="Vector_58"
                    d="M109.4 11.7501V11.8001C108.6 12.1668 106.467 13.6501 103 16.2501L98.1 20.0501L101.85 20.7001L110.75 16.7001L114.6 17.2001L125.25 8.6001C115.95 10.1001 110.667 11.1501 109.4 11.7501Z"
                    fill="#D3842F"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </motion.g>
      <motion.g id="LEFTeye"
        animate={{ y: [-5, 0, -5 ]}}
        transition={{
            times: [0,1],
            duration: 5,
            repeat: Infinity,
            type: "keyframes",
            ease: "easeInOut",
        }}
      >
        <g id="Layer_1_3">
          <g id="Group_167">
            <g id="Group_168">
              <g id="Group_169">
                <path
                  id="Vector_59"
                  d="M108.35 71.3624C107.55 68.728 105.683 66.8663 102.75 65.7774C99.85 64.7236 96.7334 64.7412 93.4 65.8301C90.1 66.9541 87.5667 68.8334 85.8 71.4678C84 74.1374 83.5 76.7894 84.3 79.4239C85.1 82.0935 86.9667 83.9552 89.9 85.0089C92.8 86.0979 95.9 86.0803 99.2 84.9563C102.533 83.8673 105.083 81.9881 106.85 79.3185C108.65 76.6489 109.15 73.9969 108.35 71.3624Z"
                  fill="url(#paint20_linear_0_1)"
                />
              </g>
            </g>
          </g>
          <g id="Group_170">
            <g id="Group_171">
              <g id="Group_172">
                <path
                  id="Vector_60"
                  d="M93.7 66.5677C90.8 67.5512 88.5834 69.2022 87.05 71.5205C85.4834 73.8739 85.05 76.1923 85.75 78.4755C86.45 80.7938 88.0834 82.4272 90.65 83.3756C93.1834 84.324 95.9 84.3064 98.8 83.3229C101.7 82.3393 103.933 80.6884 105.5 78.3701C107.033 76.0518 107.45 73.7334 106.75 71.4151C106.05 69.0968 104.433 67.4634 101.9 66.515C99.3334 65.5666 96.6 65.5842 93.7 66.5677Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
          <g id="Layer_1_4">
            <g id="Group_173">
              <g id="Group_174">
                <g id="Group_175">
                  <path
                    id="Vector_61"
                    d="M106.85 71.8894C106.15 69.6062 104.533 67.9904 102 67.042C99.4333 66.0936 96.7 66.1112 93.8 67.0947C90.9 68.0782 88.6666 69.7291 87.1 72.0475C85.5333 74.3658 85.1 76.6666 85.8 78.9498C86.5 81.2681 88.1333 82.9014 90.7 83.8499C93.2333 84.7983 95.95 84.7807 98.85 83.7972C101.75 82.8136 103.983 81.1627 105.55 78.8444C107.117 76.5261 107.55 74.2077 106.85 71.8894Z"
                    fill="#3C2415"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_176">
            <g id="Group_177">
              <g id="Group_178">
                <path
                  id="Vector_62"
                  d="M100.7 71.3097L101.3 68.2537C100.867 68.043 100.55 67.8849 100.35 67.7795C99.95 67.5688 99.35 67.4634 98.55 67.4634C97.75 67.4634 97.15 67.5688 96.75 67.7795C96.1167 68.0957 95.7833 68.2537 95.75 68.2537L96.65 71.3097H100.7Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
          <g id="Group_179">
            <g id="Group_180">
              <g id="Group_181">
                <path
                  id="Vector_63"
                  d="M105.95 71.5731L104.5 73.4699C104.667 74.1373 105.2 74.9628 106.1 75.9463L105.95 71.5731Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
          <g id="Group_182">
            <g id="Group_183">
              <g id="Group_184">
                <path
                  id="Vector_64"
                  d="M98.85 82.7959C101.217 81.988 103.183 80.6708 104.75 78.8442H86.15C87.0834 80.8464 88.75 82.2339 91.15 83.0067C93.5834 83.7443 96.15 83.6741 98.85 82.7959Z"
                  fill="#603913"
                />
              </g>
            </g>
          </g>
        </g>
      </motion.g>
      <motion.g id="RIGTHeye"
        animate={{ y: [-5, 0, -5 ]}}
        transition={{
            times: [0,1],
            duration: 5,
            repeat: Infinity,
            type: "keyframes",
            ease: "easeInOut",
        }}
      >
        <g id="Layer_1_5">
          <g id="Group_185">
            <g id="Group_186">
              <g id="Group_187">
                <path
                  id="Vector_65"
                  d="M155.25 71.05C154.75 73.6167 155.533 76.0667 157.6 78.4C159.633 80.7 162.367 82.1833 165.8 82.85C169.2 83.5166 172.3 83.1833 175.1 81.85C177.867 80.4833 179.5 78.5167 180 75.95C180.533 73.35 179.767 70.9 177.7 68.6C175.633 66.3 172.9 64.8167 169.5 64.15C166.067 63.4833 162.967 63.8167 160.2 65.15C157.433 66.5167 155.783 68.4833 155.25 71.05Z"
                  fill="url(#paint21_linear_0_1)"
                />
              </g>
            </g>
          </g>
          <g id="Group_188">
            <g id="Group_189">
              <g id="Group_190">
                <path
                  id="Vector_66"
                  d="M156.95 70.95C156.517 73.2166 157.2 75.35 159 77.35C160.8 79.3833 163.183 80.6833 166.15 81.25C169.15 81.85 171.867 81.5666 174.3 80.4C176.733 79.2 178.167 77.4666 178.6 75.2C179.067 72.9666 178.383 70.8333 176.55 68.8C174.75 66.8 172.367 65.5 169.4 64.9C166.4 64.3333 163.683 64.6333 161.25 65.8C158.85 67 157.417 68.7166 156.95 70.95Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
          <g id="Group_191">
            <g id="Group_192">
              <g id="Group_193">
                <path
                  id="Vector_67"
                  d="M156.8 71.4C156.367 73.6333 157.05 75.7666 158.85 77.8C160.65 79.8 163.033 81.1 166 81.7C169 82.2666 171.717 81.9666 174.15 80.8C176.583 79.6333 178.017 77.9166 178.45 75.65C178.917 73.3833 178.25 71.25 176.45 69.25C174.65 67.2166 172.25 65.9166 169.25 65.35C166.25 64.75 163.533 65.0333 161.1 66.2C158.7 67.4 157.267 69.1333 156.8 71.4Z"
                  fill="#3C2415"
                />
              </g>
            </g>
          </g>
          <g id="Group_194">
            <g id="Group_195">
              <g id="Group_196">
                <path
                  id="Vector_68"
                  d="M159.7 77.3C161.467 79.1333 163.733 80.3166 166.5 80.8499C168.967 81.3499 171.283 81.2 173.45 80.4L157.2 71.45C157.067 73.55 157.9 75.5 159.7 77.3Z"
                  fill="#603913"
                />
              </g>
            </g>
          </g>
          <g id="Group_197">
            <g id="Group_198">
              <g id="Group_199">
                <path
                  id="Vector_69"
                  d="M167.8 66.85C166.267 66.85 165.033 67.2 164.1 67.9L164.9 70.2L169.6 69.95L169.85 67.1C169.25 66.9666 168.567 66.8833 167.8 66.85Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
          <g id="Group_200">
            <g id="Group_201">
              <g id="Group_202">
                <path
                  id="Vector_70"
                  d="M170.8 71.2999C171.833 71.8999 172.567 72.9499 173 74.4499C173.2 75.1833 173.3 75.8666 173.3 76.4999L176.95 76.1999L177.05 72.7499C176.717 71.6166 175.833 70.5833 174.4 69.6499L172.35 68.5499L170.8 71.2999Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </g>
      </motion.g>
    </g>
    <defs>
      <filter
        id="filter0_i_0_1"
        x={134.548}
        y={89.8105}
        width={90.639}
        height={118.829}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-11.5398} dy={-18.1339} />
        <feGaussianBlur stdDeviation={10.3034} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.886275 0 0 0 0 0.196078 0 0 0 0 0.776471 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
      </filter>
      <filter
        id="filter1_i_0_1"
        x={146.208}
        y={180.533}
        width={37.9103}
        height={42.8093}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={6.59415} dy={-9.89123} />
        <feGaussianBlur stdDeviation={6.59415} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.886275 0 0 0 0 0.196078 0 0 0 0 0.776471 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
      </filter>
      <filter
        id="filter2_f_0_1"
        x={112.878}
        y={50.7757}
        width={142.564}
        height={182.932}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={20.6067}
          result="effect1_foregroundBlur_0_1"
        />
      </filter>
      <filter
        id="filter3_f_0_1"
        x={128.601}
        y={59.8528}
        width={142.564}
        height={182.932}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={20.6067}
          result="effect1_foregroundBlur_0_1"
        />
      </filter>
      <filter
        id="filter4_f_0_1"
        x={113.404}
        y={72.1112}
        width={129.061}
        height={161.372}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={20.6067}
          result="effect1_foregroundBlur_0_1"
        />
      </filter>
      <filter
        id="filter5_f_0_1"
        x={132.926}
        y={178.335}
        width={39.2023}
        height={55.0848}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={8.24269}
          result="effect1_foregroundBlur_0_1"
        />
      </filter>
      <filter
        id="filter6_f_0_1"
        x={136.709}
        y={187.508}
        width={50.3456}
        height={48.5169}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={8.24269}
          result="effect1_foregroundBlur_0_1"
        />
      </filter>
      <linearGradient
        id="paint0_linear_0_1"
        x1={27.5125}
        y1={184.112}
        x2={111.187}
        y2={145.087}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF092" />
        <stop offset={0.14902} stopColor="#FFDE17" />
        <stop offset={0.541176} stopColor="#F3B13C" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_0_1"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(127.65 126.45) scale(99 148.5)"
      >
        <stop offset={0.360784} stopColor="#F3B13C" />
        <stop offset={0.631373} stopColor="#FDC70C" />
        <stop offset={0.960784} stopColor="#FFDE17" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_0_1"
        x1={135.675}
        y1={111.05}
        x2={139.225}
        y2={111.05}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="#D1D3D4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_0_1"
        x1={76.6125}
        y1={76.6001}
        x2={115.487}
        y2={76.7001}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDE17" />
        <stop offset={0.54902} stopColor="#F3B13C" />
        <stop offset={1} stopColor="#DEA500" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_0_1"
        x1={150.575}
        y1={67.4375}
        x2={184.325}
        y2={79.3625}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.0392157} stopColor="#F3B13C" />
        <stop offset={0.509804} stopColor="#FDC70C" />
        <stop offset={0.960784} stopColor="#FFDE17" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_0_1"
        x1={89.9542}
        y1={191.741}
        x2={172.954}
        y2={191.741}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDD3C3" />
        <stop offset={1} stopColor="#F7F2EA" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_0_1"
        x1={233.469}
        y1={111.752}
        x2={202.025}
        y2={93.5982}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DC73A5" />
        <stop offset={1} stopColor="#FF9CAD" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_0_1"
        x1={232.887}
        y1={105.838}
        x2={197.488}
        y2={111.723}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4DBF5" />
        <stop offset={1} stopColor="#F1F6FF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_0_1"
        x1={231.299}
        y1={106.344}
        x2={209.786}
        y2={124.227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4DBF5" />
        <stop offset={1} stopColor="#F1F6FF" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_0_1"
        x1={232.32}
        y1={104.576}
        x2={210.807}
        y2={122.458}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4DBF5" />
        <stop offset={1} stopColor="#F1F6FF" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_0_1"
        x1={230.051}
        y1={162.18}
        x2={194.932}
        y2={155.688}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BF50" />
        <stop offset={1} stopColor="#F7DD7B" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_0_1"
        x1={164.542}
        y1={215.028}
        x2={147.429}
        y2={205.409}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD698" />
        <stop offset={1} stopColor="#FFF3DC" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_0_1"
        x1={190.987}
        y1={167.8}
        x2={231.412}
        y2={167.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC70C" />
        <stop offset={0.54902} stopColor="#F3B13C" />
        <stop offset={0.960784} stopColor="#FFDE17" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_0_1"
        x1={65.1125}
        y1={165.55}
        x2={24.2875}
        y2={165.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3B13C" />
        <stop offset={0.270588} stopColor="#FDC70C" />
        <stop offset={0.960784} stopColor="#FFDE17" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_0_1"
        x1={42.0125}
        y1={165.65}
        x2={64.9875}
        y2={165.65}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE3B9" />
        <stop offset={0.0392157} stopColor="#FADEAD" />
        <stop offset={0.180392} stopColor="#F7CE85" />
        <stop offset={0.309804} stopColor="#F5C165" />
        <stop offset={0.439216} stopColor="#F4B84E" />
        <stop offset={0.568627} stopColor="#F3B240" />
        <stop offset={0.678431} stopColor="#F3B13C" />
        <stop offset={0.74902} stopColor="#F5B72E" />
        <stop offset={0.909804} stopColor="#FBC215" />
        <stop offset={1} stopColor="#FDC70C" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_0_1"
        x1={92.0875}
        y1={23.1001}
        x2={119.112}
        y2={23.1001}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE3B9" />
        <stop offset={0.0392157} stopColor="#FADEAD" />
        <stop offset={0.180392} stopColor="#F7CE85" />
        <stop offset={0.309804} stopColor="#F5C165" />
        <stop offset={0.439216} stopColor="#F4B84E" />
        <stop offset={0.568627} stopColor="#F3B240" />
        <stop offset={0.678431} stopColor="#F3B13C" />
        <stop offset={0.74902} stopColor="#F5B72E" />
        <stop offset={0.909804} stopColor="#FBC215" />
        <stop offset={1} stopColor="#FDC70C" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_0_1"
        x1={104.825}
        y1={24.5501}
        x2={133.375}
        y2={24.5501}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE3B9" />
        <stop offset={0.0392157} stopColor="#FADEAD" />
        <stop offset={0.180392} stopColor="#F7CE85" />
        <stop offset={0.309804} stopColor="#F5C165" />
        <stop offset={0.439216} stopColor="#F4B84E" />
        <stop offset={0.568627} stopColor="#F3B240" />
        <stop offset={0.678431} stopColor="#F3B13C" />
        <stop offset={0.74902} stopColor="#F5B72E" />
        <stop offset={0.909804} stopColor="#FBC215" />
        <stop offset={1} stopColor="#FDC70C" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_0_1"
        x1={109.262}
        y1={23.3}
        x2={133.737}
        y2={23.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE3B9" />
        <stop offset={0.0196078} stopColor="#FADEAD" />
        <stop offset={0.121569} stopColor="#F7CE85" />
        <stop offset={0.211765} stopColor="#F5C165" />
        <stop offset={0.298039} stopColor="#F4B84E" />
        <stop offset={0.388235} stopColor="#F3B240" />
        <stop offset={0.458824} stopColor="#F3B13C" />
        <stop offset={0.580392} stopColor="#F5B72E" />
        <stop offset={0.839216} stopColor="#FBC215" />
        <stop offset={1} stopColor="#FDC70C" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_0_1"
        x1={109.187}
        y1={21.75}
        x2={155.612}
        y2={21.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE3B9" />
        <stop offset={0.0392157} stopColor="#FADEAD" />
        <stop offset={0.180392} stopColor="#F7CE85" />
        <stop offset={0.309804} stopColor="#F5C165" />
        <stop offset={0.439216} stopColor="#F4B84E" />
        <stop offset={0.568627} stopColor="#F3B240" />
        <stop offset={0.678431} stopColor="#F3B13C" />
        <stop offset={0.74902} stopColor="#F5B72E" />
        <stop offset={0.898039} stopColor="#FBC215" />
        <stop offset={1} stopColor="#FDC70C" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_0_1"
        x1={111.65}
        y1={20.0375}
        x2={111.65}
        y2={8.06255}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE3B9" />
        <stop offset={0.168627} stopColor="#F8D18C" />
        <stop offset={0.34902} stopColor="#F5C061" />
        <stop offset={0.490196} stopColor="#F3B546" />
        <stop offset={0.568627} stopColor="#F3B23D" />
        <stop offset={0.698039} stopColor="#F3B13C" />
        <stop offset={0.760784} stopColor="#F5B72E" />
        <stop offset={0.909804} stopColor="#FBC215" />
        <stop offset={1} stopColor="#FDC70C" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_0_1"
        x1={96.35}
        y1={44.6227}
        x2={95.5727}
        y2={78.1839}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B5E3C" />
        <stop offset={0.0117647} stopColor="#845835" />
        <stop offset={0.0392157} stopColor="#784F2C" />
        <stop offset={0.0705882} stopColor="#754C29" />
        <stop offset={0.490196} stopColor="#D47C00" />
        <stop offset={0.670588} stopColor="#603913" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_0_1"
        x1={181.8}
        y1={47.925}
        x2={165.8}
        y2={75.475}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B5E3C" />
        <stop offset={0.0117647} stopColor="#845835" />
        <stop offset={0.0392157} stopColor="#784F2C" />
        <stop offset={0.0705882} stopColor="#754C29" />
        <stop offset={0.490196} stopColor="#D47C00" />
        <stop offset={0.670588} stopColor="#603913" />
      </linearGradient>
      <clipPath id="clip0_0_1">
        <rect width={250} height={250} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default BeaverLogoPencilAnimation
