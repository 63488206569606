import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTrash, FaPlusCircle } from 'react-icons/fa';
import Slider from '@mui/material/Slider';

const SectionContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SectionItem = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const AddItemButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: #577483;
  }

  &:focus {
    outline: none;
  }
`;


const PlusIcon = styled(FaPlusCircle)`
  margin-right: 5px;
`;

const SliderWrapper = styled.div`
  width: 90%;
  margin-left: 20px;
  margin-right: 20px;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const MarginWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const TrashWrapper = styled.div`
  width: 20px;
  padding: 10px;
  color: #003366;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const UniversitiesComponent = ({ universities, setUniversities }) => {
  const [newUniversity, setNewUniversity] = useState(null);

  const handleChange = (index, field, value) => {
    const updatedUniversities = [...universities];
    updatedUniversities[index][field] = value;
    setUniversities(updatedUniversities);
  };

  const handleAddItem = (newItem) => {
    if (newItem) {
      setUniversities((prev) => [...prev, newItem]);
      setNewUniversity(null);
    }
  };

  const handleDeleteItem = (index) => {
    setUniversities((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddMajor = (index) => {
    const updatedUniversities = [...universities];
    updatedUniversities[index].majors.push({ major: '' });
    setUniversities(updatedUniversities);
  };

  const handleAddUniversity = (index) => {
    const updatedUniversities = [...universities];
    updatedUniversities[index].university.push({ university: '' });
    setUniversities(updatedUniversities);
  };

  const handleUniversityChange = (uniIndex, universityIndex, event) => {
    const values = [...universities];
    values[uniIndex].university[universityIndex][event.target.name] = event.target.value;
    setUniversities(values);
  };

  const handleMajorChange = (uniIndex, majorIndex, event) => {
    const values = [...universities];
    values[uniIndex].majors[majorIndex][event.target.name] = event.target.value;
    setUniversities(values);
  };

  const handleRemoveUniversity = (uniIndex, universityIndex) => {
    const values = [...universities];
    values[uniIndex].university.splice(universityIndex, 1);
    setUniversities(values);
  };

  const handleRemoveMajor = (uniIndex, majorIndex) => {
    const values = [...universities];
    values[uniIndex].majors.splice(majorIndex, 1);
    setUniversities(values);
  };

  const handleGpaChange = (index, newValue) => {
    const values = [...universities];
    values[index].gpa = newValue;
    setUniversities(values);
  };

  const valuetext = (value) => `${value.toFixed(1)}`;

  return (
    <SectionContainer>
      {universities.map((uni, index) => (
        <SectionItem key={index}>
          <InputLabel>
            University
            {uni.university.map((university, universityIndex) => (
                <MarginWrapper>
              <InputWrapper key={universityIndex}>
                <Input
                  type="text"
                  name="university"
                  value={university.university}
                  onChange={(e) => handleUniversityChange(index, universityIndex, e)}
                  placeholder="Enter your preferred University"
                  required
                />
                {uni.university.length > 1 && (
                  <TrashWrapper onClick={() => handleRemoveUniversity(index, universityIndex)}>
                    <FaTrash />
                  </TrashWrapper>
                )}
              </InputWrapper> </MarginWrapper>
            ))}
          </InputLabel>
          <AddItemButton onClick={() => handleAddUniversity(index)}>
            <PlusIcon />
            Add University
          </AddItemButton>
          <InputLabel>
            Major
            {uni.majors.map((major, majorIndex) => (
                  <MarginWrapper>
              <InputWrapper key={majorIndex}>
                <Input
                  type="text"
                  name="major"
                  value={major.major}
                  onChange={(e) => handleMajorChange(index, majorIndex, e)}
                  placeholder="Enter Major"
                  required
                />
                {uni.majors.length > 1 && (
                  <TrashWrapper onClick={() => handleRemoveMajor(index, majorIndex)}>
                    <FaTrash />
                  </TrashWrapper>
                )}
              </InputWrapper></MarginWrapper>
            ))}
          </InputLabel>
          <AddItemButton onClick={() => handleAddMajor(index)}>
            <PlusIcon />
            Add Major
          </AddItemButton>
          <InputLabel>
            GPA Range
            <SliderWrapper>
              <Slider
                getAriaLabel={() => 'GPA range'}
                value={uni.gpa || [2.0, 3.5]}
                onChange={(event, newValue) => handleGpaChange(index, newValue)}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={0.1}
                max={4.0}
                step={0.1}
                style={{ color: '#003366' }}
              />
            </SliderWrapper>
          </InputLabel>
        </SectionItem>
      ))}
      {newUniversity === null ? (
        <AddItemButton onClick={() => setNewUniversity({ university: [{ university: '' }], majors: [{ major: '' }], gpa: [2.0, 3.5], startDate: '', endDate: '' })}>
          Add University
        </AddItemButton>
      ) : (
        <SectionItem>
          <InputLabel>
            University
            <Input
              type="text"
              value={newUniversity.university[0].university}
              onChange={(e) => setNewUniversity({ ...newUniversity, university: [{ university: e.target.value }] })}
              placeholder="Enter your preferred University"
              required
            />
          </InputLabel>
          <InputLabel>
            Major
            <Input
              type="text"
              value={newUniversity.majors[0].major}
              onChange={(e) => setNewUniversity({ ...newUniversity, majors: [{ major: e.target.value }] })}
              placeholder="Enter Major"
              required
            />
          </InputLabel>
          <InputLabel>
            GPA Range
            <SliderWrapper>
              <Slider
                getAriaLabel={() => 'GPA range'}
                value={newUniversity.gpa || [2.0, 3.5]}
                onChange={(event, newValue) => setNewUniversity({ ...newUniversity, gpa: newValue })}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={0.1}
                max={4.0}
                step={0.1}
                style={{ color: '#003366' }}
              />
            </SliderWrapper>
          </InputLabel>
        </SectionItem>
      )}
    </SectionContainer>
  );
};

export default UniversitiesComponent;
