import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Styled components
const StepContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  width: 100%;
  height: 100vh;
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  width: 100%;
  padding: 20px;
`;

const MultipleChoiceQuestion = styled.ul`
  padding: 0;
  margin: 20px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ChoiceItem = styled(motion.li)`
  min-height: 60px;
  background: #ffffff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 80%;
  max-width: 520px;
  list-style: none;
  font-size: 18px;
  color: #333;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  transition: all 0.1s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.selected ? "#003366" : "#ffffff")};
  color: ${(props) => (props.selected ? "#fff" : "#333")};
 
  &:hover {
    background-color: ${(props) => (props.selected ? "#003366" : "#eaeaea")}; // Same background hover effect as in AnonymousPersonal
    border: 1px solid #003366; // Same border effect on hover as in AnonymousPersonal
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  }
`;

const Heading = styled.h1`
  font-size: 32px;
  color: #111;
  text-align: center;
  margin-bottom: 20px;
`;

// Framer Motion Variants
const itemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  hover: { scale: 1.05 },
  tap: { scale: 0.95 },
};

const People = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <StepContainer>
      <StepContent>
        <Heading>Which of the following are you?</Heading>
        <MultipleChoiceQuestion>
          {options.map((option) => (
            <ChoiceItem
              key={option}
              selected={selectedOption === option}
              onClick={() => handleSelect(option)}
              variants={itemVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              whileTap="tap"
              transition={{ type: "spring", stiffness: 300 }}
            >
              {option}
            </ChoiceItem>
          ))}
        </MultipleChoiceQuestion>
      </StepContent>
    </StepContainer>
  );
};

export default People;
