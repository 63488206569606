import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import ChatUser from './ChatUser';
import ChatMessages from './ChatMessages';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  padding-left: -20px;
  color: black;
  overflow-x: hidden; /* Hide horizontal overflow */

  @media (max-width: 768px) {
    padding-left: 0px;
    padding: 0px;
  }
`;

const UsersContainer = styled.div`
  border-right: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  overflow-x: hidden; /* Ensure no horizontal scroll here either */
  
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const NoMatchesMessage = styled.div`
  padding: 20px;
  text-align: center;
  color: gray;
`;

const Chat = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [matches, setMatches] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const currentUserUid = auth.currentUser ? auth.currentUser.uid : 'anonymous';

  useEffect(() => {
    const fetchMatches = async () => {
      if (currentUserUid !== 'anonymous') {
        const userRef = doc(db, 'users', currentUserUid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.matches) {
            setMatches(userData.matches);
            setCurrentUser(userData);
          }
        }
      }
    };

    fetchMatches();
  }, [currentUserUid]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (matches.length > 0) {
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const usersList = usersSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((user) => matches.includes(user.id));
        setUsers(usersList);
      }
    };

    fetchUsers();
  }, [matches]);

  return (
    <ChatContainer>
      {!selectedUser ? (
        <UsersContainer>
          {users.length > 0 ? (
            users.map((user) => (
              <ChatUser
                key={user.id}
                user={user}
                isSelected={selectedUser && selectedUser.id === user.id}
                onClick={() => setSelectedUser(user)}
              />
            ))
          ) : (
            <NoMatchesMessage>
              You haven't been matched yet, but you will be shortly!
            </NoMatchesMessage>
          )}
        </UsersContainer>
      ) : (
        <ChatMessages 
          selectedUser={selectedUser} 
          onBackClick={() => setSelectedUser(null)} 
          currentUser={currentUser} 
        />
      )}
    </ChatContainer>
  );
};

export default Chat;
