import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbardonor from '../component/Navbardonor';
import GiveBackSection from './GiveBackSection'; // Import the GiveBackSection component
import DonationDetails from './DonationDetails'; // Import the DonationDetails component
import MaximizeImpact from './MaximizeImpact';
import { useParams } from 'react-router-dom';

// MainContainer, ContentWrapper, NavList, NavItem, NavLink, etc. (same as in your original code)

const MainContainer = styled(motion.main)`
  flex: 1;
`;

const ContentWrapper = styled(motion.div)`
  margin: 0 auto;
  padding: 0;
  padding-top: 0;
  max-width: 1100px;
  height: 100%;
  overflow-y: auto;
`;
const NavList = styled(motion.ul)`
  display: flex;
  justify-content: flex-start; /* Ensure items are centered */
  align-items: center;
  height: 60px;
  margin-top: 40px;
  padding-left: 0;  /* Remove any extra padding */
  list-style: none;
  width: 100%;  /* Set to 100% width */
`;

const NavItem = styled(motion.li)`
  margin-right: 1.75rem;
  padding: 0;
  &:last-child {
    margin-right: 0;  /* Remove margin for the last item */
  }
`;
const NavLink = styled(motion.a)`
  display: flex;
  justify-content: center;  /* Ensure text inside is centered */
  padding: 1.25rem 0;
  font-size: 0.875rem;
  text-decoration: none;
  white-space: nowrap;
  text-transform: none;
  color: ${({ active }) => (active ? '#003366' : '#4A4A4A')};
  box-shadow: ${({ active }) => (active ? 'inset 0 -2px 0 0 #003366' : 'none')};
  transition: color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    color: #003366;
  }
`;


// Styled components for sections
const Section = styled(motion.div)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 0;
`;

const HowTo = () => {
  const { section } = useParams();
  console.log('Section:', section);
  const activeSection = section || 'giveBackSection';
  const navigate = useNavigate();

  const handleNavClick = (section) => {
    navigate(`/how-to/${section}`); // Update the URL
  };

  return (
    <MainContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Navbardonor />
      <ContentWrapper>
        <NavList>
          <NavItem>
            <NavLink
              onClick={() => handleNavClick('giveBackSection')}
              active={activeSection === 'giveBackSection'}
            >
              Award Features
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => handleNavClick('faq')}
              active={activeSection === 'faq'}
            >
              FAQ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => handleNavClick('pricing')}
              active={activeSection === 'pricing'}
            >
              Pricing
            </NavLink>
          </NavItem>
          
        </NavList>

        {/* Animate the transition between sections */}
        <motion.div
          key={activeSection}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ duration: 0.5 }}
        >
          {activeSection === 'faq' && <DonationDetails />}
          {activeSection === 'giveBackSection' && <GiveBackSection />}
          {activeSection === 'pricing' && <MaximizeImpact />}
        </motion.div>
      </ContentWrapper>
    </MainContainer>
  );
};

export default HowTo;
