import React, { useState } from 'react';
import styled from 'styled-components';
import { FaGoogle } from 'react-icons/fa';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebase'; // Make sure this path is correct and that you have initialized Firebase Auth
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import FundingSuccessLogoSVGNoAnimation from '../component/SVG/FundingSuccessLogoSVGNoAnimation'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Page = styled.div`
  background: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  width: 100%;
  justify-content: space-between;
`;

const LoginContainer = styled.div`
  background: #fff;
  padding: 32px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
`;

const Subtitle = styled.p`
  color: #666;
  text-align: center;
  margin-bottom: 24px;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #666;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
  background-color: #003366;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
`;

const GoogleButton = styled(Button)`
  background-color: #003366;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

const Link = styled.a`
  display: block;
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const SignupText = styled.p`
  color: #666;
  text-align: center;
`;

const Logging = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = async (e) => {
      e.preventDefault();
      try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
  
          // Fetch sign_up_step_completed from Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
              const data = userDoc.data();
              const completedStep = data.sign_up_step_completed || 0;
              const donor = data.isDonor || 0;
              if(donor){
                if (completedStep < 4) {
                  navigate("/signupinformation");
              } else {
                  navigate("/home");
              }
              }else {
                if (completedStep < 4) {
                  navigate("/signupinformation");
              } else {
                  navigate("/home");
              }
              }
          }
      } catch (error) {
          const errorMessage = getErrorMessage(error.code);
          toast.error(errorMessage);
          console.log(error.code, error.message);
      }
  };
  
  const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
      try {
          const result = await signInWithPopup(auth, provider);
          const user = result.user;
  
          // Fetch sign_up_step_completed from Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
              const data = userDoc.data();
              const completedStep = data.sign_up_step_completed || 0;
              if (completedStep < 6) {
                  navigate("/signupinformation");
              } else {
                  navigate("/home");
              }
          }
      } catch (error) {
          const errorMessage = getErrorMessage(error.code);
          toast.error(errorMessage);
          console.log(error.code, error.message);
      }
  };
  

  const getErrorMessage = (code) => {
    switch (code) {
        case 'auth/invalid-email':
            return 'The email address is badly formatted. Please check and try again.';
        case 'auth/user-disabled':
            return 'This account has been disabled. Please contact support.';
        case 'auth/user-not-found':
            return 'No user found with this email. Please check the email or sign up.';
        case 'auth/wrong-password':
            return 'Incorrect password. Please try again or reset your password.';
        case 'auth/popup-closed-by-user':
            return 'The sign-in popup was closed before completing the sign-in. Please try again.';
        case 'auth/network-request-failed':
            return 'Network error. Please check your connection and try again.';
        case 'auth/email-already-in-use':
            return 'The email address is already in use by another account. Please sign in.';
        default:
            return 'An unexpected error occurred. Please try again later.';
    }
};

 

  return (
    <Page>
        <LogoContainer>
            <FundingSuccessLogoSVGNoAnimation  onClick={() => navigate('/')}/>
        </LogoContainer>
        <LoginContainer>
            <Title>Welcome back</Title>
            <Subtitle>Sign in to continue</Subtitle>
            <form onSubmit={onLogin}>
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <CheckboxContainer>
                    <Checkbox type="checkbox" />
                    Remember me
                </CheckboxContainer>
                <Link onClick={() => navigate("/forgot-password")}>Forgot password?</Link>
                <Button type="submit">Sign In</Button>
            </form>
            {/* <GoogleButton onClick={signInWithGoogle}>
                <IconWrapper>
                    <FaGoogle color="white" />
                </IconWrapper>
                Sign in with Google
            </GoogleButton> */}
            <SignupText onClick={() => navigate("/createprofile")}>
                Don’t have an account? <Link>Sign Up</Link>
            </SignupText>
        </LoginContainer>
        <ToastContainer />
    </Page>
);

};

export default Logging;
