import React, { useState } from 'react';
import styled from 'styled-components';
import { FaPlusCircle, FaMinusCircle, FaTimesCircle } from 'react-icons/fa';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from 'react-toastify';

const Page = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eaeaea;
  margin: 20px 0;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
`;

const Title = styled.h1`
  color: black;
`;

const Verticaldiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
`;

const AddMoreButton = styled.button`
  background: none;
  color: #003366;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #e7f5fe;
  }

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  background: none;
  color: #cc0000;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #fbe3e3;
  }

  &:focus {
    outline: none;
  }
`;

const PlusIcon = styled(FaPlusCircle)`
  margin-right: 5px;
`;

const MinusIcon = styled(FaMinusCircle)`
  margin-right: 5px;
`;

const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FileInputLabel = styled.label`
  background-color: #003366;
  color: white;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005599;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;
const DisplayImage = styled.div`
  display: flex;
`;

const CategoryTag = styled.div`
  background-color: #003366;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ImagePreview = styled.img`
  width: 60px;
  height: auto;
  border-radius: 4px;
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const RemoveIcon = styled(FaTimesCircle)`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: white;
  border-radius: 50%;
  color: red;
  cursor: pointer;
`;

const Project = ({ projectEntries, setProjectEntries }) => {
  const storage = getStorage();

  const handleInputChange = (index, event) => {
    const values = [...projectEntries];
    values[index][event.target.name] = event.target.value;
    setProjectEntries(values);
  };

  const handleFileChange = async (index, event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error('Please upload at least one picture.');
      return;
    }
    
    const storageRef = ref(storage, `projects/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);

    const values = [...projectEntries];
    values[index].pictures = values[index].pictures || [];
    values[index].pictures.push({ url: downloadURL, name: file.name });
    setProjectEntries(values);
  };

  const handleAddMore = () => {
    setProjectEntries([...projectEntries, { name: '', description: '', whoBenefits: '', pictures: [] }]);
  };

  const handleDelete = (index) => {
    const values = [...projectEntries];
    values.splice(index, 1);
    setProjectEntries(values);
  };

  const handleRemovePicture = (entryIndex, pictureIndex) => {
    const values = [...projectEntries];
    values[entryIndex].pictures.splice(pictureIndex, 1);
    setProjectEntries(values);
  };

  return (
    <Page>
      <Title>Accomplishments</Title>
      <Form>
        {projectEntries.map((entry, index) => (
          <div key={index}>
            {index > 0 && <Divider />}
            <Verticaldiv>
              <InputLabel>Accomplishment Name</InputLabel>
              <Input
                type="text"
                name="name"
                value={entry.name}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter Project Name"
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>Description</InputLabel>
              <TextArea
                name="description"
                value={entry.description}
                onChange={event => handleInputChange(index, event)}
                placeholder="Enter Project Description"
                required
              />
            </Verticaldiv>
            <Verticaldiv>
              <InputLabel>Who Benefits From My Work?</InputLabel>
              <TextArea
                name="whoBenefits"
                value={entry.whoBenefits}
                onChange={event => handleInputChange(index, event)}
                placeholder="Describe who benefits from your work"
                required
                style={{ height: '150px' }} // Increased height for larger text area
              />
            </Verticaldiv>
            <FileInputContainer>
              <InputLabel>Pictures</InputLabel>
              <FileInputLabel htmlFor={`file-input-${index}`}>
                Upload Picture
              </FileInputLabel>
              <HiddenFileInput
                id={`file-input-${index}`}
                type="file"
                name="pictures"
                onChange={event => handleFileChange(index, event)}
                accept="image/*"
                required={entry.pictures.length === 0}
              />
              <DisplayImage>
                {entry.pictures.map((picture, picIndex) => (
                  <ImageContainer key={picIndex}>
                    <ImagePreview src={picture.url} alt={picture.name} />
                    <RemoveIcon onClick={() => handleRemovePicture(index, picIndex)} />
                  </ImageContainer>
                ))}
              </DisplayImage>
            </FileInputContainer>
          </div>
        ))}
        <ButtonContainer>
          <AddMoreButton type="button" onClick={handleAddMore}>
            <PlusIcon />
            Add More
          </AddMoreButton>
          {projectEntries.length > 1 && (
            <DeleteButton type="button" onClick={() => handleDelete(projectEntries.length - 1)}>
              <MinusIcon />
              Delete
            </DeleteButton>
          )}
        </ButtonContainer>
      </Form>
    </Page>
  );
};

export default Project;
