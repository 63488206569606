import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StepContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -30px;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const Step = styled(motion.div)`
  background-color: ${(props) => (props.active ? '#003366' : '#ccc')};
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ProgressLine = styled(motion.div)`
  height: 5px;
  background-color: ${(props) => (props.active ? '#003366' : '#ccc')};
  flex-grow: 1;
  margin: 0 5px;
`;

function ProgressStep({ active, stepNumber, tooltipText, onHover, onLeave, showTooltip }) {
  return (
    <StepContainer
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={onHover}
    >
      <Tooltip visible={showTooltip}>{tooltipText}</Tooltip>
      <Step active={active}>{stepNumber}</Step>
    </StepContainer>
  );
}

function ProgressBar({ step, steps }) {
  const [tooltipVisible, setTooltipVisible] = useState(null);

  const showTooltip = (index) => {
    setTooltipVisible(index);
  };

  const hideTooltip = () => {
    setTooltipVisible(null);
  };

  return (
    <ProgressBarContainer>
      {steps.map((stepInfo, index) => (
        <React.Fragment key={index}>
          <ProgressStep
            active={step >= index}
            stepNumber={index + 1}
            tooltipText={stepInfo.tooltipText}
            onHover={() => showTooltip(index)}
            onLeave={hideTooltip}
            showTooltip={tooltipVisible === index}
          />
          {index < steps.length - 1 && <ProgressLine active={step >= index + 1} />}
        </React.Fragment>
      ))}
    </ProgressBarContainer>
  );
}

export default ProgressBar;
