import React from 'react';
import styled from 'styled-components';
import BeaverLogoPencilAnimation from './BeaverLogoPencilAnimation';
import ScholarshipForm from '../component/addScholarship/ScholarshipForm';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
    min-width: 0;
    max-height: 6%;
    box-shadow: none;
  }
`;

const ScholarshipTitle = styled.h1`
  color: #003366;
`;

const ScholarshipTitleContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const AddScholarship = () => {
  return (
    <Wrapper>
      <Page>
        <ScholarshipTitleContainer>
          <BeaverLogoPencilAnimation />
          <ScholarshipTitle>Add Scholarships</ScholarshipTitle>
        </ScholarshipTitleContainer>
       
        <ScholarshipForm />
      </Page>
    </Wrapper>
  );
};

export default AddScholarship;
