import React from 'react';
import styled from 'styled-components';


// Styled components for layout and design
const ExploreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;

  @media (min-width: 768px) {
    padding: 20px 0;
  }
`;

const ExploreHeading = styled.h2`
  color: #003366;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;

  @media (min-width: 768px) {
    text-align: start;
    margin-right: 45px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 10px;
  justify-content: center;

  @media (min-width: 768px) {
    gap: 20px;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #003366;
  background-color: #f4f4f4;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const IconLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  color: #666;

  svg {
    margin-left: 5px;
  }
`;

const ExploreScholarship = () => {
  


  return (
    <ExploreContainer>
      <ExploreHeading>
        Explore All Kinds of Scholarships for All Kinds of Students
      </ExploreHeading>
      <LinksContainer>
        <StyledLink>
          Medical School Scholarships
        </StyledLink>
        <StyledLink>
          High School Scholarships
        </StyledLink>
        <StyledLink>
          No-Essay Scholarships
        </StyledLink>
        <StyledLink>
          Scholarships for Black Students
        </StyledLink>
        <StyledLink>
          Graduate School Scholarships
        </StyledLink>
        <StyledLink>
          Merit-Based Scholarships
        </StyledLink>
        <StyledLink>
          Scholarships for High School Seniors
        </StyledLink>
        <StyledLink >
          Scholarships for Nursing Students
        </StyledLink>
        <StyledLink>
          Scholarships for Women
        </StyledLink>
        <IconLink href="/scholarships/">
          View all
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75 13.5L11.25 9L6.75 4.5"
              stroke="#ACB4BF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </IconLink>
      </LinksContainer>
    </ExploreContainer>
  );
};

export default ExploreScholarship;
