// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxB18FmmHUDDPpbVpfASL2beL1syGAdDU",
  authDomain: "studentevaluation-9d972.firebaseapp.com",
  projectId: "studentevaluation-9d972",
  storageBucket: "studentevaluation-9d972.appspot.com",
  messagingSenderId: "165887953343",
  appId: "1:165887953343:web:2d15d52523b1109895b90a",
  measurementId: "G-Z1BRSSQN36"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);
export { db };
const database = getDatabase(app);
export { database}
export default app;
const analytics = getAnalytics(app);
const storage = getStorage(app);
export { storage };