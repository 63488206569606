import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

const SectionContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SectionItem = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
`;

const InputLabel = styled.label`
  font-size: 0.9em;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #003366;
  }
`;

const AddItemButton = styled.button`
  padding: 10px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #577483;
  }

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled(FaTrash)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #577483;

  &:hover {
    color: #003366;
  }
`;

const formatDateForInput = (dateStr) => {
  const [dd, mm, yyyy] = dateStr.split('/');
  return `${yyyy}-${mm}-${dd}`;
};

const formatDateForDisplay = (dateStr) => {
  const [yyyy, mm, dd] = dateStr.split('-');
  return `${dd}/${mm}/${yyyy}`;
};

const EducationComponent = ({ education, setEducation }) => {
  const [newEducation, setNewEducation] = useState(null);

  const handleChange = (index, field, value) => {
    const updatedEducation = [...education];
    updatedEducation[index][field] = value;
    setEducation(updatedEducation);
  };

  const handleAddItem = (newItem) => {
    if (newItem) {
      setEducation((prev) => [...prev, newItem]);
      setNewEducation(null);
    }
  };

  const handleDeleteItem = (index) => {
    setEducation((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <SectionContainer>
      {education.map((edu, index) => (
        <SectionItem key={index}>
          <DeleteButton onClick={() => handleDeleteItem(index)} />
          <InputLabel>
            University
            <Input type="text" value={edu.university} onChange={(e) => handleChange(index, 'university', e.target.value)} />
          </InputLabel>
          <InputLabel>
            Major
            <Input type="text" value={edu.major} onChange={(e) => handleChange(index, 'major', e.target.value)} />
          </InputLabel>
          <InputLabel>
            GPA
            <Input type="text" value={edu.gpa} onChange={(e) => handleChange(index, 'gpa', e.target.value)} />
          </InputLabel>
          <InputLabel>
            Start Date
            <Input type="date" value={formatDateForInput(edu.startDate)} onChange={(e) => handleChange(index, 'startDate', formatDateForDisplay(e.target.value))} />
          </InputLabel>
          <InputLabel>
            End Date
            <Input type="date" value={formatDateForInput(edu.endDate)} onChange={(e) => handleChange(index, 'endDate', formatDateForDisplay(e.target.value))} />
          </InputLabel>
        </SectionItem>
      ))}
      {newEducation === null ? (
        <AddItemButton onClick={() => setNewEducation({ university: '', major: '', gpa: '', startDate: '', endDate: '' })}>Add Education</AddItemButton>
      ) : (
        <SectionItem>
          <InputLabel>
            University
            <Input type="text" value={newEducation.university} onChange={(e) => setNewEducation({ ...newEducation, university: e.target.value })} />
          </InputLabel>
          <InputLabel>
            Major
            <Input type="text" value={newEducation.major} onChange={(e) => setNewEducation({ ...newEducation, major: e.target.value })} />
          </InputLabel>
          <InputLabel>
            GPA
            <Input type="text" value={newEducation.gpa} onChange={(e) => setNewEducation({ ...newEducation, gpa: e.target.value })} />
          </InputLabel>
          <InputLabel>
            Start Date
            <Input type="date" value={newEducation.startDate} onChange={(e) => setNewEducation({ ...newEducation, startDate: e.target.value })} />
          </InputLabel>
          <InputLabel>
            End Date
            <Input type="date" value={newEducation.endDate} onChange={(e) => setNewEducation({ ...newEducation, endDate: e.target.value })} />
          </InputLabel>
          <AddItemButton onClick={() => handleAddItem(newEducation)}>Add Education</AddItemButton>
        </SectionItem>
      )}
    </SectionContainer>
  );
};

export default EducationComponent;
