import React from 'react';
import { FaUserSecret, FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Styled components used in the AnonymousPersonal section
const Header = styled.h1`
  color: #003366;
  margin-bottom: 20px;
  margin-top: 80px;
  font-size: 2em;
  text-align: center;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled(motion.div)`
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 45%;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #eaeaea;
    border: 1px solid #003366;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardTitle = styled.h2`
  font-size: 1.4em;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #003366;
`;

const CardIcon = styled.div`
  font-size: 4em;
  color: #003366;
`;

const Button = styled(motion.button)`
  padding: 10px 20px;
  font-size: 1.1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #003366;
  color: white;
  margin-top: 20px;

  &:hover {
    background-color: #022a5f;
  }
`;

const AnonymousPersonal = ({ onSignUp }) => {
  return (
    <>
      <Header>Join the Funding Success <br /> Donor Network</Header>

      <p>Share a bit about yourself and your philanthropy goals to inspire applicants and guide our Donor Team.</p>

      <CardsContainer>
        <Card
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          onClick={() => onSignUp(true)}  // Anonymous sign-up
        >
          <CardIcon>
            <FaUserSecret />
          </CardIcon>
          <CardTitle>Sign up Anonymously</CardTitle>
          <Button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            Sign Up
          </Button>
        </Card>

        <Card
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          onClick={() => onSignUp(false)}  // Personal data sign-up
        >
          <CardIcon>
            <FaUser />
          </CardIcon>
          <CardTitle>Sign up with Personal Data</CardTitle>
          <Button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            Sign Up
          </Button>
        </Card>
      </CardsContainer>
    </>
  );
};

export default AnonymousPersonal;
